const API_URL = `${process.env.REACT_APP_API_URL}media/big-images`;

const uploadFile = (file: FormData) => {
  return fetch(API_URL, {
    method: "POST",
    body: file,
  });
};

function uploadAdapter(loader: { file: Promise<any> }) {
  return {
    upload: () => {
      return loader.file.then(async (file) => {
        const formData = new FormData();

        formData.append("files", file);
        formData.append("service", "EXPORT_WEB");
        formData.append("folder", "commons/");

        const uploadResult = await uploadFile(formData).then();
        const fileUrl = await uploadResult.json();

        return {
          default: `${fileUrl.rows[0].mediaUrl}`,
        };
      });
    },
  };
}

function uploadPlugin(editor: any) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader: any) => {
    return uploadAdapter(loader);
  };
}

export { uploadPlugin };
