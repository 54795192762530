import {
  InputHTMLAttributes,
  forwardRef,
  useEffect,
  useId,
  useRef,
  useState,
} from "react";
import { styled } from "styled-components";
import { Button, IconButton } from "../Button";
import Typo from "../Typo";
import Icon from "../Icon";
import { ReactComponent as ClipSvg } from "@/src/assets/icons/icon-link-clip-blue.svg";
import { useUploadBigFileMutation } from "@/src/store/apis/media";
import useAlert from "@/src/hooks/useAlert";
import { MediaDto, MediaFolderType } from "@/src/store/apis/media/interface";
import RemoveSvg from "@/src/assets/icons/icon-cancle-circle.svg";
import Loader from "../Loader";
import PreviewSvg from "@/src/assets/icons/icon-preview-black.svg";
import colorSet from "@/src/styles/color";
import { useTranslation } from "react-i18next";
import { isFileAccepted } from "@/src/utils/is";
import { acceptData } from "@/src/utils/accept";

export interface FileProps
  extends Omit<
    InputHTMLAttributes<HTMLInputElement>,
    "onChange" | "placeholder"
  > {
  onChange?: (fileList: MediaDto[]) => void;
  defaultFileList?: File[];
  onRemove?: (fileName: string) => void;
  isPreview?: boolean;
  hideButton?: boolean;
  folder?: MediaFolderType;
  placeholder?: string;
}

const File = forwardRef<HTMLInputElement, FileProps>((props, ref) => {
  const { t } = useTranslation();
  const {
    onChange,
    accept = acceptData.file,
    multiple,
    isPreview,
    hideButton,
    placeholder,
    ...restProps
  } = props;
  const [files, setFiles] = useState<File[] | null>(
    props?.defaultFileList || null
  );

  const containerRef = useRef<HTMLDivElement>(null);
  const id = useId();
  const alert = useAlert();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [uploadFile, { isLoading }] = useUploadBigFileMutation();

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    const filteredFiles = Array.from(e.target.files).filter((file) =>
      isFileAccepted(file, accept)
    );
    if (filteredFiles.length === 0) {
      alert.showAlert({
        type: "error",
        message: t("alert:fileUploadFail"),
      });
      return;
    }

    try {
      const res = await uploadFile({
        files: filteredFiles,
        folder: props.folder || "commons/",
      }).unwrap();
      onChange?.(res);
      setFiles(filteredFiles);
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const handleDragFile = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    if (!e.dataTransfer.files) return;

    const filteredFiles = Array.from(e.dataTransfer.files).filter((file) =>
      isFileAccepted(file, accept)
    );

    if (filteredFiles.length === 0) {
      alert.showAlert({
        type: "error",
        message: t("alert:fileUploadFail"),
      });
      return;
    }

    try {
      const res = await uploadFile({
        files: filteredFiles,
        folder: props.folder || "commons/",
      }).unwrap();
      onChange?.(res);
      setFiles(filteredFiles);
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const renderFile = () => {
    if (isLoading) return <Loader />;
    return (
      <>
        {!files || files.length === 0 ? (
          <Typo typoType="b7r" color="gray7">
            {placeholder ?? t("common:findFile.placeholder")}
          </Typo>
        ) : (
          <>
            {files.map((file) => {
              const isDisabled = !!props.disabled;
              return (
                <FileRow>
                  <Typo typoType="b7r">{file.name}</Typo>
                  {isDisabled ? null : (
                    <Icon
                      className="remove-icon"
                      iconSrc={RemoveSvg}
                      iconSize={20}
                      onClick={() => {
                        props.onRemove?.(file.name);
                        setFiles([]);
                      }}
                    />
                  )}

                  {isPreview && file && (
                    <IconButton
                      buttonColor="black"
                      buttonGrade="tertiary"
                      buttonSize={24}
                      onClick={() => {
                        const target = (restProps?.value as any)?.find(
                          (item: MediaDto) =>
                            item.originalFileName === file.name
                        )?.mediaUrl;

                        return window.open(target, "_blank");
                      }}
                    >
                      <Icon iconSrc={PreviewSvg} iconSize={16} />
                    </IconButton>
                  )}
                </FileRow>
              );
            })}
          </>
        )}
      </>
    );
  };

  useEffect(() => {
    if (props.defaultFileList) {
      setFiles(props.defaultFileList);
    }
  }, [props.defaultFileList]);

  return (
    <FileContainer
      ref={containerRef}
      onDragOver={(e) => {
        e.preventDefault();
      }}
      onDrop={(e) => {
        e.preventDefault();
        if (restProps.disabled) return;
        handleDragFile(e);
      }}
    >
      {!hideButton && (
        <StyledButton
          buttonSize={32}
          buttonColor="blue"
          buttonGrade="secondary"
          disabled={isLoading || props.disabled}
          onClick={() => {
            if (fileInputRef.current) {
              fileInputRef.current.click();
            }
          }}
        >
          <ClipIcon data-disabled={isLoading || props.disabled} />
          {t("common:findFile")}
        </StyledButton>
      )}
      {renderFile()}
      <HiddenInput
        ref={(node) => {
          if (node) {
            fileInputRef.current = node;
          }

          if (ref) {
            if (typeof ref === "function") {
              ref(node);
            } else {
              ref.current = node;
            }
          }
        }}
        type="file"
        id={id}
        accept={accept}
        multiple={multiple}
        {...restProps}
        onChange={handleFileChange}
        value={""}
      />
    </FileContainer>
  );
});

export default File;

const FileContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
`;

const HiddenInput = styled.input`
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
  opacity: 0;
`;

const FileRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;

  .remove-icon {
    flex-shrink: 0;
  }
`;

const ClipIcon = styled(ClipSvg)`
  flex-shrink: 0;

  &[data-disabled="true"] {
    path {
      fill: ${colorSet.gray8};
    }
  }
`;
