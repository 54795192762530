import React, { useEffect, useRef, useState } from "react";
import ImporterMainLayout from "@/src/components/template/Layout/importer/ImporterMainLayout";
import SectionCard from "@/src/components/molecule/SectionCard";
import styled from "styled-components";
import { Button } from "@/src/components/atom/Button";
import { ReactComponent as AddSvg } from "@/src/assets/icons/icon-add-white.svg";
import { ReactComponent as CloseIconSvg } from "@/src/assets/icons/icon-cancle-black.svg";
import colorSet from "@/src/styles/color";
import BottomFixedContainer from "@/src/components/molecule/BottomFixedContainer";
import ChevronLeft from "@/src/assets/icons/icon-chevron-left-black.svg";
import Icon from "@/src/components/atom/Icon";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import CallOut from "@/src/components/molecule/CallOut";
import Typo from "@/src/components/atom/Typo";
import FormItem, { InputError } from "@/src/components/molecule/FormItem";
import InfoGray6Svg from "@/src/assets/icons/icon-info-gray6.svg";
import Table, { renderNoRowsComponent } from "@/src/components/atom/Table";
import { ColDef, RowSelectedEvent } from "ag-grid-community";
import { columnSalesItem } from "./columns/columnsSales";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import { AgGridReact } from "ag-grid-react";
import { editAction } from "@/src/utils/row-data-util";
import AddSalesItemDialog, {
  SalesItemType,
} from "./components/dialog/AddSalesItemDialog";
import AlertDialog from "@/src/components/atom/AlertDialog";
import useAlert from "@/src/hooks/useAlert";
import EditSalesItemDialog from "./components/dialog/EditSalesItemDialog";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import { MediaDto } from "@/src/store/apis/media/interface";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import Label from "@/src/components/atom/Label";
import AddBlueSvg from "@/src/assets/icons/icon-add-blue.svg";
import SelectOptionButton from "@/src/components/atom/Button/SelectOptionButton";
import LoadSalesContractDialog from "./components/dialog/LoadSalesContractDialog";
import { mediaQuery } from "@/src/styles/mediaQuery";
import Select from "@/src/components/atom/Select";
import Loader from "@/src/components/atom/Loader";
import { isUndefined } from "@/src/utils/is";
import Flex from "@/src/components/molecule/Flex";
import {
  useCreateFinalProductMutation,
  useCreateSaleMutation,
  useGetFinalProductsQuery,
} from "@/src/store/apis/sales";
import {
  DUPLICATED_SALES_NUMBER,
  FinalProductDto,
  SalesDto,
} from "@/src/store/apis/sales/interface";
import {
  useCreateWarehouseEmployeesMutation,
  useGetWarehousesQuery,
  useLazyGetWarehouseEmployeesQuery,
} from "@/src/store/apis/warehouse";
import {
  WarehouseDto,
  WarehouseEmployeeDto,
} from "@/src/store/apis/warehouse/interface";
import ContactPersonAddDialog, {
  TransformContactPersonAddModalFormType,
} from "../WarehouseManagement/dialog/ContactPersonAddDialog";
import dayjs, { Dayjs } from "dayjs";
import IMPORTER_PRIVATE_PATH from "@/src/routes/importer/path";
import {
  useLazyGetTemporaryDataQuery,
  useSaveTemporaryDataMutation,
} from "@/src/store/apis/tempSave";
import { PartialCommonCodeItemDto } from "@/src/store/apis/common/interface";
import { useGetCommonCodeViaCodeNameQuery } from "@/src/store/apis/common";
import { ErrorResponse } from "@/src/store/apis/type";
import { useTranslation } from "react-i18next";
import { useGetSessionQuery } from "@/src/store/apis/auth";

enum DialogState {
  NULL,
  ADD_WAREHOUSE_PERSON,
  ADD_ITEM,
  EDIT_ITEM,
  LOAD_CONTRACT,
}

enum AlertDialogState {
  NULL,
  SAVED_DATA,
  PREVIOUS,
  CREATE,
  DUPLICATION_ERROR,
  DELETE_ITEM,
}

const finalProductEmptyArray: FinalProductDto[] = [];
const WarehouseEmptyArray: WarehouseDto[] = [];
const WarehouseEmployeesEmptyArray: WarehouseEmployeeDto[] = [];
const mainCategoryEmptyArray: PartialCommonCodeItemDto[] = [];

const ImporterSalesCreatePage = () => {
  const { t } = useTranslation();
  const alert = useAlert();
  const navigate = useNavigate();
  const gridRef = useRef<AgGridReact>(null);
  const hiddenButtonRef = useRef<HTMLButtonElement>(null);

  // API
  const { userMainCategory, exporterUserType } = useGetSessionQuery(undefined, {
    refetchOnMountOrArgChange: true,
    selectFromResult: ({ currentData, isError, isFetching }) => {
      const isUnstable = isError || isFetching || isUndefined(currentData);
      const isStable = !isUnstable;

      return {
        userMainCategory: isStable
          ? currentData.row.mainCategoryCodeItemNames
          : [],
        exporterUserType: currentData?.row.exporterUserType,
      };
    },
  });

  const [getSavedData] = useLazyGetTemporaryDataQuery();
  const [tempSave] = useSaveTemporaryDataMutation();
  const [createSale] = useCreateSaleMutation();
  const [createFinalProduct, { isLoading: isCreateFinalProductLoading }] =
    useCreateFinalProductMutation();
  const { finalProducts, isFinalProductsFetching } = useGetFinalProductsQuery(
    undefined,
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          isFinalProductsFetching: isFetching,
          finalProducts: isStable ? currentData.rows : finalProductEmptyArray,
        };
      },
    }
  );

  const [createEmployee] = useCreateWarehouseEmployeesMutation();
  const { warehouses, isWarehousesFetching } = useGetWarehousesQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isFetching, isError }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          isWarehousesFetching: isFetching,
          warehouses: isStable ? currentData.rows : WarehouseEmptyArray,
        };
      },
    }
  );
  const [
    getWarehouseEmployees,
    { warehouseEmployees, isWarehouseEmployeesFetching },
  ] = useLazyGetWarehouseEmployeesQuery({
    selectFromResult: ({ currentData, isFetching, isError }) => {
      const isUnstable = isError || isFetching || isUndefined(currentData);
      const isStable = !isUnstable;

      return {
        isWarehouseEmployeesFetching: isFetching,
        warehouseEmployees: isStable
          ? currentData.rows
          : WarehouseEmployeesEmptyArray,
      };
    },
  });

  const { mainCategoryList } = useGetCommonCodeViaCodeNameQuery(
    {
      codeName: "MAIN_CATEGORY",
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          mainCategoryList: isStable
            ? currentData ?? mainCategoryEmptyArray
            : mainCategoryEmptyArray,
        };
      },
    }
  );
  const { subCategoryList } = useGetCommonCodeViaCodeNameQuery(
    {
      codeName: "SUB_CATEGORY",
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          subCategoryList: isStable
            ? currentData ?? mainCategoryEmptyArray
            : mainCategoryEmptyArray,
        };
      },
    }
  );

  // AgGrid
  const [isReady, setIsReady] = useState<boolean>(false);
  const [columnDefs] = useState<ColDef[]>(columnSalesItem);

  // Items State
  const [items, setItems] = useState<(SalesItemType & { id: string })[]>([]);
  const [selectedItemIds, setSelectedItemIds] = useState<string[]>([]);
  const [selectItem, setSelectItem] = useState<
    (SalesItemType & { id: string }) | null
  >(null);

  // Dialog State
  const [directInputOpen, setDirectInputOpen] = useState(false);
  const [dialogState, setDialogState] = useState(DialogState.NULL);
  const [alertDialogState, setAlertDialogState] = useState(
    AlertDialogState.NULL
  );
  const [savedData, setSavedData] = useState<any>();

  const {
    watch,
    control,
    setValue,
    setFocus,
    handleSubmit,
    getValues,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm<{
    salesNumber: string;
    orderDateAt?: Dayjs;
    salesMediaId?: MediaDto[];
    warehouseId?: string;
    warehouseEmployeeId?: string;
    finalProductIdList: string[];
    isFinalProductDirectInput: boolean;
    finalProductDirectInput: string;
    memo?: string;
  }>({
    mode: "onBlur",
    defaultValues: {
      salesNumber: undefined,
      orderDateAt: undefined,
      memo: undefined,
      salesMediaId: undefined,
      warehouseId: undefined,
      warehouseEmployeeId: undefined,
      finalProductIdList: [],
      finalProductDirectInput: "",
      isFinalProductDirectInput: false,
    },
  });

  const finalProductOptionList = finalProducts
    ? finalProducts.map(({ name, id }) => {
        return { label: name, value: id.toString() };
      })
    : [];

  const warehousesOptionList = warehouses
    .filter(({ isActivated, mainCategoryCodeItemNames }) => {
      // 일반매니저, 중간관리자가 아닌 경우 필터링 없이 그대로 유지
      return isActivated && (exporterUserType === "MANAGER" ||
        exporterUserType === "MIDDLE_MANAGER")
        ? mainCategoryCodeItemNames.some((category) =>
            userMainCategory.includes(category)
          )
        : true;
    })
    .map(({ warehouseName, id }) => {
      return { label: warehouseName, value: id.toString() };
    });

  const warehouseEmployeeList = warehouseEmployees
    .filter(({ isActivated }) => isActivated)
    .map(({ name, id }) => {
      return { label: name, value: id.toString() };
    });

  // AgGrid Header
  useAgGridHeaderRefresh({
    gridRef: gridRef?.current,
    isReady,
    headerSet: [
      {
        columnKey: "item",
        langKey: "sales:add.itemTable.item",
      },
      {
        columnKey: "itemCode",
        langKey: "sales:add.itemTable.itemCode",
      },
      {
        columnKey: "subCategory",
        langKey: "sales:add.itemTable.subCategory",
      },
      {
        columnKey: "mainCategory",
        langKey: "sales:add.itemTable.mainCategory",
      },
      {
        columnKey: "quantity",
        langKey: "sales:add.itemTable.qty",
      },
      {
        columnKey: "unitPrice",
        langKey: "sales:add.itemTable.unitPrice",
      },
      {
        columnKey: "amount",
        langKey: "sales:add.itemTable.amount",
      },
      {
        columnKey: "edit",
        langKey: "sales:add.itemTable.edit",
      },
    ],
  });

  // AgGrid Checkbox
  const handleSelectionChanged = (e: RowSelectedEvent) => {
    if (
      e.source === "rowClicked" ||
      e.source === "uiSelectAll" ||
      e.source === "checkboxSelected"
    ) {
      const selectedNodesData = e.api.getSelectedNodes();
      const accountKey = e.node.data.id;

      const keys = selectedNodesData.map((item) => item.data.id) as number[];

      if (selectedItemIds.includes(accountKey)) {
        setSelectedItemIds((prev) => prev.filter((id) => id !== accountKey));
      } else {
        const set: any = new Set([...selectedItemIds, ...keys]);
        setSelectedItemIds([...set]);
      }
    }
  };

  const handleSubmitClick = async () => {
    const itemInformation = items.map((item) => {
      return {
        quantity: Number(item.quantity.replace(/,/g, "")),
        quantityUnit: item.quantityUnit,
        quantityUnitCodeItemName: item.quantityUnitCodeItemName,
        unitPrice: Number(item.unitPrice.replace(/,/g, "")),
        unitPriceUnit: item.unitPriceUnit,
        unitPriceUnitCodeItemName: item.unitPriceUnitCodeItemName,
        exporterItemId: Number(item.exporterItemId),
        exporterItemCodeId: Number(item.exporterItemCodeId),
      };
    });

    const params = {
      salesNumber: getValues("salesNumber"),
      orderDateAt: dayjs(
        dayjs(getValues("orderDateAt")).format(DATE_FORMAT_STRINGS.YYYY_MM_DD)
      ).toISOString(),
      memo: getValues("memo"),
      finalProductIdList: getValues("finalProductIdList").map(
        (finalProductId) => Number(finalProductId)
      ),
      warehouseId: Number(getValues("warehouseId")),
      warehouseEmployeeId: Number(getValues("warehouseEmployeeId")),
      salesMediaId: getValues("salesMediaId")?.[0].id,
      itemInformationDtoList: itemInformation,
    };

    try {
      const res = await createSale(params).unwrap();
      setAlertDialogState(AlertDialogState.NULL);

      if (res) {
        navigate(IMPORTER_PRIVATE_PATH.SALES_MANAGEMENT);
        alert.showAlert({
          type: "success",
          message: t("alert:saveSuccess"),
        });
      }
    } catch (e: any) {
      const error = e as ErrorResponse;

      if (error.data.errorCode === DUPLICATED_SALES_NUMBER) {
        return setAlertDialogState(AlertDialogState.DUPLICATION_ERROR);
      }

      const message = Array.isArray(error.data.message)
        ? error.data.message[0]
        : error.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const handleAddFinalProduct = async () => {
    try {
      const res = await createFinalProduct({
        name: getValues("finalProductDirectInput"),
      }).unwrap();

      if (res) {
        const currentFinalIdList = watch("finalProductIdList");

        setValue("finalProductDirectInput", "");
        setValue("finalProductIdList", [
          ...currentFinalIdList,
          res.row.id.toString(),
        ]);
        setFocus("finalProductDirectInput");
        clearErrors("finalProductIdList");
      }
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const handleTempSave = async () => {
    try {
      await tempSave({
        storageTarget: "SALES",
        jsonData: { ...getValues(), items },
      }).unwrap();

      alert.showAlert({
        type: "success",
        message: t("sales:common.alert.temporarilySaved"),
      });
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const handleWarehouseClick = async (id: number) => {
    try {
      await getWarehouseEmployees({ id }).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const handleEditItemClick = (row: SalesItemType & { id: string }) => {
    setSelectItem(row);
    setDialogState(DialogState.EDIT_ITEM);
  };

  const handleDeleteItemsClick = () => {
    const filterItems = [...items].filter(
      (item) => !selectedItemIds.includes(item.id)
    );

    setItems(filterItems);
    setSelectedItemIds([]);
    setAlertDialogState(AlertDialogState.NULL);

    alert.showAlert({
      type: "success",
      message: t("sales:common.alert.deleteItem"),
    });
  };

  const handleOverrideData = () => {
    setValue("memo", savedData.memo);
    setValue("orderDateAt", dayjs(savedData.orderDateAt));
    setValue("finalProductIdList", savedData.finalProductIdList);
    setValue("salesMediaId", savedData.salesMediaId);
    setValue("salesNumber", savedData.salesNumber);
    setValue("warehouseEmployeeId", savedData.warehouseEmployeeId);
    setValue("warehouseId", savedData.warehouseId);
    setItems(savedData.items);

    setAlertDialogState(AlertDialogState.NULL);
    setFocus("salesNumber");
  };

  const handleLoadContractOverrideData = (detail: SalesDto) => {
    const {
      warehouse,
      warehouseEmployee,
      salesFinalProducts,
      exporterItemCode,
      exporterItem,
      quantity,
      quantityUnit,
      unitPriceUnitCodeItemName,
      quantityUnitCodeItemName,
      unitPrice,
      unitPriceUnit,
    } = detail;

    const subCategory =
      subCategoryList.find(
        (item) => item.codeItemName === exporterItem.subCategoryCodeItemName
      )?.codeItemNameEn || "";
    const mainCategory =
      mainCategoryList.find(
        (item) => item.codeItemName === exporterItem.mainCategoryCodeItemName
      )?.codeItemNameEn || "";

    handleWarehouseClick(warehouse.id);

    setValue("warehouseId", warehouse.id.toString());
    setValue("warehouseEmployeeId", warehouseEmployee.id.toString());
    setValue(
      "finalProductIdList",
      salesFinalProducts.map((item) => item.finalProduct.id.toString())
    );
    setItems([
      {
        id: "0",
        exporterItemId: `${exporterItem.id}`,
        exporterItemCodeId: `${exporterItemCode.id}`,
        itemName: `${exporterItem.item}`,
        itemCode: `${exporterItemCode.itemCode}`,
        subCategory: `${subCategory}`,
        mainCategory: `${mainCategory}`,
        quantity: `${quantity}`,
        quantityUnit: `${quantityUnit}`,
        quantityUnitCodeItemName: `${quantityUnitCodeItemName}`,
        unitPrice: `${unitPrice}`,
        unitPriceUnit: `${unitPriceUnit}`,
        unitPriceUnitCodeItemName: `${unitPriceUnitCodeItemName}`,
        amount: `${String(Number(quantity) * Number(unitPrice))}`,
        amountUnit: `${unitPriceUnit}`,
      },
    ]);
  };

  const handleCreateEmployee = async (
    values: TransformContactPersonAddModalFormType
  ) => {
    if (!getValues("warehouseId")) {
      return;
    }

    try {
      const res = await createEmployee({
        id: Number(getValues("warehouseId")),
        isActivated: values.isActivated,
        name: values.name,
        email: values.email,
        personalContactPrefix: values.personalContactPrefix,
        personalContact: values.personalContact,
        officeContactPrefix: values.officeContactPrefix
          ? values.officeContactPrefix
          : undefined,
        officeContact: values.officeContact ? values.officeContact : undefined,
      }).unwrap();

      setDialogState(DialogState.NULL);
      setValue("warehouseEmployeeId", res.row.id.toString());
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const renderDialog = () => {
    // 아이템 추가
    if (dialogState === DialogState.ADD_ITEM) {
      return (
        <AddSalesItemDialog
          open
          onOpenChange={() => setDialogState(DialogState.NULL)}
          onSave={(newItem) =>
            setItems((prev) => {
              return [...prev, newItem];
            })
          }
        />
      );
    }

    // 아이템 수정
    if (dialogState === DialogState.EDIT_ITEM && selectItem) {
      return (
        <EditSalesItemDialog
          open
          onOpenChange={() => setDialogState(DialogState.NULL)}
          selectItem={selectItem}
          onSave={(editItem) => {
            if (gridRef.current) {
              const result = [...items].map((item) => {
                if (item.id === editItem.id) {
                  return editItem;
                }

                return item;
              });

              gridRef.current.api.redrawRows();

              setItems(result);
              setSelectItem(null);
            }
          }}
        />
      );
    }

    // 발주계약 불러오기
    if (dialogState === DialogState.LOAD_CONTRACT) {
      return (
        <LoadSalesContractDialog
          open
          onClose={() => setDialogState(DialogState.NULL)}
          onFetchContractViaId={handleLoadContractOverrideData}
        />
      );
    }

    // 입고지 담당자 추가
    if (dialogState === DialogState.ADD_WAREHOUSE_PERSON) {
      return (
        <ContactPersonAddDialog
          open
          onOpenChange={() => setDialogState(DialogState.NULL)}
          onSave={handleCreateEmployee}
        />
      );
    }
  };

  const renderAlertDialog = () => {
    // 아이템 삭제
    if (alertDialogState === AlertDialogState.DELETE_ITEM) {
      return (
        <AlertDialog
          title={t("sales:common.alertDialog.deleteItem.title")}
          open
          onOpenChange={() => setAlertDialogState(AlertDialogState.NULL)}
          cancelText={t("sales:common.button.cancel")}
          okText={t("sales:common.button.ok")}
          onOk={handleDeleteItemsClick}
        >
          {t("sales:common.alertDialog.deleteItem.description")}
        </AlertDialog>
      );
    }

    // 이전 화면 이동
    if (alertDialogState === AlertDialogState.PREVIOUS) {
      return (
        <AlertDialog
          title={t("sales:common.alertDialog.backToPrevious.title")}
          open
          onOpenChange={() => setAlertDialogState(AlertDialogState.NULL)}
          cancelText={t("sales:common.button.cancel")}
          okText={t("sales:common.button.ok")}
          onOk={() => navigate(-1)}
        >
          {t("sales:common.alertDialog.backToPrevious.description")}
        </AlertDialog>
      );
    }

    // 최종저장 시
    if (alertDialogState === AlertDialogState.CREATE) {
      return (
        <AlertDialog
          title={t("sales:common.alertDialog.createSaleContract.title")}
          open
          onOpenChange={() => setAlertDialogState(AlertDialogState.NULL)}
          cancelText={t("sales:common.button.cancel")}
          okText={t("sales:common.button.ok")}
          onOk={handleSubmit(handleSubmitClick, () => {
            setAlertDialogState(AlertDialogState.NULL);
          })}
        >
          {t("sales:common.alertDialog.createSaleContract.description")}
        </AlertDialog>
      );
    }

    // 임시저장 계약이 있는경우
    if (alertDialogState === AlertDialogState.SAVED_DATA) {
      return (
        <AlertDialog
          title={t("sales:common.alertDialog.loadTemporarySave.title")}
          open
          onOpenChange={() => setAlertDialogState(AlertDialogState.NULL)}
          cancelText={t("sales:common.button.cancel")}
          okText={t("sales:common.button.ok")}
          onOk={handleOverrideData}
        >
          {t("sales:common.alertDialog.loadTemporarySave.description")}
        </AlertDialog>
      );
    }

    // 중복 발주 넘버가 있을경우
    if (alertDialogState === AlertDialogState.DUPLICATION_ERROR) {
      return (
        <AlertDialog
          title={t("sales:common.alertDialog.contractNoDuplicate.title")}
          open
          onOpenChange={() => setAlertDialogState(AlertDialogState.NULL)}
          okText={t("sales:common.button.ok")}
          cancelText=""
          onOk={() => {
            setAlertDialogState(AlertDialogState.NULL);
            setFocus("salesNumber");
          }}
        >
          {t("sales:common.alertDialog.contractNoDuplicate.description")}
        </AlertDialog>
      );
    }
  };

  useEffect(() => {
    setFocus("salesNumber");
  }, [setFocus]);

  useEffect(() => {
    (async () => {
      try {
        const data = await getSavedData({ storageTarget: "SALES" }).unwrap();

        if (data?.row.jsonData?.warehouseId) {
          await getWarehouseEmployees({
            id: data.row.jsonData.warehouseId,
          });
        }

        if (data) {
          setAlertDialogState(AlertDialogState.SAVED_DATA);
          setSavedData(data.row.jsonData);
        }
      } catch (e) {
        setFocus("salesNumber");
      }
    })();
  }, [getWarehouseEmployees, getSavedData, reset, setFocus]);

  return (
    <ImporterMainLayout
      breadcrumb={[t("sideNav:sales"), t("sideNav:createSales")]}
      pageTitle={t("sideNav:createSales")}
    >
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          setAlertDialogState(AlertDialogState.CREATE);
        }}
      >
        {/* Basic Information */}
        <SectionCard
          cardTitle={t("sales:common.basicInformation")}
          rightAccessory={
            <StyledButton
              buttonColor="black"
              buttonGrade="tertiary"
              buttonSize={32}
              onClick={() => setDialogState(DialogState.LOAD_CONTRACT)}
            >
              {t("sales:common.button.loadContract")}
            </StyledButton>
          }
        >
          <Flex gap={24}>
            <Flex gap={24} flexDirection="column" flex={1}>
              <StyledFormItem
                label={t("common:contractNo")}
                type="text"
                name="salesNumber"
                control={control as any}
                rules={{ required: true }}
                inputProps={{
                  placeholder: t("sales:common.contractNoPlaceholder"),
                }}
                errorsMessage={{
                  required: t("error:required"),
                }}
              />
              <CustomFormItemRow>
                <>
                  <Label required>{t("sales:common.warehouse")}</Label>
                  <Controller
                    name="warehouseId"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => {
                      return (
                        <Flex flexDirection="column" gap={8} flex={1}>
                          <Flex flexDirection="column" gap={8}>
                            <StyledSelect
                              {...field}
                              value={field.value || undefined}
                              style={{ flex: 1, width: "100%" }}
                              placeholder={t(
                                "sales:common.warehouseSelectPlaceholder"
                              )}
                              filterOption={(input, option) =>
                                ((option?.label as string) ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              onSelect={(warehouseId) => {
                                handleWarehouseClick(Number(warehouseId));
                                setValue("warehouseEmployeeId", undefined);
                                clearErrors("warehouseId");
                              }}
                              options={warehousesOptionList}
                              showSearch
                              suffixIcon={
                                isWarehousesFetching ? <Loader /> : undefined
                              }
                              data-invalid={!!errors.warehouseId}
                            />
                            {errors.warehouseId && (
                              <InputError message={t("error:required")} />
                            )}
                          </Flex>

                          <CustomFormItemRow>
                            <StyledLabel required>
                              <Typo typoType="b9r" color="gray6">
                                {t("sales:common.warehouseContact")}
                              </Typo>
                            </StyledLabel>
                            <Flex flex={1} alignItems="baseline">
                              <Controller
                                name="warehouseEmployeeId"
                                rules={{ required: true }}
                                control={control}
                                render={({ field }) => {
                                  return (
                                    <Flex flex={1}>
                                      <Flex
                                        flexDirection="column"
                                        gap={8}
                                        flex={1}
                                      >
                                        <StyledSelect
                                          {...field}
                                          value={field.value || undefined}
                                          style={{ flex: 1, width: "100%" }}
                                          placeholder={t(
                                            "sales:common.warehouseContactPlaceholder"
                                          )}
                                          filterOption={(input, option) =>
                                            ((option?.label as string) ?? "")
                                              .toLowerCase()
                                              .includes(input.toLowerCase())
                                          }
                                          disabled={
                                            isUndefined(
                                              watch("warehouseId")
                                            ) === true
                                          }
                                          onSelect={() => {
                                            clearErrors("warehouseEmployeeId");
                                          }}
                                          options={warehouseEmployeeList}
                                          showSearch
                                          suffixIcon={
                                            isWarehouseEmployeesFetching ? (
                                              <Loader />
                                            ) : undefined
                                          }
                                          data-invalid={
                                            !!errors.warehouseEmployeeId
                                          }
                                          dropdownRender={(menu) => {
                                            return (
                                              <>
                                                <DirectInputSelectOptionButton
                                                  onClick={() => {
                                                    setDialogState(
                                                      DialogState.ADD_WAREHOUSE_PERSON
                                                    );
                                                  }}
                                                >
                                                  {t("common:directInput")}
                                                </DirectInputSelectOptionButton>
                                                {(warehouseEmployeeList?.length ??
                                                  0) > 0 && <div>{menu}</div>}
                                              </>
                                            );
                                          }}
                                        />

                                        {errors.warehouseEmployeeId && (
                                          <InputError
                                            message={t("error:required")}
                                          />
                                        )}
                                      </Flex>
                                    </Flex>
                                  );
                                }}
                              />
                            </Flex>
                          </CustomFormItemRow>
                        </Flex>
                      );
                    }}
                  />
                </>
              </CustomFormItemRow>
            </Flex>
            <Flex gap={24} flexDirection="column" flex={1}>
              <StyledSectionCardRow
                label={
                  <Label required>
                    <Typo typoType="b7m">{t("sales:common.finalProduct")}</Typo>
                  </Label>
                }
                value={
                  <Flex flexDirection="column" gap={8}>
                    <StyledFormItem
                      type="multipleSelect"
                      name="finalProductIdList"
                      control={control as any}
                      rules={{ required: true }}
                      inputProps={{
                        placeholder: t(
                          "sales:common.finalProductSelectPlaceholder"
                        ),
                        suffixIcon: isFinalProductsFetching ? (
                          <Loader />
                        ) : undefined,
                        onRemoveItem: (value) => {
                          const watchCategory = watch("finalProductIdList");
                          const watchCategoryFilter = watchCategory.filter(
                            (item) => item !== value
                          );
                          setValue("finalProductIdList", watchCategoryFilter);
                        },
                        open: directInputOpen,
                        onDropdownVisibleChange: (visible) =>
                          setDirectInputOpen(visible),
                        dropdownRender: (menu) => {
                          return (
                            <>
                              <DirectInputSelectOptionButton
                                onClick={() => {
                                  setDirectInputOpen(false);
                                  setValue("isFinalProductDirectInput", true);
                                  setFocus("finalProductDirectInput");
                                }}
                              >
                                {t("common:directInput")}
                              </DirectInputSelectOptionButton>
                              {(finalProductOptionList?.length ?? 0) > 0 && (
                                <div>{menu}</div>
                              )}
                            </>
                          );
                        },
                      }}
                      options={finalProductOptionList}
                      errorsMessage={{
                        required: t("error:required"),
                      }}
                    />
                    {watch("isFinalProductDirectInput") && (
                      <FinalDirectForm>
                        <StyledFormItem
                          type="text"
                          name="finalProductDirectInput"
                          control={control as any}
                          className="direct-input"
                          inputProps={{
                            placeholder: t(
                              "sales:common.finalProductPlaceholder"
                            ),
                          }}
                          errorsMessage={{
                            required: t("error:required"),
                          }}
                        />
                        <StyledButton
                          type="submit"
                          buttonColor="black"
                          buttonGrade="tertiary"
                          onClick={handleAddFinalProduct}
                          isLoading={isCreateFinalProductLoading}
                          disabled={
                            watch("finalProductDirectInput").length === 0 ||
                            isCreateFinalProductLoading
                          }
                        >
                          <AddIcon
                            data-disabled={
                              watch("finalProductDirectInput").length === 0 ||
                              isCreateFinalProductLoading
                            }
                          />
                          {t("sales:common.button.addFinalProduct")}
                        </StyledButton>
                      </FinalDirectForm>
                    )}
                  </Flex>
                }
              />

              <StyledFormItem
                label={t("common:orderDate")}
                type="date"
                name="orderDateAt"
                control={control as any}
                rules={{ required: true }}
                inputProps={{
                  placeholder: DATE_FORMAT_STRINGS.YYYY_MM_DD,
                  allowClear: true,
                }}
                errorsMessage={{
                  required: t("error:required"),
                }}
              />
              <StyledFormItem
                label={t("sales:common.file")}
                type="file"
                name="salesMediaId"
                control={control as any}
                inputProps={{
                  defaultFileList: watch("salesMediaId")
                    ? [
                        new File(
                          [""],
                          watch("salesMediaId")?.[0]?.originalFileName ?? ""
                        ),
                      ]
                    : undefined,
                  onRemove: () => setValue("salesMediaId", undefined),
                }}
              />
            </Flex>
          </Flex>
        </SectionCard>

        {/* Item Information */}
        <SectionCard
          cardTitle={t("sales:common.itemInformation")}
          rightAccessory={
            <Flex gap={8}>
              <StyledButton
                buttonColor="black"
                buttonGrade="tertiary"
                buttonSize={32}
                onClick={() => setDialogState(DialogState.ADD_ITEM)}
              >
                <AddIcon />
                {t("sales:common.button.addItem")}
              </StyledButton>

              <StyledButton
                buttonColor="red"
                buttonGrade="secondary"
                buttonSize={32}
                disabled={selectedItemIds.length === 0}
                onClick={() =>
                  setAlertDialogState(AlertDialogState.DELETE_ITEM)
                }
              >
                <CloseIcon data-disabled={selectedItemIds.length === 0} />
                {t("sales:common.button.delete")}
              </StyledButton>
            </Flex>
          }
        >
          <Table
            ref={gridRef}
            rowData={items}
            columnDefs={columnDefs}
            isPagination={false}
            height={252}
            onGridReady={() => {
              setIsReady(true);
            }}
            rowSelection={"multiple"}
            rowMultiSelectWithClick
            suppressRowClickSelection
            onRowSelected={handleSelectionChanged}
            components={{
              ...editAction({
                onClick: (row) => {
                  return handleEditItemClick(row as any);
                },
              }),
            }}
            getRowId={(data) => data.data.id}
          />
        </SectionCard>

        {/* Memo */}
        <StyledSectionCard
          cardTitle={t("sales:common.memo")}
          className="bottom-margin"
        >
          <Flex gap={24}>
            <StyledCallOut backgroundColor="white">
              <Flex alignItems="center" justifyContent="center" isFullWidth>
                {renderNoRowsComponent()}
              </Flex>
            </StyledCallOut>

            <Flex gap={16} flexDirection="column" flex={1}>
              <Flex gap={6} alignItems="center">
                <StyledIcon iconSrc={InfoGray6Svg} />
                <Typo color="gray6" typoType="b9r">
                  {t("sales:common.memoInfo")}
                </Typo>
              </Flex>

              <FormItem
                type="textarea"
                name="memo"
                control={control}
                inputProps={{
                  style: { width: "100%" },
                  placeholder: t("placeholder.memo"),
                  maxLength: 500,
                }}
              />
            </Flex>
          </Flex>
        </StyledSectionCard>

        <Hidden
          type="submit"
          ref={hiddenButtonRef}
          disabled={items.length === 0}
        />
      </Form>

      {/* Bottom Fixed */}
      <BottomFixedContainer>
        <BottomButtonContainer>
          <StyledButton
            type="button"
            buttonColor="black"
            buttonGrade="tertiary"
            onClick={() => setAlertDialogState(AlertDialogState.PREVIOUS)}
          >
            <Icon iconSrc={ChevronLeft} iconSize={16} />
            {t("sales:common.button.backToPreviousButton")}
          </StyledButton>

          <figure>
            <StyledButton
              type="button"
              onClick={handleTempSave}
              buttonColor="blue"
              buttonGrade="secondary"
            >
              {t("sales:common.button.temporarySave")}
            </StyledButton>
            <StyledButton
              onClick={() => hiddenButtonRef.current?.click()}
              disabled={items.length === 0}
              className="save-button"
            >
              {t("sales:common.button.save")}
            </StyledButton>
          </figure>
        </BottomButtonContainer>
      </BottomFixedContainer>

      {renderDialog()}
      {renderAlertDialog()}
    </ImporterMainLayout>
  );
};

export default ImporterSalesCreatePage;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const AddIcon = styled(AddSvg)`
  width: 16px;
  height: 16px;

  path {
    fill: ${colorSet.gray2};
  }

  &[data-disabled="true"] {
    path {
      fill: ${colorSet.gray8};
    }
  }
`;

const CloseIcon = styled(CloseIconSvg)`
  width: 16px;
  height: 16px;

  path {
    fill: ${colorSet.red2};
  }

  &[data-disabled="true"] {
    path {
      fill: ${colorSet.red6};
    }
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const BottomButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  figure {
    display: flex;
    gap: 8px;

    .save-button {
      width: 158px;
      justify-content: center;
    }

    .temporary-save {
      width: 136px;
      justify-content: center;
    }
  }
`;

const StyledCallOut = styled(CallOut)`
  flex: 1;
`;

const StyledIcon = styled(Icon)`
  flex-shrink: 0;
`;

const StyledSectionCardRow = styled(SectionCardRow)`
  & > p {
    width: 100%;
  }

  ${mediaQuery.FORM_INPUT_TO_FULL_INPUT} {
    flex-direction: column !important;
  }
`;

const StyledFormItem = styled(FormItem)`
  &.direct-input {
    flex: 1;
  }

  ${mediaQuery.FORM_INPUT_TO_FULL_INPUT} {
    flex-direction: column;
  }
`;

const DirectInputSelectOptionButton = styled(SelectOptionButton)`
  color: ${colorSet.blue4};

  &:hover {
    & {
      color: ${colorSet.blue4};
    }
  }

  &::before {
    content: url(${AddBlueSvg});
    width: 18px;
    height: 18px;
    position: relative;
    margin-right: 8px;
    top: 4px;
  }
`;

const CustomFormItemRow = styled.div`
  display: flex;
  gap: 8px;
  flex: 1;

  ${mediaQuery.FORM_INPUT_TO_FULL_INPUT} {
    flex-direction: column;
  }
`;

const StyledSelect = styled(Select)`
  width: 100%;

  &[data-invalid="true"] {
    .ant-select-selector {
      border: 1px solid ${colorSet.red2} !important;
    }
  }

  .ant-select-selection-placeholder {
    width: 1px;
  }
`;

const StyledLabel = styled(Label)`
  min-width: 0;
`;

const StyledSectionCard = styled(SectionCard)`
  &.bottom-margin {
    margin-bottom: 72px;
  }
`;

const FinalDirectForm = styled.form`
  display: flex;
  gap: 8px;
  flex: 1;
`;

const Hidden = styled.button`
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
`;
