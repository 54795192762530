import Table from "@/src/components/atom/Table";
import Filter from "@/src/components/molecule/Filter";
import ExporterMainLayout from "@/src/components/template/Layout/exporter/ExporterMainLayout";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import EditDialog from "./components/EditDialog";
import { useLazyGetStaffsQuery } from "@/src/store/apis/corporate/staffManagement";
import {
  ExporterUserListViewDto,
  StaffManagementListFilterRequest,
  StaffManagementListRequest,
} from "@/src/store/apis/corporate/staffManagement/interface";
import { useGetCommonCodeViaCodeNameQuery } from "@/src/store/apis/common";
import { columnStaffManagement } from "./columns/columnStaffManagement";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import { useGetSessionQuery } from "@/src/store/apis/auth";
import SectionCardWithoutHeader from "@/src/components/molecule/SectionCardWithoutHeader";
import useFilterSearch from "@/src/hooks/useFilterSearch";
import dayjs from "dayjs";
import { PartialCommonCodeItemDto } from "@/src/store/apis/common/interface";
import { isUndefined } from "@/src/utils/is";
import { ClientType } from "@/src/store/apis/auth/interface";
import Typo from "@/src/components/atom/Typo";
import { Button } from "@/src/components/atom/Button";
import PermissionChangeDialog from "./components/PermissionChangeDialog";
import { useLazyGetCurrentSubscriptionQuery } from "@/src/store/apis/subscription";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@/src/store";
import { CLIENT_TYPE_OPTION_LIST } from "@/src/constant/optionList";

const Init: StaffManagementListFilterRequest = {
  name: undefined,
  email: undefined,
  personalContact: undefined,
  createdAt: undefined,
  mainCategoryCodeItemName: "all",
  exporterUserMainFieldType: "all" as ClientType,
  isActivated: "all" as unknown as boolean,
};

const FAST_SEARCH_QUERY_KEY = [
  "createdAt",
  "mainCategoryCodeItemName",
  "isActivated",
  "exporterUserMainFieldType",
];

const mainCategoryEmptyArray: PartialCommonCodeItemDto[] = [];

enum DialogState {
  NULL,
  STAFF_EDIT,
  PERMISSION_CHANGE,
}

const ExporterStaffManagementPage = () => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.auth.user);
  const gridRef = useRef<AgGridReact>(null);

  // API
  const [getCurrentSubscription] = useLazyGetCurrentSubscriptionQuery();
  const { isAbleToEdit, currentData } = useGetSessionQuery(undefined, {
    refetchOnMountOrArgChange: true,
    selectFromResult: ({ isError, isFetching, currentData }) => {
      const isUnstable = isError || isFetching || isUndefined(currentData);
      const isStable = !isUnstable;
      return {
        isAbleToEdit: isStable
          ? currentData.row.exporter.companyType === "BOTH" &&
            currentData.row.exporterUserType === "CORPORATE_MANAGER"
          : false,
        currentData,
      };
    },
  });
  const [getStaffs, { isFetching, staffList, count, isError }] =
    useLazyGetStaffsQuery({
      selectFromResult: ({ currentData, isFetching, isError }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);

        return {
          isFetching,
          count: !isUnstable ? currentData.count : 0,
          staffList: !isUnstable ? currentData.rows : undefined,
          isError,
        };
      },
    });
  const { mainCategoryCurrentData } = useGetCommonCodeViaCodeNameQuery(
    {
      codeName: "MAIN_CATEGORY",
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);

        return {
          mainCategoryCurrentData: !isUnstable
            ? currentData
            : mainCategoryEmptyArray,
        };
      },
    }
  );

  const mainCategoryFilterList = mainCategoryCurrentData
    .filter((item) =>
      currentData?.row.exporter.mainCategoryCodeItemNames.includes(
        item.codeItemName
      )
    )
    .map((item) => {
      return {
        label: item.codeItemNameEn,
        value: item.codeItemName,
      };
    });
  const allOptionItem = {
    label: t("staffManagement:exporter.placeholder.all"),
    value: "all",
  };

  // state
  const [dialogState, setDialogState] = useState<DialogState>(DialogState.NULL);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [columnDefs] = useState<ColDef[]>(columnStaffManagement);
  const [selectRowData, setSelectRowData] =
    useState<ExporterUserListViewDto | null>(null);

  const handleSelectRow = useCallback((selectRow: ExporterUserListViewDto) => {
    setDialogState(DialogState.STAFF_EDIT);
    return setSelectRowData(selectRow);
  }, []);

  const handlePermissionChangeClick = () => {
    setDialogState(DialogState.PERMISSION_CHANGE);
  };

  useAgGridHeaderRefresh({
    gridRef: gridRef.current,
    isReady,
    headerSet: [
      {
        columnKey: "name",
        langKey: "staffManagement:exporter.table.name",
      },
      {
        columnKey: "email",
        langKey: "staffManagement:exporter.table.email",
      },
      {
        columnKey: "exporterUserType",
        langKey: "staffManagement:exporter.table.authority",
      },
      {
        columnKey: "staffType",
        langKey: "staffManagement:exporter.table.staffType",
      },
      {
        columnKey: "mainCategory",
        langKey: "staffManagement:exporter.table.categoryInCharge",
      },
      {
        columnKey: "personalContact",
        langKey: "staffManagement:exporter.table.contactPersonal",
      },
      {
        columnKey: "officeContact",
        langKey: "staffManagement:exporter.table.contactOffice",
      },
      {
        columnKey: "createdAt",
        langKey: "staffManagement:exporter.table.joinDate",
      },
      {
        columnKey: "isActivated",
        langKey: "staffManagement:exporter.table.use",
      },
    ],
  });

  const {
    state: { pagination, filterData },
    func: {
      onPaginationChange,
      onSortChange,
      onSearch,
      onFilterDataChange,
      onResetFilter,
      onForceSearch,
    },
  } = useFilterSearch<
    StaffManagementListRequest,
    StaffManagementListFilterRequest
  >({
    isReady,
    gridRef: gridRef.current,
    fetch: getStaffs,
    defaultFilterData: Init,
    onBeforeSearch: (data) => {
      return {
        name: data?.name || undefined,
        email: data?.email || undefined,
        personalContact: data?.personalContact || undefined,
        isActivated:
          (data?.isActivated as unknown as string) === "all"
            ? undefined
            : (data?.isActivated as any) === "true"
            ? true
            : false,
        mainCategoryCodeItemName:
          data.mainCategoryCodeItemName === "all"
            ? undefined
            : data?.mainCategoryCodeItemName,
        exporterUserMainFieldType:
          data.exporterUserMainFieldType === "all"
            ? undefined
            : data?.exporterUserMainFieldType,
        createdAt: undefined,
        createdAtFrom: data.createdAt?.[0]
          ? dayjs(data.createdAt?.[0]).startOf("day").toISOString()
          : undefined,
        createdAtTo: data.createdAt?.[1]
          ? dayjs(data.createdAt?.[1]).endOf("day").toISOString()
          : undefined,
      };
    },
    onBeforeSetFilter: (urlObject) => {
      return {
        name: urlObject.name,
        email: urlObject.email,
        personalContact: urlObject.personalContact,
        isActivated: (urlObject.isActivated
          ? urlObject.isActivated === "true"
            ? "true"
            : "false"
          : "all") as unknown as boolean,
        mainCategoryCodeItemName: urlObject.mainCategoryCodeItemName ?? "all",
        exporterUserMainFieldType: urlObject.exporterUserMainFieldType ?? "all",
        createdAt:
          urlObject.createdAtFrom && urlObject.createdAtTo
            ? [dayjs(urlObject.createdAtFrom), dayjs(urlObject.createdAtTo)]
            : undefined,
        createdAtFrom: urlObject.createdAtFrom,
        createdAtTo: urlObject.createdAtTo,
      };
    },
  });

  const renderDialog = () => {
    // 단일 직원 수정 Dialog
    if (dialogState === DialogState.STAFF_EDIT) {
      return (
        <EditDialog
          open={dialogState === DialogState.STAFF_EDIT}
          onOpenChange={() => setDialogState(DialogState.NULL)}
          selectData={selectRowData}
        />
      );
    }

    // 직원 권한 일괄 변경
    if (dialogState === DialogState.PERMISSION_CHANGE) {
      return (
        <PermissionChangeDialog
          open={dialogState === DialogState.PERMISSION_CHANGE}
          onOpenChange={() => setDialogState(DialogState.NULL)}
        />
      );
    }
  };

  useEffect(() => {
    if (isFetching && gridRef.current) {
      gridRef.current.api.showLoadingOverlay();
    }
  }, [isFetching]);

  useEffect(() => {
    getCurrentSubscription();
  }, [getCurrentSubscription]);

  return (
    <ExporterMainLayout
      breadcrumb={[
        t("sideNav:corporateManagement"),
        t("sideNav:staffManagement"),
      ]}
      pageTitle={t("sideNav:staffManagement")}
    >
      {renderDialog()}

      <FlexColumn>
        <Filter
          filterData={filterData}
          filterKeyList={[
            {
              key: "name",
              label: t("staffManagement:exporter.filter.name"),
              type: "input",
              props: {
                placeholder: t(
                  "staffManagement:exporter.filter.namePlaceholder"
                ),
              },
            },
            {
              key: "email",
              label: t("staffManagement:exporter.filter.email"),
              type: "input",
              props: {
                placeholder: t(
                  "staffManagement:exporter.filter.emailPlaceholder"
                ),
              },
            },
            {
              key: "personalContact",
              label: t("staffManagement:exporter.filter.contact"),
              type: "input",
              props: {
                placeholder: t(
                  "staffManagement:exporter.filter.contactPlaceholder"
                ),
              },
            },
            {
              key: "exporterUserMainFieldType",
              label: t("common:staffType"),
              type: "searchSelect",
              props: {
                placeholder: t("staffManagement:exporter.placeholder.all"),
                allowClear:
                  filterData.exporterUserMainFieldType === "all" ? false : true,
                onChange: (value) => {
                  if (!value) {
                    onFilterDataChange("exporterUserMainFieldType", "all");
                    onForceSearch("exporterUserMainFieldType", "all");
                  } else {
                    onFilterDataChange("exporterUserMainFieldType", value);
                    onForceSearch("exporterUserMainFieldType", value);
                  }
                },
              },
            },
            {
              key: "mainCategoryCodeItemName",
              label: t("staffManagement:exporter.filter.categoryInCharge"),
              type: "searchSelect",
              props: {
                placeholder: t("staffManagement:exporter.placeholder.all"),
                allowClear:
                  filterData.mainCategoryCodeItemName === "all" ? false : true,
                onChange: (value) => {
                  if (!value) {
                    onFilterDataChange("mainCategoryCodeItemName", "all");
                    onForceSearch("mainCategoryCodeItemName", "all");
                  } else {
                    onFilterDataChange("mainCategoryCodeItemName", value);
                    onForceSearch("mainCategoryCodeItemName", value);
                  }
                },
              },
            },
            {
              key: "isActivated",
              label: t("staffManagement:exporter.filter.use"),
              type: "searchSelect",
              props: {
                placeholder: t("staffManagement:exporter.placeholder.all"),
                allowClear: (filterData.isActivated as any) !== "all",
                onChange: (value) => {
                  if (!value) {
                    onFilterDataChange("isActivated", "all");
                    onForceSearch("isActivated", "all");
                  } else {
                    onFilterDataChange("isActivated", value);
                    onForceSearch("isActivated", value);
                  }
                },
              },
            },
            {
              key: "createdAt",
              label: t("staffManagement:exporter.filter.joinDate"),
              type: "dateRangePicker",
            },
          ]}
          onChange={(key, value) => {
            onFilterDataChange(key, value);

            if (FAST_SEARCH_QUERY_KEY.includes(key)) {
              onForceSearch(key, value);
            }
          }}
          onReset={onResetFilter}
          onSubmit={onSearch}
          optionList={[
            {
              key: "mainCategoryCodeItemName",
              option: [allOptionItem, ...mainCategoryFilterList],
            },
            {
              key: "isActivated",
              option: [
                allOptionItem,
                { value: "true", label: "Y" },
                { value: "false", label: "N" },
              ],
            },
            {
              key: "exporterUserMainFieldType",
              option: [
                allOptionItem,
                ...CLIENT_TYPE_OPTION_LIST.map((item) => {
                  return { ...item, label: t(item.langKey) };
                }),
              ],
            },
          ]}
        />

        <div>
          <TableTitleContainer>
            <Typo color="gray4" typoType="b5m">
              {t("staffManagement:exporter.table.title")}
            </Typo>

            {isAbleToEdit && (
              <ButtonContainer>
                <Button buttonSize={40} onClick={handlePermissionChangeClick}>
                  {t("staffManagement:exporter.button.editEmployeeType")}
                </Button>
              </ButtonContainer>
            )}
          </TableTitleContainer>

          <SectionCardWithoutHeader>
            <Table
              ref={gridRef}
              rowData={isError ? [] : staffList}
              isPaginationDataMaping
              columnDefs={columnDefs}
              handlePaginationClick={(page, pageSize) =>
                onPaginationChange({ page, pageSize })
              }
              totalPage={count}
              onRowClicked={(e) => {
                /**
                 * - 일반매니저 수정 x
                 * - 중간매니저 기업관리자에 정보 수정 x
                 */
                if (
                  user?.exporterUserType === "MIDDLE_MANAGER" &&
                  e.data.exporterUserType === "CORPORATE_MANAGER"
                ) {
                  return;
                }

                if (user?.exporterUserType !== "MANAGER") {
                  return handleSelectRow(e.data);
                }
              }}
              pageSize={pagination.pageSize}
              page={pagination.page}
              onSortChange={(sortSource, isClickedHeader) =>
                !!isClickedHeader && onSortChange(sortSource as any)
              }
              onGridReady={() => setIsReady(true)}
            />
          </SectionCardWithoutHeader>
        </div>
      </FlexColumn>
    </ExporterMainLayout>
  );
};

export default ExporterStaffManagementPage;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const TableTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
