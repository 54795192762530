export const transformValueOrNull = <T extends {}, K extends keyof T>({
  targetObject,
  additionalNullValue = [],
  removeFieldKey,
}: {
  targetObject: T;
  additionalNullValue?: any[];
  removeFieldKey?: K[];
}) => {
  const paramsRemoveFieldKey = removeFieldKey || [];

  return Object.fromEntries(
    Object.entries(targetObject)
      .filter(([key]) => !(paramsRemoveFieldKey as string[]).includes(key))
      .reduce<any>((acc, [key, val]) => {
        if ([...additionalNullValue, undefined, null, ""]?.includes(val)) {
          return [...acc, [key, null]];
        }

        return [...acc, [key, val]];
      }, [])
  );
};

export const transformValueOrUndefined = <T extends {}, K extends keyof T>({
  targetObject,
  additionalNullValue = [],
  removeFieldKey,
}: {
  targetObject: T;
  additionalNullValue?: any[];
  removeFieldKey?: K[];
}) => {
  const paramsRemoveFieldKey = removeFieldKey || [];

  return Object.fromEntries(
    Object.entries(targetObject)
      .filter(([key]) => !(paramsRemoveFieldKey as string[]).includes(key))
      .reduce<any>((acc, [key, val]) => {
        if ([...additionalNullValue, undefined, null, ""]?.includes(val)) {
          return [...acc, [key, undefined]];
        }

        return [...acc, [key, val]];
      }, [])
  );
};

export const dataConvertUndefined = (
  data: Record<string, any>,
  allKeyList?: string[]
) => {
  const dataConvertToUndefined = Object.entries(data).reduce(
    (acc: any, [key]) => {
      const isAllValue = allKeyList?.includes(key);
      if (isAllValue) {
        acc[key] = "all";
      } else {
        acc[key] = undefined;
      }

      return acc;
    },
    {}
  );

  return dataConvertToUndefined;
};

/**
 *
 * @param urlString : URL형태의 문자열
 * @description URL형태의 문자열을 객체로 바꿔주는 함수
 */
export const transformURLSearchParamsToObject = (urlString: string) => {
  return Object.fromEntries(new URLSearchParams(urlString));
};

// 타입을 PascalCase로 변환하는 유틸리티 함수
export const toPascalCase = (str: string): string => {
  return str
    .toLowerCase()
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join("");
};

// HTML 인코딩 (저장할 때 사용)
export const encodeHtml = (str: string): string => {
  return str
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#39;")
    .replace(/\(/g, "&#40;")
    .replace(/\)/g, "&#41;")
    .replace(/\//g, "&#47;")
    .replace(/\\/g, "&#92;")
    .replace(/=/g, "&#61;")
    .replace(/\[/g, "&#91;")
    .replace(/\]/g, "&#93;")
    .replace(/;/g, "&#59;")
    .replace(/\*/g, "&#42;")
    .replace(/\|/g, "&#124;")
    .replace(/\+/g, "&#43;")
    .replace(/-/g, "&#45;")
    .replace(/,/g, "&#44;")
    .replace(/\./g, "&#183;");
};

// HTML 디코딩 (사용할 때 사용)
export const decodeHtml = (str: string): string => {
  return str
    .replace(/&amp;/g, "&")
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&quot;/g, '"')
    .replace(/&#39;/g, "'")
    .replace(/&#40;/g, "(")
    .replace(/&#41;/g, ")")
    .replace(/&#47;/g, "/")
    .replace(/&#92;/g, "\\")
    .replace(/&#61;/g, "=")
    .replace(/&#91;/g, "[")
    .replace(/&#93;/g, "]")
    .replace(/&#59;/g, ";")
    .replace(/&#42;/g, "*")
    .replace(/&#124;/g, "|")
    .replace(/&#43;/g, "+")
    .replace(/&#45;/g, "-")
    .replace(/&#44;/g, ",")
    .replace(/&#183;/g, ".");
};
