import { ReactNode } from "react";
import styled from "styled-components";
import LogoSvg from "@/src/assets/logos/logo-export.svg";
import LogoDevSvg from "@/src/assets/logos/logo-export-dev.svg";
import { useNavigate } from "react-router-dom";
import PublicFooter from "../../molecule/PublicFooter";
import PUBLIC_PATH from "@/src/routes/public/path";
import { isDevelopment } from "@/src/utils/environment";

interface SignUpLayoutProps {
  children?: ReactNode;
  onLogoClick?: () => void;
  isLogoClick?: boolean;
}

function SignUpLayout({
  children,
  onLogoClick,
  isLogoClick = true,
}: SignUpLayoutProps) {
  const navigate = useNavigate();

  return (
    <Layout>
      <StyledHeader>
        <Logo
          logoSrc={isDevelopment ? LogoDevSvg : LogoSvg}
          isDevelopment={isDevelopment}
          onClick={() =>
            isLogoClick &&
            (onLogoClick ? onLogoClick() : navigate(PUBLIC_PATH.LOGIN))
          }
        />
      </StyledHeader>

      <Flex>{children}</Flex>
      <PublicFooter />
    </Layout>
  );
}

export default SignUpLayout;

const Layout = styled.div`
  min-width: 1250px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;
const Flex = styled.div`
  flex: 1;
`;
const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 0;
`;

const Logo = styled.span<{ logoSrc: string; isDevelopment: boolean }>`
  display: inline-block;
  width: ${({ isDevelopment }) => (isDevelopment ? "197px" : "156px")};
  height: 24px;
  background: ${({ logoSrc }) => `url(${logoSrc}) center center no-repeat`};
  background-size: contain;
  cursor: pointer;
`;
