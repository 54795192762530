import React, { useId, useRef, useState } from "react";
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@radix-ui/react-accordion";
import styled, { css } from "styled-components";
import colorSet, { ColorType } from "@/src/styles/color";
import Icon from "@/src/components/atom/Icon";
import {
  renderContainerWeighCheckedStatus,
  renderIsWritedInAppStatus,
  renderLoadingContainerStatusBadge,
} from "../../../utils/renderTaskStatusBadge";
import {
  ContainerDetailViewDto,
  ContainerListViewDto,
  ContainerMediaType,
  ContainerStatusType,
  SimpleContainerMediaDto,
  TaskWeighingStatusType,
} from "@/src/store/apis/tasks/taskDetail/interface";
import Typo from "@/src/components/atom/Typo";
import SectionCard from "@/src/components/molecule/SectionCard";
import Checkbox from "@/src/components/atom/Checkbox";
import { PulseLoader } from "react-spinners";
import { ReactComponent as EditSvg } from "@/src/assets/icons/icon-edit-pencel.svg";
import PreviewSvg from "@/src/assets/icons/icon-preview-indigo.svg";
import ArrowDownSvg from "@/src/assets/icons/icon-chevron-down.svg";
import IconButton from "@/src/components/atom/Button/IconButton";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import { downloadFile } from "@/src/utils/downloadFile";
import DownloadIndigoSvg from "@/src/assets/icons/icon-download-indigo.svg";
import {
  renderContainerIcon,
  renderContainerText,
} from "../utils/renderContainerType";
import { Button } from "@/src/components/atom/Button";
import dayjs from "dayjs";
import ResetSvg from "@/src/assets/icons/icon-filter-reset.svg";
import {
  taskDetailApi,
  useLazyGetTaskDetailContainerQuery,
  useTaskDetailPrefetch,
  useUpdateContainersWeightStatusMutation,
} from "@/src/store/apis/tasks/taskDetail";
import TaskThumbnail from "../TaskThumbnail";
import useAlert from "@/src/hooks/useAlert";
import getDateDifference from "@/src/utils/getDateDifference";
import { useContainerIdToSeaVantageUrlMutation } from "@/src/store/apis/seavantage";
import TableBlueText from "@/src/components/molecule/TableBlueText";
import { ReactComponent as ErrorCircleSvg } from "@/src/assets/icons/icon-error-circle-fill.svg";
import EditContainerInformationDialog, {
  EditContainerTabType,
} from "../dialog/EditContainerInformationDialog";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ViewPhotoCollectionDialog, {
  ContainerMediaData,
} from "@/src/components/organism/ViewPhotoCollectionDialog";
import { ReactComponent as DownloadSvg } from "@/src/assets/icons/icon-download-black.svg";
import { slideDown, slideUp } from "@/src/styles/animation";
import { toPascalCase } from "@/src/utils/transform";

type TaskContainerDialogItemType = "VIEW_PHOTO" | "EDIT_CONTAINER" | null;

interface ContainerAccordionItemProps {
  containerId: number;
  onCheckChange?: (id: number, checked: boolean) => void;
  containerNo?: string;
  sealNo?: string;
  containerStatus: ContainerStatusType;
  containerWeighingStatus: TaskWeighingStatusType;
  isUnableEdit: boolean;
  containerCheckList: number[];
  index: number;
  extraRemark: string | null;
}

const containerCountMediaMap = {
  containerSampleBaleMediasCount: "sampleBaleSimpleContainerMedias",
  containerEmptyMediasCount: "emptySimpleContainerMedias",
  containerQuarterLoadedMediasCount: "quarterLoadedSimpleContainerMedias",
  containerHalfLoadedMediasCount: "halfLoadedSimpleContainerMedias",
  containerFullLoadedMediasCount: "fullLoadedSimpleContainerMedias",
  containerOneDoorClosedMediasCount: "oneDoorClosedSimpleContainerMedias",
  containerBothDoorsClosedMediasCount: "bothDoorsClosedSimpleContainerMedias",
  containerCloseUpSealMediasCount: "closeUpSealSimpleContainerMedias",
  containerExtraMediasCount: "extraSimpleContainerMedias",
};

const countKeyConvertToContainerType = (
  countKey: keyof typeof containerCountMediaMap
): ContainerMediaType | undefined => {
  if (countKey === "containerSampleBaleMediasCount") {
    return "SAMPLE_BALE";
  }
  if (countKey === "containerEmptyMediasCount") {
    return "EMPTY";
  }
  if (countKey === "containerQuarterLoadedMediasCount") {
    return "QUARTER_LOADED";
  }
  if (countKey === "containerHalfLoadedMediasCount") {
    return "HALF_LOADED";
  }
  if (countKey === "containerFullLoadedMediasCount") {
    return "FULL_LOADED";
  }
  if (countKey === "containerOneDoorClosedMediasCount") {
    return "ONE_DOOR_CLOSED";
  }
  if (countKey === "containerBothDoorsClosedMediasCount") {
    return "BOTH_DOORS_CLOSED";
  }
  if (countKey === "containerCloseUpSealMediasCount") {
    return "CLOSE_UP_SEAL";
  }
  if (countKey === "containerExtraMediasCount") {
    return "EXTRA";
  }
};

function ContainerAccordionItem({
  containerId,
  onCheckChange,
  containerNo,
  sealNo,
  containerStatus,
  containerWeighingStatus,
  isUnableEdit,
  containerCheckList,
  index,
  extraRemark,
}: ContainerAccordionItemProps) {
  const { t } = useTranslation();
  const alert = useAlert();
  const isFetched = useRef(false);
  const id = useId();
  const loadingImageRef = useRef<HTMLDivElement>(null);
  const loadingWeightRef = useRef<HTMLDivElement>(null);
  const params = useParams();

  const [selectDialog, setSelectDialog] =
    useState<TaskContainerDialogItemType>(null);
  const [startIndex, setStartIndex] = useState<number>();
  const [selectLoading, setSelectLoading] = useState<
    ContainerListViewDto | ContainerDetailViewDto | null
  >(null);
  const [isRefetched, setIsRefetched] = useState<boolean>(false);
  const [selectEditTab, setSelectEditTab] =
    useState<EditContainerTabType | null>(null);
  const [isPreviewDownloadLoading, setIsPreviewDownloadLoading] =
    useState(false);
  const [selectImageUrl, setSelectImage] = useState<ContainerMediaData | null>(
    null
  );

  // API
  const [getLoadingDetailContainer, loadingDetailContainerData] =
    useLazyGetTaskDetailContainerQuery();
  const { currentData, isFetching } =
    taskDetailApi.endpoints.getTaskDetailContainer.useQueryState({
      id: containerId,
    });
  const [containerIdToSeaVantageUrl] = useContainerIdToSeaVantageUrlMutation();
  const [updateContainersWeightStatus] =
    useUpdateContainersWeightStatusMutation();
  const prefetch = useTaskDetailPrefetch("getTaskDetailContainer");

  const isContainerCheckCompleted = isRefetched
    ? (loadingDetailContainerData.currentData
        ?.containerWeighingStatus as TaskWeighingStatusType)
    : containerWeighingStatus;

  const handlePrefetch = async () => {
    try {
      await prefetch({ id: containerId }, { force: !isFetched.current });
      isFetched.current = true;
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const handleContainerRefetch = async () => {
    try {
      await getLoadingDetailContainer({ id: containerId }).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    } finally {
      setIsRefetched(true);
    }
  };

  const handleContainerNoClick = async () => {
    try {
      const { cargoTrackUrl } = await containerIdToSeaVantageUrl({
        containerId,
      }).unwrap();

      window.open(cargoTrackUrl, "_blank");
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const handleConfirmClick = async () => {
    try {
      await updateContainersWeightStatus({
        id: Number(params.id),
        containerIdList: [containerId],
      });

      alert.showAlert({
        type: "success",
        message: t("alert:containerConfirmSuccess"),
      });

      handleContainerRefetch();
    } catch (e) {
      alert.showAlert({
        type: "error",
        message: t("alert:containerConfirmError"),
      });
    }
  };

  const formatSingleContainerMedia = (
    containerData: any
  ): { folderName: string; images: ContainerMediaData[] } => {
    const {
      containerNo,
      sampleBaleSimpleContainerMedias,
      emptySimpleContainerMedias,
      quarterLoadedSimpleContainerMedias,
      halfLoadedSimpleContainerMedias,
      fullLoadedSimpleContainerMedias,
      oneDoorClosedSimpleContainerMedias,
      bothDoorsClosedSimpleContainerMedias,
      closeUpSealSimpleContainerMedias,
      extraSimpleContainerMedias,
    } = containerData;

    // 미디어 타입별로 데이터를 분리하여 처리
    const allMediaDataByType: Record<string, any[]> = {
      SAMPLE_BALE: sampleBaleSimpleContainerMedias || [],
      EMPTY: emptySimpleContainerMedias || [],
      QUARTER_LOADED: quarterLoadedSimpleContainerMedias || [],
      HALF_LOADED: halfLoadedSimpleContainerMedias || [],
      FULL_LOADED: fullLoadedSimpleContainerMedias || [],
      ONE_DOOR_CLOSED: oneDoorClosedSimpleContainerMedias || [],
      BOTH_DOORS_CLOSED: bothDoorsClosedSimpleContainerMedias || [],
      CLOSE_UP_SEAL: closeUpSealSimpleContainerMedias || [],
      EXTRA: extraSimpleContainerMedias || [],
    };

    const images: ContainerMediaData[] = [];

    // 타입별로 파일명과 URL을 생성하여 이미지 리스트에 추가
    Object.keys(allMediaDataByType).forEach((type) => {
      const mediaList = allMediaDataByType[type];

      if (mediaList.length > 0) {
        mediaList.forEach((media: any, index) => {
          images.push({
            createAt: media.containerMediaCreatedAt,
            mediaUrl: media.containerPhotoSimpleMedia.mediaUrl, // mediaUrl 경로
            originalFileName: `${containerNo || "-"}_${toPascalCase(
              type
            )}${String(index + 1).padStart(2, "0")}.jpg`,
            latitude: media.coordinate?.latitude
              ? media.coordinate?.latitude
              : undefined,
            longitude: media.coordinate?.longitude
              ? media.coordinate?.longitude
              : undefined,
            zoomLevel: media.coordinate?.zoomLevel,
          });
        });
      }
    });

    return {
      folderName: containerNo || "-",
      images,
    };
  };

  const containerImageListMaping = Object.entries(
    containerCountMediaMap
  ).reduce<{
    lastUpdatedAt: undefined | string;
    imageResource: {
      isUsed: boolean;
      imageList: (string | undefined)[];
      icon: any;
      title: any;
      mediaDataList: ContainerMediaData[];
      description?: string;
    }[];
  }>(
    (acc, [countKey, mediaKey]) => {
      const count =
        currentData?.[countKey as keyof ContainerDetailViewDto] ?? 0;
      let lastUpdatedAt = acc.lastUpdatedAt;

      const mediaDataList = count
        ? (
            currentData?.[
              mediaKey as keyof ContainerDetailViewDto
            ] as SimpleContainerMediaDto[]
          )?.map(
            ({
              containerPhotoSimpleMedia,
              containerMediaCreatedAt,
              coordinate,
            }) => {
              return {
                createAt: containerMediaCreatedAt,
                latitude: coordinate?.latitude ?? undefined,
                longitude: coordinate?.longitude ?? undefined,
                zoomLevel: coordinate?.zoomLevel
                  ? Number(coordinate?.zoomLevel)
                  : undefined,
                originalFileName:
                  containerPhotoSimpleMedia.originalFileName ?? undefined,
                mediaUrl: containerPhotoSimpleMedia
                  ? containerPhotoSimpleMedia?.mediaUrl
                  : undefined,
              };
            }
          ) ?? []
        : [];

      const imageList = count
        ? (
            currentData?.[
              mediaKey as keyof ContainerDetailViewDto
            ] as SimpleContainerMediaDto[]
          )?.map(({ containerPhotoSimpleMedia, containerMediaUpdatedAt }) => {
            if (!lastUpdatedAt) {
              lastUpdatedAt = containerMediaUpdatedAt;
            }

            if (dayjs(containerMediaUpdatedAt).isAfter(dayjs(lastUpdatedAt))) {
              lastUpdatedAt = containerMediaUpdatedAt;
            }
            return containerPhotoSimpleMedia.mediaUrl;
          }) ?? []
        : [];

      const containerType = countKeyConvertToContainerType(
        countKey as keyof typeof containerCountMediaMap
      );

      const resource = {
        isUsed: !!count,
        imageList: [
          ...(imageList as unknown as string[]),
          ...Array.from({ length: count as number }, () => undefined),
        ].slice(0, count as number),
        icon: renderContainerIcon(containerType),
        title: renderContainerText(t, containerType, "b7m"),
        description:
          containerType === "EXTRA" ? extraRemark ?? undefined : undefined,
        mediaDataList,
      };

      return {
        lastUpdatedAt,
        imageResource: [...acc.imageResource, resource].filter(
          (item) => item.isUsed
        ),
      };
    },
    {
      lastUpdatedAt: undefined,
      imageResource: [],
    }
  );

  const photoTypeList = currentData
    ? formatSingleContainerMedia(currentData)
    : { folderName: "", images: [] };

  const allImageList = containerImageListMaping.imageResource.reduce<string[]>(
    (acc, val) => {
      const validImage = val.imageList.filter((image) => image) as string[];

      return [...acc, ...validImage];
    },
    []
  );

  const allMediaDataList = containerImageListMaping.imageResource.reduce<
    ContainerMediaData[]
  >(
    (acc, val) => {
      const validImage = val.mediaDataList;

      return [...acc, ...validImage].filter(
        ({ mediaUrl }) => mediaUrl !== undefined
      );
    },
    [
      {
        latitude: undefined,
        longitude: undefined,
        mediaUrl: undefined,
        originalFileName: undefined,
        zoomLevel: undefined,
      },
    ]
  );

  const getLastEditedConnectionType = () => {
    if (!currentData?.lastEditedConnectionType) {
      return "[-]";
    }

    if (currentData?.lastEditedConnectionType === "WEB") {
      return "[PC]";
    }

    return `[${currentData?.lastEditedConnectionType}]`;
  };

  const handlePreviewDownloadClick = async () => {
    setIsPreviewDownloadLoading(true);
    try {
      const selectUrl = selectImageUrl;
      if (!selectUrl?.mediaUrl) {
        return;
      }

      await downloadFile(
        selectUrl?.mediaUrl ?? "",
        selectUrl?.originalFileName ?? ""
      );
    } finally {
      setIsPreviewDownloadLoading(false);
    }
  };

  const renderDialogs = () => {
    // 수출자 > 로딩 상세 > 컨테이너 > 컨테이너 사진 슬라이드
    if (selectDialog === "VIEW_PHOTO") {
      return (
        <ViewPhotoCollectionDialog
          items={allImageList.map((item) => {
            return { original: item, thumbnail: item };
          })}
          open={selectDialog === "VIEW_PHOTO"}
          onClose={() => {
            setSelectDialog(null);
            setSelectLoading(null);
          }}
          selectLoading={selectLoading}
          startIndex={startIndex}
          mediaDataList={photoTypeList.images}
          mediaCoordinateRange="ALL"
          onSelectImageChange={(media) => setSelectImage(media)}
          photoDetailRightAccessory={
            <IconButton
              buttonGrade="tertiary"
              buttonColor="black"
              buttonSize={32}
              onClick={handlePreviewDownloadClick}
              isLoading={isPreviewDownloadLoading}
            >
              <DownloadIcon />
            </IconButton>
          }
        />
      );
    }

    if (selectDialog === "EDIT_CONTAINER" && selectEditTab) {
      return (
        <EditContainerInformationDialog
          open
          tabType={selectEditTab}
          containerId={containerId}
          onOpenChange={() => {
            setSelectDialog(null);
            setSelectEditTab(null);
          }}
          refetch={handleContainerRefetch}
          disableTabChange={isUnableEdit}
          mediaDataList={allMediaDataList}
        />
      );
    }
  };

  const renderWritedInAppStatusBadge = () => {
    if (currentData?.containerWeighingStatus === "WEIGHING_CHECK_COMPLETED") {
      return;
    }

    if (currentData?.isLastWeighingCompletedByApp) {
      if (currentData?.isLastNetWeightUpdatedByWeb) {
        return;
      }

      return renderIsWritedInAppStatus({
        type: true,
        isTooltip: true,
        t,
      });
    }

    if (!currentData?.isLastNetWeightUpdatedByWeb) {
      return;
    }
  };

  return (
    <AccordionItem
      value={id}
      onMouseEnter={() => {
        handlePrefetch();
      }}
      onKeyDown={() => {
        handlePrefetch();
      }}
    >
      <StyledAccordionTrigger>
        <AccordionTriggerContainer onClick={(e) => e.stopPropagation()}>
          <Checkbox
            onChange={(e) => {
              e.stopPropagation();
              onCheckChange?.(containerId, e.target.checked);
            }}
            checked={containerCheckList.includes(containerId)}
          />

          {/* Badge, Container No, Seal No */}
          <AccordionTriggerTextContainer>
            <IconContainer>
              {renderContainerWeighCheckedStatus({
                type: isContainerCheckCompleted,
                isTooltip: true,
                t,
              })}

              {renderWritedInAppStatusBadge()}
            </IconContainer>

            <TextAndBadgeContainer>
              <TextContainer>
                <Typo typoType="b7m">
                  {t("common:dynamicContainerNo", { index: index + 1 })}
                </Typo>

                {!loadingDetailContainerData?.currentData?.containerNo &&
                !containerNo ? (
                  <Typo typoType="b7m" color="blue2">
                    -
                  </Typo>
                ) : (
                  <TableBlueText
                    typoType="b7m"
                    color="systemBlue2"
                    onClick={() => handleContainerNoClick()}
                  >
                    {isRefetched
                      ? loadingDetailContainerData?.currentData?.containerNo
                      : containerNo}
                  </TableBlueText>
                )}
              </TextContainer>

              <TextContainer>
                <Typo typoType="b7m">{t("common:sealNo")}</Typo>
                <Typo typoType="b7m" color="blue2">
                  {isRefetched
                    ? loadingDetailContainerData?.currentData?.sealNo ?? "-"
                    : sealNo ?? "-"}
                </Typo>
              </TextContainer>

              <TextContainer>
                {renderLoadingContainerStatusBadge({
                  type: isRefetched
                    ? (loadingDetailContainerData.currentData
                        ?.containerStatus as ContainerStatusType)
                    : containerStatus,
                  size: "S",
                  t,
                })}
              </TextContainer>
            </TextAndBadgeContainer>
          </AccordionTriggerTextContainer>
        </AccordionTriggerContainer>

        <ArrowIcon iconSrc={ArrowDownSvg} iconSize={24} />
      </StyledAccordionTrigger>
      <StyledAccordionContent>
        <AccordionSectionCardContent>
          {/* UpdateDate */}
          <UpdateDate>
            <Dot />
            <Typo typoType="b9r" color="gray6">
              {`${t(
                "task:detail.cards.lastUploadDate"
              )} ${getLastEditedConnectionType()}`}
            </Typo>
            <Typo typoType="b9r" color="gray7">
              {getDateDifference({
                updateAt: currentData?.updatedAt || "",
              })}
            </Typo>
          </UpdateDate>

          {/* Container Loading Information */}
          <StyledSectionCard
            cardTitle={
              <SectionCardTitle>
                <Typo typoType="h6">
                  {t("task:detail.cards.containerLoadingInformation")}
                </Typo>
                {renderLoadingContainerStatusBadge({
                  type: isRefetched
                    ? (loadingDetailContainerData.currentData
                        ?.containerStatus as ContainerStatusType)
                    : containerStatus,
                  size: "S",
                  t,
                })}
              </SectionCardTitle>
            }
            rightAccessory={
              <SectionCardRight>
                <IconButton
                  buttonColor="black"
                  buttonGrade="tertiary"
                  buttonSize={32}
                  onClick={handleContainerRefetch}
                >
                  <Icon iconSrc={ResetSvg} iconSize={20} />
                </IconButton>

                <EditButton
                  buttonGrade="tertiary"
                  buttonColor="black"
                  buttonSize={32}
                  disabled={isUnableEdit}
                  onClick={() => {
                    setSelectEditTab("PHOTO");
                    setSelectDialog("EDIT_CONTAINER");
                  }}
                >
                  <EditIcon color={isUnableEdit ? "gray8" : "gray2"} />
                  {t("task:detail.button.editButton")}
                </EditButton>
              </SectionCardRight>
            }
          >
            <TaskInformationContainer ref={loadingImageRef}>
              {isFetching ? (
                <LoadingContainer
                  height={
                    loadingImageRef?.current?.getBoundingClientRect().height
                  }
                >
                  <PulseLoader color={colorSet.blue4} />
                </LoadingContainer>
              ) : (
                containerImageListMaping.imageResource.map((item, idx) => {
                  return (
                    <TaskThumbnail
                      index={idx}
                      key={idx}
                      title={item.title}
                      icon={item.icon}
                      description={item.description}
                      imageUrlList={item.imageList}
                      onClickImage={(imageUrl) => {
                        if (!currentData) {
                          return;
                        }

                        setSelectLoading(currentData);
                        setSelectDialog("VIEW_PHOTO");
                        setStartIndex(
                          allImageList.findIndex((item) => item === imageUrl)
                        );
                      }}
                    />
                  );
                })
              )}
            </TaskInformationContainer>
          </StyledSectionCard>

          {/* Weight Information */}
          <StyledSectionCard
            cardTitle={
              <SectionCardTitle>
                <Typo typoType="h6">
                  {t("task:detail.cards.weightInformation")}
                </Typo>
                {renderContainerWeighCheckedStatus({
                  type: isContainerCheckCompleted,
                  isTooltip: true,
                  t: t,
                })}

                {renderWritedInAppStatusBadge()}
              </SectionCardTitle>
            }
            rightAccessory={
              <SectionCardRight>
                <IconButton
                  buttonColor="black"
                  buttonGrade="tertiary"
                  buttonSize={32}
                  onClick={handleContainerRefetch}
                >
                  <Icon iconSrc={ResetSvg} iconSize={20} />
                </IconButton>

                <EditButton
                  buttonGrade="tertiary"
                  buttonColor="black"
                  buttonSize={32}
                  onClick={() => {
                    setSelectEditTab("WEIGHT");
                    setSelectDialog("EDIT_CONTAINER");
                  }}
                >
                  <EditIcon color="gray2" />
                  {t("task:detail.button.editButton")}
                </EditButton>
              </SectionCardRight>
            }
          >
            {isFetching ? (
              <LoadingContainer
                height={
                  loadingWeightRef?.current?.getBoundingClientRect().height
                }
              >
                <PulseLoader color={colorSet.blue4} />
              </LoadingContainer>
            ) : (
              <WeightInformationContainer ref={loadingWeightRef}>
                <WeightInformationContent>
                  <Typo typoType="b9m" color="gray7">
                    {t("task:detail.cards.weightInformationSubTitle")}
                  </Typo>

                  <WeightInformationRows>
                    <SectionCardRow
                      label={t("common:unitQuantity")}
                      value={
                        !currentData?.unitQuantity &&
                        !currentData?.unitQuantityUnit ? (
                          "-"
                        ) : (
                          <>
                            {currentData?.unitQuantity
                              ? currentData.unitQuantity.toLocaleString("ko-KR")
                              : ""}
                            {currentData?.unitQuantityUnit ?? ""}
                          </>
                        )
                      }
                    />
                    <SectionCardRow
                      label={t("common:grossWeight")}
                      value={
                        currentData?.grossWeight && currentData?.weightUnit
                          ? `${Number(currentData?.grossWeight).toLocaleString(
                              "ko-KR"
                            )} ${currentData?.weightUnit}`
                          : "-"
                      }
                    />
                    <SectionCardRow
                      label={t("common:netWeight")}
                      value={
                        currentData?.netWeight && currentData?.weightUnit
                          ? `${Number(currentData?.netWeight).toLocaleString(
                              "ko-KR"
                            )} ${currentData?.weightUnit} (${
                              currentData?.convertedNetWeight
                            })`
                          : "-"
                      }
                    />
                  </WeightInformationRows>
                </WeightInformationContent>

                <GrayLine />

                <WeightInformationContent>
                  <Typo typoType="b9m" color="gray7">
                    {t("task:detail.cards.detailInformation")}
                  </Typo>

                  <WeightInformationRows>
                    <SectionCardRow
                      label={t("task:detail.cards.photoSubmittedDate")}
                      value={
                        currentData?.photoSubmittedDateAt
                          ? `${dayjs(currentData?.photoSubmittedDateAt).format(
                              "YYYY-MM-DD HH:mm"
                            )}`
                          : "-"
                      }
                    />
                    <SectionCardRow
                      label={t("task:detail.cards.firstWeight")}
                      value={
                        currentData?.firstWeight && currentData?.weightUnit
                          ? `${Number(currentData?.firstWeight).toLocaleString(
                              "ko-KR"
                            )} ${currentData?.weightUnit}`
                          : "-"
                      }
                    />
                    <SectionCardRow
                      label={t("task:detail.cards.secondWeight")}
                      value={
                        currentData?.secondWeight && currentData?.weightUnit
                          ? `${Number(currentData?.secondWeight).toLocaleString(
                              "ko-KR"
                            )} ${currentData?.weightUnit}`
                          : "-"
                      }
                    />
                    <SectionCardRow
                      label={t("task:detail.cards.weighingTicket")}
                      value={
                        <FileInfoContainer>
                          {currentData?.weighingTicketMedias &&
                          currentData.weighingTicketMedias.length >= 1
                            ? currentData.weighingTicketMedias.map(
                                (weightMedia) => {
                                  return (
                                    <div>
                                      <figure>
                                        <Typo typoType="b7r" color="gray5">
                                          {weightMedia.originalFileName}
                                        </Typo>
                                      </figure>
                                      <ButtonContainer>
                                        <IconButton
                                          buttonSize={24}
                                          buttonColor="blue"
                                          buttonGrade="secondary"
                                        >
                                          <Icon
                                            iconSrc={DownloadIndigoSvg}
                                            iconSize={16}
                                            onClick={() =>
                                              downloadFile(
                                                weightMedia.mediaUrl ?? "",
                                                weightMedia.originalFileName ??
                                                  ""
                                              )
                                            }
                                          />
                                        </IconButton>
                                        <IconButton
                                          buttonSize={24}
                                          buttonColor="blue"
                                          buttonGrade="secondary"
                                          disabled={false}
                                        >
                                          <a
                                            href={weightMedia.mediaUrl}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            <Icon
                                              iconSrc={PreviewSvg}
                                              iconSize={16}
                                            />
                                          </a>
                                        </IconButton>
                                      </ButtonContainer>
                                    </div>
                                  );
                                }
                              )
                            : "-"}
                        </FileInfoContainer>
                      }
                    />
                  </WeightInformationRows>
                </WeightInformationContent>
              </WeightInformationContainer>
            )}
          </StyledSectionCard>

          {/* Confirm */}
          <ConfirmButtonContainer>
            <ConfirmDescription>
              <ErrorCircleIcon />
              <Typo typoType="b7m">
                {t("task:detail.description.confirmWeight")}
              </Typo>
            </ConfirmDescription>

            <ConfirmButton
              buttonSize={40}
              onClick={handleConfirmClick}
              disabled={
                isUnableEdit ||
                isContainerCheckCompleted === "WEIGHING_CHECK_COMPLETED"
              }
            >
              {isContainerCheckCompleted === "WEIGHING_CHECK_COMPLETED"
                ? t("task:detail.button.confirmedButton")
                : t("task:detail.button.confirmButton")}
            </ConfirmButton>
          </ConfirmButtonContainer>
        </AccordionSectionCardContent>
      </StyledAccordionContent>

      {renderDialogs()}
    </AccordionItem>
  );
}

export default ContainerAccordionItem;

const ArrowIcon = styled(Icon)`
  transition: 0.3s;
`;

const StyledAccordionTrigger = styled(AccordionTrigger)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: none;
  border: none;
  border-bottom: 1px solid ${colorSet.gray10};
  padding: 16px;
  margin: 0;
  cursor: pointer;

  &:hover {
    background: ${colorSet.blue10};
  }

  &[data-state="open"] {
    ${ArrowIcon} {
      transform: rotateZ(-180deg);
    }
  }

  &[data-state="closed"] {
    ${ArrowIcon} {
      transform: rotateZ(0deg);
    }
  }
`;

const AccordionTriggerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
const AccordionTriggerTextContainer = styled(AccordionTriggerContainer)``;
const IconContainer = styled(AccordionTriggerContainer)``;
const TextAndBadgeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const AccordionSectionCardContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 16px;
  background: ${colorSet.gray11};
`;

const StyledSectionCard = styled(SectionCard)`
  background: ${colorSet.white};
`;

const SectionCardTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SectionCardRight = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const UpdateDate = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
`;

const EditButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const WeightInformationContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 24px;
`;

const WeightInformationContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const WeightInformationRows = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const GrayLine = styled.div`
  width: 1px;
  height: 100%;
  border-left: 1px solid ${colorSet.gray9};
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 4px;
  height: 24px;
`;

const FileInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  & > div {
    display: flex;
    gap: 8px;
  }

  a {
    line-height: 0px;
  }
`;

const TaskInformationContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(auto, 460px));
  justify-content: center;
  gap: 16px;
`;

const LoadingContainer = styled.div<{ height?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ height }) => height}px;
`;

const EditIcon = styled(EditSvg)<{ color: ColorType }>`
  width: 16px;
  height: 16px;

  path {
    fill: ${({ color }) => colorSet[color]};
  }
`;

const ConfirmButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ConfirmDescription = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ConfirmButton = styled(Button)`
  width: 160px;
  text-align: center;
`;

const ErrorCircleIcon = styled(ErrorCircleSvg)`
  flex-shrink: 0;
  width: 20px;
  height: 20px;

  #Shape {
    fill: ${colorSet.blue4};
  }
`;

const Dot = styled.div`
  width: 4px;
  height: 4px;
  background: ${colorSet.gray7};
  border-radius: 100%;
`;

const DownloadIcon = styled(DownloadSvg)<{
  color?: ColorType;
  disabled?: boolean;
}>`
  width: 16px;
  height: 16px;

  path {
    ${({ color }) =>
      color &&
      css`
        fill: ${colorSet[color]};
      `}

    ${({ disabled }) =>
      disabled &&
      css`
        fill: ${colorSet.gray8};
      `}
  }
`;

const StyledAccordionContent = styled(AccordionContent)`
  overflow: hidden;
  transform-origin: top;

  &[data-state="open"] {
    animation: ${slideDown} 0.6s ease-in-out;
  }

  &[data-state="closed"] {
    animation: ${slideUp} 0.6s ease-in-out;
  }
`;
