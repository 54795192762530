import Badge from "@/src/components/atom/Badge";
import Typo from "@/src/components/atom/Typo";
import i18n from "@/src/i18n";
import getTableIndex from "@/src/utils/getTableIndex";
import { ColDef, ValueGetterParams } from "ag-grid-community";
import dayjs from "dayjs";

export const columnInquiryList: ColDef[] = [
  {
    headerName: "No.",
    headerClass: "ag-right-aligned-cell",
    field: "no",
    lockPosition: "left",
    pinned: "left",
    cellStyle: { textAlign: "center" },
    width: 70,
    minWidth: 70,
    resizable: false,
    cellRenderer: function (params: ValueGetterParams) {
      return (
        <Typo typoType="b9r">
          {params.node?.rowIndex
            ? getTableIndex(
                params.node?.rowIndex + 1,
                params.data.page,
                params.data.pageSize
              )
            : getTableIndex(1, params.data.page, params.data.pageSize)}
        </Typo>
      );
    },
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "category",
    flex: 1,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (i18n.language === "ko") {
        return <Typo typoType="b9r">{params.data.categoryKo}</Typo>;
      }

      return <Typo typoType="b9r">{params.data.categoryEn}</Typo>;
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "title",
    flex: 1,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return <Typo typoType="b9r">{params.data.title}</Typo>;
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "createdAt",
    width: 180,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.createdAt ? (
        <Typo typoType="b9r">
          {dayjs(params.data.createdAt).format("YYYY-MM-DD")}
        </Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: {
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    field: "status",
    width: 180,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.status === "replied") {
        return (
          <Badge
            text={params.data.t("status:inquiry.complete")}
            color="systemLime1"
            badgeColor="systemLime5"
            badgeSize={"S"}
          />
        );
      }

      return (
        <Badge
          text={params.data.t("status:inquiry.reply")}
          color="gray12"
          badgeColor="gray7"
          badgeSize={"S"}
        />
      );
    },
  },
];
