import { apiSlice } from "../../baseQuery";
import { Row } from "../../type";
import {
  EmailListDto,
  ExporterDetailViewDto,
  ExporterSignatureMediaDto,
  GenerateSalesContractTermsDto,
  GenerateSalesContractTermsHistoryDto,
  ModifyExporterDto,
  SalesContractTermsWithLatestHistoryDto,
} from "./interface";

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ["company"],
});

const companyApi = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    // 수출회사 상세 정보 조회
    getExporters: build.query<ExporterDetailViewDto, void>({
      query: () => ({
        url: "/exporters/this",
      }),
      providesTags: ["company"],
      transformResponse: (res: Row<ExporterDetailViewDto>) => res.row,
    }),

    // 수출회사 상세 정보 수정
    editExporters: build.mutation<ExporterDetailViewDto, ModifyExporterDto>({
      query: (body) => ({
        method: "PUT",
        url: "/exporters/this",
        body,
      }),
      invalidatesTags: ["company"],
      transformResponse: (res: Row<ExporterDetailViewDto>) => res.row,
    }),

    // Sales Contract 약관 생성
    createSalesContract: build.mutation<
      SalesContractTermsWithLatestHistoryDto,
      GenerateSalesContractTermsDto
    >({
      query: (body) => ({
        method: "POST",
        url: "/exporters/this/sales-contract-terms",
        body: body,
      }),
      invalidatesTags: ["company"],
      transformResponse: (res: Row<SalesContractTermsWithLatestHistoryDto>) =>
        res.row,
    }),

    // Sales Contract 약관 히스토리 생성
    createSalesContractHistory: build.mutation<
      SalesContractTermsWithLatestHistoryDto,
      GenerateSalesContractTermsHistoryDto
    >({
      query: (body) => ({
        method: "POST",
        url: "/exporters/this/sales-contract-terms-histories",
        body: body,
      }),
      invalidatesTags: ["company"],
      transformResponse: (res: Row<SalesContractTermsWithLatestHistoryDto>) =>
        res.row,
    }),

    addSign: build.mutation<
      ExporterSignatureMediaDto,
      { signatureMediaId: number }
    >({
      query: (body) => ({
        url: "/exporters/this/exporter-signature-medias",
        method: "POST",
        body,
      }),
      invalidatesTags: ["company"],
      transformResponse: (res: Row<ExporterSignatureMediaDto>) => res.row,
    }),

    editSign: build.mutation<
      ExporterSignatureMediaDto,
      { signatureMediaId?: number; id: number; isDefault?: boolean }
    >({
      query: (body) => ({
        url: `/exporters/this/exporter-signature-medias/${body.id}`,
        method: "PUT",
        body: {
          signatureMediaId: body.signatureMediaId,
          isDefault: body.isDefault,
        },
      }),
      invalidatesTags: ["company"],
      transformResponse: (res: Row<ExporterSignatureMediaDto>) => res.row,
    }),

    removeSign: build.mutation<void, { id: number }>({
      query: (body) => ({
        url: `/exporters/this/exporter-signature-medias/${body.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["company"],
    }),

    // 연동 코드 이메일 전송
    sendCompanyLinkCode: build.mutation<void, EmailListDto>({
      query: (body) => ({
        url: `/company-link-code`,
        method: "POST",
        body,
      }),
    }),
    // 기업관리자 사인 등록 요청 이메일발송 API
    sendCompanySignRequest: build.mutation<void, void>({
      query: (body) => ({
        url: `/exporter/send-request-sign-registration`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetExportersQuery,
  useLazyGetExportersQuery,
  useEditExportersMutation,

  useCreateSalesContractMutation,
  useCreateSalesContractHistoryMutation,

  useAddSignMutation,
  useEditSignMutation,
  useRemoveSignMutation,

  useSendCompanyLinkCodeMutation,
  useSendCompanySignRequestMutation,
} = companyApi;
export { companyApi };
