import {
  CSSProperties,
  InputHTMLAttributes,
  ReactNode,
  forwardRef,
  useRef,
} from "react";
import { styled } from "styled-components";
import Image, { ImageProps } from "../../atom/Image";
import Icon from "../../atom/Icon";
import { ReactComponent as AddImageSvg } from "@/src/assets/icons/icon-image-add.svg";
import colorSet from "@/src/styles/color";
import typo from "@/src/styles/typography";
import useAlert from "@/src/hooks/useAlert";
import { useUploadBigImageMutation } from "@/src/store/apis/media";
import { MediaDto, MediaFolderType } from "@/src/store/apis/media/interface";
import DeleteSvg from "@/src/assets/icons/icon-close-white.svg";
import { useTranslation } from "react-i18next";
import { isFileAccepted } from "@/src/utils/is";
import { acceptData } from "@/src/utils/accept";

export interface ImageUploadProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "onChange" | "multiple"> {
  imageSrcList?: ImageProps[];
  imageLimit?: number;
  uploadButton?: ReactNode;
  uploadButtonStyle?: CSSProperties;
  onChange?: (imageFile: MediaDto[]) => void;
  onRemove?: (imageSrc: string) => void;
  folder?: MediaFolderType;
}

const ImageUpload = forwardRef<HTMLInputElement, ImageUploadProps>(
  (
    {
      imageSrcList,
      uploadButton,
      imageLimit = 10,
      uploadButtonStyle,
      onChange,
      onRemove,
      folder = "commons/",
      ...inputProps
    },
    ref
  ) => {
    const { t } = useTranslation();
    const alert = useAlert();
    const [uploadBigImage] = useUploadBigImageMutation();
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const handleImageChange = async (
      e: React.ChangeEvent<HTMLInputElement>
    ) => {
      if (!e.target.files) return;

      const filteredFiles = Array.from(e.target.files).filter((file) =>
        isFileAccepted(file, "image/png, image/gif, image/jpeg")
      );
      if (filteredFiles.length === 0) {
        alert.showAlert({
          type: "error",
          message: t("alert:fileUploadFail"),
        });
        return;
      }

      try {
        const res = await uploadBigImage({
          files: Array.from(e.target.files),
          folder,
        }).unwrap();
        onChange?.(res);
      } catch (e: any) {
        const message = Array.isArray(e.data?.message)
          ? e.data?.message[0]
          : e.data?.message;
        alert.showAlert({
          type: "error",
          message: message ?? t("alert:unHandled"),
        });
      }
    };

    const handleDropImage = async (e: React.DragEvent<HTMLDivElement>) => {
      if (!e.dataTransfer.files) return;

      if (
        (imageSrcList?.length ?? 0) + e.dataTransfer.files.length >
        imageLimit
      ) {
        alert.showAlert({
          type: "error",
          message:
            t("alert:imageUploadLimit", { imageLimit }) || t("alert:unHandled"),
        });
        return;
      }

      try {
        const res = await uploadBigImage({
          files: Array.from(e.dataTransfer.files),
          folder,
        }).unwrap();
        onChange?.(res);
      } catch (e: any) {
        const message = Array.isArray(e.data?.message)
          ? e.data?.message[0]
          : e.data?.message;
        alert.showAlert({
          type: "error",
          message: message ?? t("alert:unHandled"),
        });
      }
    };

    return (
      <UploadContainer>
        <ImageUploadContainer data-disabled={!!inputProps.disabled}>
          <ImageUploadButton
            role="button"
            style={uploadButtonStyle}
            onDragOver={(e) => e.preventDefault()}
            data-disabled={!!inputProps.disabled}
            onDrop={(e) => {
              e.preventDefault();
              handleDropImage(e);
            }}
            onClick={() => {
              if (inputProps.disabled) {
                return;
              }
              if (
                fileInputRef.current &&
                (imageSrcList?.length ?? 0) < imageLimit
              ) {
                fileInputRef.current?.click();
              }
            }}
          >
            {uploadButton || (
              <>
                <AddImageIcon data-disabled={inputProps.disabled} />

                <p>
                  {imageSrcList?.length ?? 0}/{imageLimit}
                </p>
              </>
            )}
          </ImageUploadButton>
        </ImageUploadContainer>

        {imageSrcList?.map((props) => {
          const { topRightAccessory, ...restProps } = props;
          return (
            <Image
              {...restProps}
              topRightAccessory={
                topRightAccessory
                  ? topRightAccessory
                  : !inputProps.disabled && (
                      <StyledIcon
                        iconSrc={DeleteSvg}
                        iconSize={20}
                        onClick={(e) => {
                          e.stopPropagation();
                          onRemove?.(restProps.imageSrc ?? "");
                        }}
                      />
                    )
              }
            />
          );
        })}

        <HiddenInput
          ref={(node) => {
            if (node) {
              fileInputRef.current = node;
            }

            if (ref) {
              if (typeof ref === "function") {
                ref(node);
              } else {
                ref.current = node;
              }
            }
          }}
          type="file"
          {...inputProps}
          multiple={false}
          accept={acceptData.image}
          onChange={handleImageChange}
          value={""}
        />
      </UploadContainer>
    );
  }
);

export default ImageUpload;

const UploadContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const ImageUploadContainer = styled.div`
  background: ${colorSet.blue10};
  border-radius: 8px;
  border: 1px dotted ${colorSet.blue7};
  cursor: pointer;

  &[data-disabled="true"] {
    border: 1px dotted ${colorSet.blue8};
    background: ${colorSet.gray11};
    cursor: not-allowed;
  }
`;

const ImageUploadButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;

  p {
    ${typo.b10m};
    color: ${colorSet.blue4};
  }

  &[data-disabled="true"] {
    p {
      color: ${colorSet.gray8};
    }
  }
`;

const HiddenInput = styled.input`
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
`;

const StyledIcon = styled(Icon)`
  filter: drop-shadow(0px 2px 8px rgba(5, 29, 57, 0.25))
    drop-shadow(0px 0px 0px rgba(160, 164, 171, 0.2));
`;

const AddImageIcon = styled(AddImageSvg)`
  width: 24px;
  height: 24px;

  &[data-disabled="true"] {
    path {
      fill: ${colorSet.gray8};
    }
  }
`;
