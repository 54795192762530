import AlertDialog from "@/components/atom/AlertDialog";
import { useTranslation } from "react-i18next";

interface AutoLogoutAlertDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onClose: () => void;
}

const AutoLogoutAlertDialog = ({
  open,
  onOpenChange,
  onClose,
}: AutoLogoutAlertDialogProps) => {
  const { t } = useTranslation();

  return (
    <AlertDialog
      title={t("common:autoLogoutTitle")}
      open={open}
      onOpenChange={onOpenChange}
      onOk={onClose}
      okText={t("common:ok")}
    >
      {t("common:autoLogoutDescription")}
    </AlertDialog>
  );
};

export default AutoLogoutAlertDialog;
