import Typo from "@/src/components/atom/Typo";
import i18n from "@/src/i18n";
import getTableIndex from "@/src/utils/getTableIndex";
import { ColDef, ValueGetterParams } from "ag-grid-community";
import dayjs from "dayjs";

export const columnNoticeList: ColDef[] = [
  {
    headerName: "No.",
    headerClass: "ag-right-aligned-cell",
    field: "no",
    lockPosition: "left",
    pinned: "left",
    cellStyle: { textAlign: "center" },
    width: 70,
    minWidth: 70,
    resizable: false,
    cellRenderer: function (params: ValueGetterParams) {
      return (
        <Typo typoType="b9r">
          {params.node?.rowIndex
            ? getTableIndex(
                params.node?.rowIndex + 1,
                params.data.page,
                params.data.pageSize
              )
            : getTableIndex(1, params.data.page, params.data.pageSize)}
        </Typo>
      );
    },
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "title",
    flex: 1,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return (
        <Typo typoType="b9r">
          {i18n.language === "ko"
            ? params.data.titleKr || "-"
            : params.data.titleEn || "-"}
        </Typo>
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "createdAt",
    width: 180,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.createdAt ? (
        <Typo typoType="b9r">
          {dayjs(params.data.createdAt).format("YYYY-MM-DD")}
        </Typo>
      ) : (
        "-"
      );
    },
  },
];
