import { Button } from "@/src/components/atom/Button";
import Dialog from "@/src/components/atom/Dialog";
import Typo from "@/src/components/atom/Typo";
import React, { useEffect, useRef, useState } from "react";
import AlertDialog from "@/src/components/atom/AlertDialog";
import styled from "styled-components";
import Table from "@/src/components/atom/Table";
import colorSet from "@/src/styles/color";
import { AgGridReact } from "ag-grid-react";
import useAlert from "@/src/hooks/useAlert";
import { ColDef } from "ag-grid-community";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import Loader from "@/src/components/atom/Loader";
import Select from "@/src/components/atom/Select";
import { warehousesApi } from "@/src/store/apis/warehouse";
import { isUndefined } from "@/src/utils/is";
import {
  useLazyGetSaleQuery,
  useLazyGetSalesQuery,
} from "@/src/store/apis/sales";
import { SalesDto } from "@/src/store/apis/sales/interface";
import { useTranslation } from "react-i18next";
import { columnLoadSalesContract } from "../../columns/columnLoadSales";
import DialogFooterContainer from "@/src/components/atom/Dialog/DialogFooterContainer";
import { useAppSelector } from "@/src/store";

interface LoadSalesContractDialogProps {
  open: boolean;
  onClose: () => void;
  onFetchContractViaId: (detail: SalesDto) => void;
}

enum AlertDialogState {
  NULL,
  SELECT,
}

const emptyArray: SalesDto[] = [];

const LoadSalesContractDialog = ({
  open,
  onClose,
  onFetchContractViaId,
}: LoadSalesContractDialogProps) => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.auth.user);
  const gridRef = useRef<AgGridReact>(null);
  const alert = useAlert();
  const [alertDialogState, setAlertDialogState] = useState<AlertDialogState>(
    AlertDialogState.NULL
  );
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [warehouseId, setWarehouseId] = useState<string>();
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
  const [isReady, setIsReady] = useState<boolean>(false);
  const [columnDefs] = useState<ColDef[]>(
    columnLoadSalesContract(setSelectedId)
  );

  useAgGridHeaderRefresh({
    gridRef: gridRef.current,
    isReady,
    headerSet: [
      {
        columnKey: "orderDateAt",
        langKey: "sales:add.loadContractsTable.orderDateAt",
      },
      {
        columnKey: "salesNumber",
        langKey: "sales:add.loadContractsTable.salesNumber",
      },
      {
        columnKey: "exporterItemCode",
        langKey: "sales:add.loadContractsTable.exporterItemCode",
      },
      {
        columnKey: "salesFinalProducts",
        langKey: "sales:add.loadContractsTable.salesFinalProducts",
      },
      {
        columnKey: "warehouse",
        langKey: "sales:add.loadContractsTable.warehouse",
      },
      {
        columnKey: "warehouseEmployee",
        langKey: "sales:add.loadContractsTable.warehouseEmployee",
      },
    ],
  });

  // API
  const [getSale] = useLazyGetSaleQuery();
  const { isWarehousesFetching, warehouses } =
    warehousesApi.endpoints.getWarehouses.useQueryState(
      {},
      {
        selectFromResult: ({ currentData, isFetching, isError }) => {
          const isUnstable = isUndefined(currentData) || isFetching || isError;
          const isStable = !isUnstable;

          return {
            warehouses: isStable ? currentData.rows : [],
            isWarehousesFetching: isFetching,
          };
        },
      }
    );
  const [getSales, { isFetching, count, list }] = useLazyGetSalesQuery({
    selectFromResult: ({ currentData, isFetching, isError }) => {
      const isUnstable = isUndefined(currentData) || isFetching || isError;
      const isStable = !isUnstable;

      return {
        isFetching,
        list: isStable ? currentData.rows : emptyArray,
        count: isStable ? currentData.count : 0,
      };
    },
  });

  const saleList = list.reduce<any>((acc, val) => {
    const resource = {
      id: val.id,
      salesNumber: val.salesNumber,
      orderDateAt: val.orderDateAt,
      warehouse: val.warehouse,
      warehouseEmployee: val.warehouseEmployee,
      exporterItemCode: val.exporterItemCode,
      salesFinalProducts: val.salesFinalProducts,
      isChecked: selectedId === val.id,
    };
    return [...acc, resource];
  }, []);

  const warehouseList = warehouses
    .filter((item) =>
      user?.exporterUserType === "MANAGER"
        ? item.mainCategoryCodeItemNames.some((category) =>
            user?.mainCategoryCodeItemNames.includes(category)
          )
        : true
    )
    .reduce<{ label: string; value: string }[]>(
      (acc, val) => {
        const resource = {
          label: val?.warehouseName || "",
          value: `${val.id}` || "",
        };
        return [...acc, resource];
      },
      [{ label: t("common:all"), value: "all" }]
    );

  const renderAlertDialog = () => {
    if (alertDialogState === AlertDialogState.SELECT) {
      return (
        <AlertDialog
          open
          title={t("sales:common.alertDialog.loadContracts.title")}
          onOpenChange={() => setAlertDialogState(AlertDialogState.NULL)}
          onOk={async () => {
            if (!selectedId) {
              return setAlertDialogState(AlertDialogState.NULL);
            }
            try {
              const res = await getSale({
                id: selectedId,
              }).unwrap();
              onFetchContractViaId(res);
            } catch (e: any) {
              const message = Array.isArray(e.data.message)
                ? e.data.message[0]
                : e.data.message;
              alert.showAlert({ type: "error", message });
            } finally {
              setAlertDialogState(AlertDialogState.NULL);
              onClose();
            }
          }}
          okText={t("sales:common.button.ok")}
          cancelText={t("sales:common.button.cancel")}
        >
          <WhiteSpace>
            {t("sales:common.alertDialog.loadContracts.description")}
          </WhiteSpace>
        </AlertDialog>
      );
    }
  };

  useEffect(() => {
    if (isFetching && gridRef.current) {
      gridRef.current.api.showLoadingOverlay();
    }
  }, [isFetching]);

  useEffect(() => {
    (async () => {
      await getSales({
        page: 1,
        pageSize: 10,
      });
    })();
  }, [getSales]);

  return (
    <>
      <Dialog
        title={t("sales:common.loadContracts")}
        open={open}
        onOpenChange={onClose}
        width={1000}
        footer={
          <DialogFooterContainer>
            <Button
              buttonGrade="tertiary"
              buttonColor="black"
              onClick={onClose}
            >
              {t("sales:common.button.cancel")}
            </Button>
            <Button
              disabled={!selectedId}
              onClick={() => setAlertDialogState(AlertDialogState.SELECT)}
            >
              {t("sales:common.button.select")}
            </Button>
          </DialogFooterContainer>
        }
      >
        <Content>
          <InputContainer>
            <Typo typoType="b7m">{t("sales:common.warehouse")}</Typo>
            <Select
              options={warehouseList}
              showSearch
              defaultValue={"all"}
              suffixIcon={isWarehousesFetching ? <Loader /> : undefined}
              disabled={isWarehousesFetching}
              style={{ width: "100%" }}
              filterOption={(input, option) =>
                ((option?.label as string) ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
              onSelect={async (value, option: any) => {
                setWarehouseId(value === "all" ? undefined : value);
                setPagination({ page: 1, pageSize: pagination.pageSize });
                await getSales({
                  warehouseIdList: value === "all" ? undefined : value,
                  pageSize: pagination.pageSize,
                });
              }}
            />
          </InputContainer>

          <Table
            ref={gridRef}
            onGridReady={() => setIsReady(true)}
            columnDefs={columnDefs}
            rowData={saleList}
            totalPage={count}
            page={pagination.page}
            pageSize={pagination.pageSize}
            handlePaginationClick={async (page, pageSize) => {
              setPagination({ page, pageSize });
              await getSales({
                page,
                pageSize,
                warehouseIdList: warehouseId,
              });
            }}
            onRowClicked={(params) => {
              setSelectedId(params.data.id);
            }}
          />
        </Content>
      </Dialog>
      {renderAlertDialog()}
    </>
  );
};

export default LoadSalesContractDialog;

const Content = styled.div`
  min-height: 400px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .ag-theme-alpine {
    border: 1px solid ${colorSet.gray9} !important;
    border-radius: 8px;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const WhiteSpace = styled.p`
  white-space: pre-wrap;
`;
