import colorSet from "@/src/styles/color";
import { PropsWithChildren } from "react";
import styled from "styled-components";
import LogoSvg from "@/src/assets/logos/logo-export.svg";
import LogoDevSvg from "@/src/assets/logos/logo-export-dev.svg";
import Icon from "../../atom/Icon";
import Typo from "../../atom/Typo";
import { useNavigate } from "react-router-dom";
import PUBLIC_PATH from "@/src/routes/public/path";
import EmailFooter from "@/src/pages/private/SharedEmail/components/EmailFooter";
import { useTranslation } from "react-i18next";
import { LANGUAGE_LIST } from "@/src/constant/optionList";
import DropDown from "@/src/components/atom/DropDown";
import { Button } from "../../atom/Button";
import Flex from "../../molecule/Flex";
import GlobalLanguageSvg from "@/src/assets/icons/icon-global-language.svg";
import {
  DropdownMenuGroup,
  DropdownMenuItem,
} from "@radix-ui/react-dropdown-menu";
import SectionCardWithoutHeader from "../../molecule/SectionCardWithoutHeader";
import { isDevelopment } from "@/src/utils/environment";

function SharedEmailLayout({ children }: PropsWithChildren) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const renderLanguageListDropdown = () => {
    const menu = LANGUAGE_LIST.map((item) => {
      return {
        ...item,
        onSelect: async () => {
          i18n.changeLanguage(item.value);
        },
      };
    });

    return (
      <>
        {menu.map(({ label, onSelect }) => (
          <AccountDropDownItem key={label} onSelect={onSelect}>
            <Typo typoType="b9r">{t(label)}</Typo>
          </AccountDropDownItem>
        ))}
      </>
    );
  };

  return (
    <SharedEmailLayoutContainer>
      {/* 언어변경 컨트롤러 */}
      <UserControllerContainer>
        <DropDown
          dropdownContentProps={{
            align: "end",
            sideOffset: 10,
          }}
          dropdownTrigger={
            <StyledButton buttonColor="black" buttonGrade="ghost">
              <Flex alignItems="center" gap={4}>
                <Icon iconSrc={GlobalLanguageSvg} iconSize={18} />
                <Typo typoType="b9r">
                  {t(
                    LANGUAGE_LIST.find((item) => item.value === i18n.language)
                      ?.label || ""
                  )}
                </Typo>
              </Flex>
            </StyledButton>
          }
        >
          <StyledSectionCardWithoutHeader>
            <AccountDropDownList>
              {renderLanguageListDropdown()}
            </AccountDropDownList>
          </StyledSectionCardWithoutHeader>
        </DropDown>
      </UserControllerContainer>

      <Main>
        <Logo
          src={isDevelopment ? LogoDevSvg : LogoSvg}
          isDevelopment={isDevelopment}
          alt="logo"
          onClick={() => navigate(PUBLIC_PATH.LOGIN)}
        />
        {children}
        <EmailFooter />
      </Main>
    </SharedEmailLayoutContainer>
  );
}

export default SharedEmailLayout;

const SharedEmailLayoutContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const UserControllerContainer = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  padding: 10px 40px;
  background: ${colorSet.white};
  border-bottom: 1px solid ${colorSet.gray10};
`;

const Main = styled.main`
  display: flex;
  flex: 1;
  flex-direction: column;
  border: 1px solid ${colorSet.gray9};
  padding: 40px;
  width: 1250px;
  min-height: 1000px;
  margin: 80px auto 40px;
`;

const Logo = styled.img<{ isDevelopment: boolean }>`
  width: ${({ isDevelopment }) => (isDevelopment ? "246px" : "208px")};
  height: 32px;
  cursor: pointer;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 4px 8px 4px 12px;

  &:focus-visible {
    outline: none;
  }
`;

const AccountDropDownList = styled(DropdownMenuGroup)`
  display: flex;
  flex-direction: column;
`;

const AccountDropDownItem = styled(DropdownMenuItem)`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  cursor: pointer;
  outline: none;

  &:focus,
  &:hover {
    background: ${colorSet.gray10};
  }
`;

const StyledSectionCardWithoutHeader = styled(SectionCardWithoutHeader)`
  background: ${colorSet.white};
  width: 180px;
`;
