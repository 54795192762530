import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { css, styled } from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import ImporterMainLayout from "@/src/components/template/Layout/importer/ImporterMainLayout";
import { Button } from "@/src/components/atom/Button";
import Icon from "@/src/components/atom/Icon";
import BookMarkStatus from "@/src/components/molecule/BookMarkStatus";
import useAlert from "@/src/hooks/useAlert";
import BottomFixedContainer from "@/src/components/molecule/BottomFixedContainer";
import Typo from "@/src/components/atom/Typo";
import CancelContractAlertDialog from "./components/detail/dialog/CancelContractAlertDialog";
import RedoAlertDialog from "./components/detail/dialog/RedoAlertDialog";
import EmailDialog from "./components/detail/dialog/EmailDialog";
import {
  useConfirmImporterPoMutation,
  useConfirmImporterScMutation,
  useGetImporterContractDetailBookmarkQuery,
  useGetImporterContractDetailBookmarkUserQuery,
  useGetImporterContractShipmentSummaryInfoQuery,
  useLazyGetImporterContractDetailQuery,
  useLazyGetImporterContractSignatureHistoriesQuery,
  useLazyGetImporterPoHistoriesQuery,
  useLazyGetImporterScHistoriesQuery,
  useRejectImporterScMutation,
  useUpdateContractDetailStatusMutation,
  useUpdateImporterContractDetailBookmarkMutation,
} from "@/src/store/apis/contracts/contractDetail";
import renderContractStatusBadge from "./utils/renderContractStatusBadge";
import BookmarkDialog from "@/src/components/molecule/BookmarkDialog";
import colorSet, { ColorType } from "@/src/styles/color";
import { ReactComponent as ArrowHookSvg } from "@/src/assets/icons/icon-arrow-hook-red.svg";
import ChevronLeft from "@/src/assets/icons/icon-chevron-left-black.svg";
import DotGray7Svg from "@/src/assets/icons/icon-dot-gray7.svg";
import DotGray4Svg from "@/src/assets/icons/icon-dot-gray4.svg";
import PenSvg from "@/src/assets/icons/icon-pen-white.svg";
import { useGetExportersQuery } from "@/src/store/apis/corporate/company";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import { ContractBookmarkListViewDto } from "@/src/store/apis/contracts/contractDetail/interface";
import { isUndefined } from "@/src/utils/is";
import EmailHistoryDialog from "@/src/components/organism/EmailHistoryDialog";
import { useLazyGetEmailShareHistoriesQuery } from "@/src/store/apis/emailShareHistories";
import useContentLoading from "@/src/hooks/useContentLoading";
import UnauthorizedDescription from "@/src/components/molecule/UnauthorizedDescription";
import { useGetSessionQuery } from "@/src/store/apis/auth";
import { useGetCommonCodeViaCodeNameQuery } from "@/src/store/apis/common";
import { PartialCommonCodeItemDto } from "@/src/store/apis/common/interface";
import typo from "@/src/styles/typography";
import ContractDocumentTab from "./components/detail/ContractDocumentTab";
import ProcessingTab from "./components/detail/ProcessingTab";
import ContractFilesTab from "./components/detail/ContractFilesTab";
import { ContractStatusType } from "@/src/store/apis/contracts/interface";
import ContractInformationTab from "./components/detail/ContractInformationTab";
import { useGetBuyersQuery } from "@/src/store/apis/client/buyer";
import AlertDialog from "@/src/components/atom/AlertDialog";
import RejectReasonDialog from "./components/detail/dialog/RejectReasonDialog";
import RegisterSignDialog from "./components/detail/dialog/RegisterSignDialog";
import StoreTab from "./components/detail/StoreTab";
import { useTranslation } from "react-i18next";
import TabItem from "@/src/components/molecule/TabItem";
import NoRegisteredSignatureAlertDialog from "@/src/components/organism/NoRegisteredSignatureAlertDialog";
import SignatureRequestAlertDialog from "@/src/components/organism/SignatureRequestAlertDialog";

enum DialogState {
  NULL,
  SEND_EMAIL,
  SENT_HISTORY,
  BOOK_MARK,
  REJECT_SC_REASON,
  REGISTER_SIGN,
}

enum AlertDialogState {
  NULL,
  CANCEL,
  REDO,
  SIGN_SC,
  REJECT_SC,
  CONFIRM_REQUEST_PO,
  REGISTER_SIGN,
  SIGNATURE_REQUEST,
}

type TabType =
  | "contractInformation"
  | "contractDocument"
  | "processing"
  | "store"
  | "files";

const TAB_LIST: {
  id: TabType;
  label: string;
}[] = [
  {
    id: "contractInformation",
    label: "common:importerContractInformation",
  },
  {
    id: "contractDocument",
    label: "common:importerContractDocument",
  },
  {
    id: "processing",
    label: "common:processing",
  },
  {
    id: "store",
    label: "common:receipt",
  },
  {
    id: "files",
    label: "common:files",
  },
];

const bookmarkEmptyArray: ContractBookmarkListViewDto[] = [];
const mainCategoryEmptyArray: PartialCommonCodeItemDto[] = [];

const ImporterContractDetailPage = () => {
  const { t } = useTranslation();
  const alert = useAlert();
  const params = useParams();
  const navigate = useNavigate();
  const [tab, setTab] = useState<TabType>("contractInformation");
  const [poVersion, setPoVersion] = useState<number>();
  const [scVersion, setScVersion] = useState<number>();
  const [processingTab, setProcessingTab] = useState<
    ("booking" | "loading" | "shipment")[]
  >(["booking"]);
  const [selectedProcessingTab, setSelectedProcessingTab] = useState<
    "booking" | "loading" | "shipment"
  >();
  const [selectedProcessingItem, setSelectedProcessingItem] =
    useState<number>();
  const loadingTimeoutId = useRef<NodeJS.Timeout | null>(null);
  const { handleContentLoadingOff, handleContentLoadingOn } =
    useContentLoading();

  // API
  const { mainCategoryList } = useGetCommonCodeViaCodeNameQuery(
    {
      codeName: "MAIN_CATEGORY",
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          mainCategoryList: isStable
            ? currentData ?? mainCategoryEmptyArray
            : mainCategoryEmptyArray,
        };
      },
    }
  );

  const { currentData: buyers } = useGetBuyersQuery({ isActivated: true });
  const { userId, exporterUserType, userMainCategory, isUserFetching } =
    useGetSessionQuery(undefined, {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          isUserFetching: isFetching,
          userId: currentData?.row.id,
          userMainCategory: isStable
            ? currentData.row.mainCategoryCodeItemNames
            : [],
          exporterUserType: currentData?.row.exporterUserType,
        };
      },
    });

  const { hasSign } = useGetExportersQuery(undefined, {
    refetchOnMountOrArgChange: true,
    selectFromResult: ({ currentData, isError }) => {
      const isUnstable = isError || isUndefined(currentData);
      const isStable = !isUnstable;
      return {
        hasSign: isStable
          ? currentData.signatureManagement.simpleExporterSignatureMedias
              ?.length > 0
          : false,
      };
    },
  });

  const { balanceOfContractV2, totalShippingWeightV2, percentOfShipment } =
    useGetImporterContractShipmentSummaryInfoQuery(
      { id: Number(params.id) },
      {
        refetchOnMountOrArgChange: true,
        selectFromResult: ({ currentData, isError }) => {
          const isErrorAndUndefined = isError || currentData === undefined;

          return {
            balanceOfContractV2: !isErrorAndUndefined
              ? currentData?.balanceOfContractV2 ?? "-"
              : "-",
            totalShippingWeightV2: !isErrorAndUndefined
              ? currentData?.totalShippingWeightV2 ?? "-"
              : "-",
            percentOfShipment:
              !isErrorAndUndefined && currentData.percentOfShipment
                ? Number(currentData?.percentOfShipment)
                : 0,
          };
        },
      }
    );
  const [
    getContractDetail,
    {
      clientName,
      isContractError,
      contractWriterId,
      contractStatus,
      contractUpdatedAt,
      contractCreatedAt,
      contractLastEditorName,
      contractWriterName,
      poNo,
      scNo,
      isContractDetailFetching,
      isContractUnstable,
      isContractStable,
      contractMainCategory,
      contractMainCategoryForImporter,
      signatureStatus,
      sellerId,
    },
  ] = useLazyGetImporterContractDetailQuery({
    selectFromResult: ({ currentData, isError, isFetching }) => {
      const isUnstable = isUndefined(currentData) || isError || isFetching;
      const isStable = !isUnstable;

      return {
        sellerId: isStable ? currentData.importerBuyerId : undefined,
        clientName: isStable ? currentData.buyerName : "",
        isContractUnstable: isUnstable,
        isContractStable: isStable,
        isContractError: isError,
        isContractDetailFetching: isFetching,
        contractWriterId: isStable ? currentData.contractWriterId : null,
        contractStatus: isStable ? currentData.contractStatus : "NOT_LINKED",
        contractUpdatedAt:
          isStable && currentData.updatedAt
            ? `(${dayjs(currentData.updatedAt).format(
                DATE_FORMAT_STRINGS.YY_MM_DD
              )},${dayjs(currentData.updatedAt).format(
                DATE_FORMAT_STRINGS.HH_mm
              )})`
            : "-",
        contractCreatedAt:
          isStable && currentData.createdAt
            ? `(${dayjs(currentData.createdAt).format(
                DATE_FORMAT_STRINGS.YY_MM_DD
              )},${dayjs(currentData.createdAt).format(
                DATE_FORMAT_STRINGS.HH_mm
              )})`
            : "-",
        contractLastEditorName: isStable
          ? currentData.contractLastEditorName ?? "-"
          : "-",
        contractWriterName: isStable
          ? currentData.contractWriterName ?? "-"
          : "-",
        poNo: isStable ? currentData.poNo ?? "-" : "-",
        scNo: isStable ? currentData.scNo ?? "-" : "-",
        scSimpleMedia: isStable ? currentData?.scSimpleMedia : null,
        contractMainCategory: isStable ? currentData.mainCategory ?? "" : "",
        contractMainCategoryForImporter: isStable
          ? currentData.mainCategoryForImporter ?? ""
          : "",
        signatureStatus: isStable
          ? currentData.signatureStatus || "DRAFT"
          : "DRAFT",
      };
    },
  });
  const { isBookmarked } = useGetImporterContractDetailBookmarkQuery(
    { id: Number(params.id) },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData }) => {
        return { isBookmarked: currentData?.isBookmarked };
      },
    }
  );
  const { bookmarkUserList } = useGetImporterContractDetailBookmarkUserQuery(
    { id: Number(params.id) },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isUndefined(currentData) || isError || isFetching;

        return {
          bookmarkUserList: !isUnstable
            ? currentData.rows ?? bookmarkEmptyArray
            : bookmarkEmptyArray,
        };
      },
    }
  );
  const [editContractBookmark] =
    useUpdateImporterContractDetailBookmarkMutation();
  const [contractStatusChange] = useUpdateContractDetailStatusMutation();
  const [
    getContractSharedHistories,
    {
      count,
      emailHistoryList,
      isEmailShareHistoriesFetching,
      isEmailShareHistoriesError,
    },
  ] = useLazyGetEmailShareHistoriesQuery({
    selectFromResult: ({ currentData, isFetching, isError }) => {
      const isUnstable = isUndefined(currentData) || isError || isFetching;

      return {
        count: !isUnstable ? currentData.count : 0,
        emailHistoryList: !isUnstable ? currentData.rows : undefined,
        isEmailShareHistoriesError: isError,
        isEmailShareHistoriesFetching: isFetching,
      };
    },
  });
  const [
    getContractSignatureHistories,
    {
      isOnlyReceivedSc,
      isPending,
      isExporterScSigned,
      isImporterScSigned,
      isImporterPoSigned,
      hasLinkedBefore,
    },
  ] = useLazyGetImporterContractSignatureHistoriesQuery({
    selectFromResult: ({ currentData }) => ({
      isOnlyReceivedSc:
        !!currentData?.rows[0].isExporterSignedSC &&
        !currentData?.rows[0].isImporterSignedSC,
      isPending: currentData?.rows[0].signatureStatus === "PENDING",
      isExporterScSigned: !!currentData?.rows[0].isExporterSignedSC,
      isImporterScSigned: !!currentData?.rows[0].isImporterSignedSC,
      isExporterPoSigned: !!currentData?.rows[0].isExporterSignedPO,
      isImporterPoSigned: !!currentData?.rows[0].isImporterSignedPO,
      hasLinkedBefore: currentData?.rows.some(
        ({
          isExporterSignedSC,
          isImporterSignedSC,
          isExporterSignedPO,
          isImporterSignedPO,
        }) =>
          isExporterSignedSC &&
          isImporterSignedSC &&
          isExporterSignedPO &&
          isImporterSignedPO
      ),
    }),
  });
  const [getImporterPoHistories, { isPoHistoryError }] =
    useLazyGetImporterPoHistoriesQuery({
      selectFromResult: ({ isError }) => {
        return {
          isPoHistoryError: isError,
        };
      },
    });
  const [getImporterScHistories] = useLazyGetImporterScHistoriesQuery({
    selectFromResult: ({ currentData, isError, isFetching }) => {
      return {
        isScHistoryError: isError,
        isScHitoryFetching: isFetching,
        scHistory: currentData,
      };
    },
  });

  const [confirmImporterPo, { isLoading: isConfirmingPo }] =
    useConfirmImporterPoMutation();
  const [confirmImporterSc, { isLoading: isConfirmingSc }] =
    useConfirmImporterScMutation();
  const [rejectSc, { isLoading: isRejectingSc }] =
    useRejectImporterScMutation();

  // State
  const [documentTab, setDocumentTab] = useState<"po" | "sc">("po");
  const [filesTab, setFilesTab] = useState<"list" | "gallery">("list");
  const [dialogState, setDialogState] = useState<DialogState>(DialogState.NULL);
  const [alertDialogState, setAlertDialogState] = useState<AlertDialogState>(
    AlertDialogState.NULL
  );

  const mainCategoryItemName = mainCategoryList.find(
    (item) =>
      item.codeItemName ===
        contractMainCategory.toUpperCase().replace(/-/g, "_") ||
      item.codeItemName ===
        contractMainCategoryForImporter.toUpperCase().replace(/-/g, "_")
  )?.codeItemName;

  // 매니저,중간관리자 카테고리 비교
  const isMainCategoryAuthorized =
    exporterUserType === "MANAGER" || exporterUserType === "MIDDLE_MANAGER"
      ? userMainCategory.includes(mainCategoryItemName as string)
      : true;

  const isMainCategoryUnauthorized = !isMainCategoryAuthorized;

  /**
   * 수정 권한
   * - 기업관리자, 중간관리자, 작성자
   * - 일반 매내저는 메인카테고리가 포함된 사람
   */
  const isEditAuth =
    !isContractError &&
    (userId === contractWriterId || isMainCategoryAuthorized);

  const handleBookmarkClick = async () => {
    const bookmark = !isBookmarked;

    try {
      const bookmarkParams: { id: number; isBookmarked: boolean } = {
        id: Number(params.id),
        isBookmarked: bookmark,
      };

      await editContractBookmark(bookmarkParams).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const handleContractStatusChangeClick = async (
    type: "COMPLETE" | "PROCESSING"
  ) => {
    try {
      const contractParams = {
        contractStatus: type,
        contractId: Number(params.id),
      };

      await contractStatusChange(contractParams).unwrap();

      if (type === "COMPLETE") {
        alert.showAlert({
          type: "success",
          message: t("contract:importer.detail.alert.completeAlert"),
        });
      }

      if (type === "PROCESSING") {
        alert.showAlert({
          type: "success",
          message: t("contract:importer.detail.alert.redoAlert"),
        });
      }
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const handleDocument = async (
    type: "confirmPo" | "confirmSc" | "rejectSc",
    rejectReason?: string
  ) => {
    try {
      handleContentLoadingOn();
      const id = Number(params.id);
      switch (type) {
        case "confirmPo":
          await confirmImporterPo({ id }).unwrap();
          alert.showAlert({
            type: "success",
            message: t(
              "contract:importer.detail.alert.confirmationRequestCompletedAlert"
            ),
          });
          break;
        case "confirmSc":
          await confirmImporterSc({ id }).unwrap();
          alert.showAlert({
            type: "success",
            message: t(
              "contract:importer.detail.alert.signatureCompletedAlert"
            ),
          });
          break;
        case "rejectSc":
          await rejectSc({ id, rejectReason }).unwrap();
          alert.showAlert({
            type: "success",
            message: t("contract:importer.detail.alert.rejectCompletedAlert"),
          });
          break;
      }
      await getContractDetail({
        id: Number(params.id),
      });
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    } finally {
      setDialogState(DialogState.NULL);
      setAlertDialogState(AlertDialogState.NULL);
      handleContentLoadingOff();
    }
  };

  const renderDialogs = () => {
    if (dialogState === DialogState.BOOK_MARK) {
      return (
        <BookmarkDialog
          open
          onOpenChange={() => setDialogState(DialogState.NULL)}
          bookmarkUserList={bookmarkUserList}
        />
      );
    }
    if (dialogState === DialogState.SEND_EMAIL) {
      return (
        <EmailDialog
          open
          onOpenChange={() => setDialogState(DialogState.NULL)}
        />
      );
    }
    if (dialogState === DialogState.SENT_HISTORY) {
      return (
        <EmailHistoryDialog
          open
          onOpenChange={() => setDialogState(DialogState.NULL)}
          isFetching={isEmailShareHistoriesFetching}
          isError={isEmailShareHistoriesError}
          emailHistoryList={emailHistoryList}
          totalCount={count}
          fetch={getContractSharedHistories}
          emailShareDomain={"CONTRACT"}
          emailShareDomainId={Number(params.id)}
        />
      );
    }
    if (dialogState === DialogState.REJECT_SC_REASON) {
      return (
        <RejectReasonDialog
          onClose={() => setDialogState(DialogState.NULL)}
          onSubmit={(rejectReason) => {
            handleDocument("rejectSc", rejectReason);
          }}
        />
      );
    }
    if (dialogState === DialogState.REGISTER_SIGN) {
      return (
        <RegisterSignDialog
          id={Number(params.id)}
          onClose={() => setDialogState(DialogState.NULL)}
        />
      );
    }
  };

  const renderAlertDialogs = () => {
    const closeAlertDialog = () => setAlertDialogState(AlertDialogState.NULL);
    // 발주완료
    if (alertDialogState === AlertDialogState.CANCEL) {
      return (
        <CancelContractAlertDialog
          open
          onOpenChange={() => setAlertDialogState(AlertDialogState.NULL)}
          onOk={() => {
            setAlertDialogState(AlertDialogState.NULL);
            handleContractStatusChangeClick("COMPLETE");
          }}
        />
      );
    }
    // 발주 되돌리기
    if (alertDialogState === AlertDialogState.REDO) {
      return (
        <RedoAlertDialog
          open
          onOpenChange={() => setAlertDialogState(AlertDialogState.NULL)}
          onOk={() => {
            setAlertDialogState(AlertDialogState.NULL);
            handleContractStatusChangeClick("PROCESSING");
          }}
        />
      );
    }
    // SC컴펌
    if (alertDialogState === AlertDialogState.SIGN_SC) {
      return (
        <AlertDialog
          open
          onOpenChange={() => closeAlertDialog()}
          title={t("contract:importer.detail.alertDialog.signContractTitle")}
          description={t(
            "contract:importer.detail.alertDialog.signContractDescription"
          )}
          onOk={() => handleDocument("confirmSc")}
          onCancel={closeAlertDialog}
          cancelText={t("contract:importer.detail.button.cancelButton")}
          okText={t("contract:importer.detail.button.okButton")}
        />
      );
    }
    // SC반려
    if (alertDialogState === AlertDialogState.REJECT_SC) {
      return (
        <AlertDialog
          open
          onOpenChange={() => closeAlertDialog()}
          title={t("contract:importer.detail.alertDialog.rejectContractTitle")}
          description={t(
            "contract:importer.detail.alertDialog.rejectContractDescription"
          )}
          onOk={() => handleDocument("rejectSc")}
          onCancel={closeAlertDialog}
          cancelText={t("contract:importer.detail.button.cancelButton")}
          okText={t("contract:importer.detail.button.okButton")}
        />
      );
    }
    // PO컨펌
    if (alertDialogState === AlertDialogState.CONFIRM_REQUEST_PO) {
      return (
        <AlertDialog
          open
          onOpenChange={() => closeAlertDialog()}
          title={t(
            "contract:importer.detail.alertDialog.requestConfirmationTitle"
          )}
          description={t(
            "contract:importer.detail.alertDialog.requestConfirmationDescription"
          )}
          onOk={() => handleDocument("confirmPo")}
          onCancel={closeAlertDialog}
          cancelText={t("contract:importer.detail.button.cancelButton")}
          okText={t("contract:importer.detail.button.okButton")}
        />
      );
    }
    // 서명등록
    if (alertDialogState === AlertDialogState.REGISTER_SIGN) {
      return (
        <NoRegisteredSignatureAlertDialog
          open
          onOpenChange={() => closeAlertDialog()}
          onOk={() => {
            setDialogState(DialogState.REGISTER_SIGN);
            closeAlertDialog();
          }}
          onCancel={closeAlertDialog}
        />
      );
    }
    // 서명등록 요청
    if (alertDialogState === AlertDialogState.SIGNATURE_REQUEST) {
      return (
        <SignatureRequestAlertDialog
          open
          onOpenChange={() => closeAlertDialog()}
          onCancel={closeAlertDialog}
        />
      );
    }
  };

  const renderUnauthorizedDescription = () => {
    if (isContractDetailFetching || isUserFetching) {
      return;
    }

    if (isContractUnstable || isMainCategoryUnauthorized) {
      return (
        <NoDataContainer>
          <UnauthorizedDescription />
        </NoDataContainer>
      );
    }
  };

  const renderFooter = () => {
    switch (tab) {
      case "contractInformation":
        return null;
      case "contractDocument":
        if (documentTab === "sc") {
          return (
            <div>
              <StyledButton
                buttonColor="red"
                buttonGrade="secondary"
                onClick={() => setDialogState(DialogState.REJECT_SC_REASON)}
                disabled={
                  !isEditAuth ||
                  !isExporterScSigned ||
                  isImporterScSigned ||
                  isConfirmingSc ||
                  isRejectingSc ||
                  isPending
                }
                isLoading={isConfirmingSc || isRejectingSc}
              >
                <ArrowHookIcon
                  disabled={
                    !isEditAuth ||
                    !isExporterScSigned ||
                    isImporterScSigned ||
                    isConfirmingSc ||
                    isRejectingSc ||
                    isPending
                  }
                />
                {t("contract:importer.detail.button.rejectContractButton")}
              </StyledButton>

              <StyledButton
                onClick={() => {
                  if (!hasSign) {
                    /**
                     * - 기업관리자, 중간관리자 서명등록
                     * - 일반 매니저 기업관리자에게 서명요청
                     */
                    if (exporterUserType !== "MANAGER") {
                      setAlertDialogState(AlertDialogState.REGISTER_SIGN);
                    } else {
                      setAlertDialogState(AlertDialogState.SIGNATURE_REQUEST);
                    }
                  } else {
                    setAlertDialogState(AlertDialogState.SIGN_SC);
                  }
                }}
                disabled={
                  !isEditAuth ||
                  !isExporterScSigned ||
                  isImporterScSigned ||
                  isConfirmingSc ||
                  isRejectingSc ||
                  isPending
                }
                isLoading={isConfirmingSc || isRejectingSc}
              >
                <Icon iconSrc={PenSvg} iconSize={16} />
                {t("contract:importer.detail.button.signContractButton")}
              </StyledButton>
            </div>
          );
        }
        return (
          <StyledButton
            onClick={() =>
              setAlertDialogState(AlertDialogState.CONFIRM_REQUEST_PO)
            }
            isLoading={isConfirmingPo}
            disabled={
              (contractStatus === "NOT_LINKED" &&
                buyers?.data.find(
                  ({ buyerListQueryResultDto }) =>
                    sellerId === buyerListQueryResultDto.id
                )?.buyerListQueryResultDto.linkedStatus !== "COMPLETED") ||
              isImporterPoSigned ||
              isConfirmingPo ||
              signatureStatus === "REQUESTED" ||
              signatureStatus === "SENT"
            }
          >
            {t("contract:importer.detail.button.requestForConfirmationButton")}
          </StyledButton>
        );
    }
  };

  const renderContentByTab = () => {
    switch (tab) {
      case "contractInformation":
        return (
          <ContractInformationTab
            isEditAuth={isEditAuth}
            balanceOfContractV2={balanceOfContractV2}
            percentOfShipment={percentOfShipment}
            totalShippingWeightV2={totalShippingWeightV2}
            contractStatus={contractStatus}
            emailDialogOn={() => setDialogState(DialogState.SEND_EMAIL)}
            historyDialogOn={() => setDialogState(DialogState.SENT_HISTORY)}
            isZeroPo={isPoHistoryError}
            isOnlyReceivedSc={isOnlyReceivedSc}
            isDisabledEdit={
              isImporterPoSigned &&
              contractStatus !== "NOT_LINKED" &&
              signatureStatus !== "LINKED"
            }
          />
        );
      case "contractDocument":
        return (
          <ContractDocumentTab
            tab={documentTab}
            onTabChange={(tab) => setDocumentTab(tab)}
            poVersion={poVersion}
            scVersion={scVersion}
            poNo={poNo}
            scNo={scNo}
            contractStatus={contractStatus}
            signatureStatus={signatureStatus}
            onVersionChange={({ type, version }) => {
              if (type === "po") {
                setPoVersion(version);
              }
              if (type === "sc") {
                setScVersion(version);
              }
            }}
          />
        );
      case "processing":
        return (
          <ProcessingTab
            tab={processingTab}
            onTabChange={(tab) => {
              if (processingTab.includes(tab)) {
                setProcessingTab((prev) => prev.filter((item) => item !== tab));
              } else {
                setProcessingTab((prev) => [...prev, tab]);
              }
            }}
            hasLinkedBefore={!!hasLinkedBefore}
            signatureStatus={signatureStatus}
            selectedProcessingTab={selectedProcessingTab}
            selectedProcessingItem={selectedProcessingItem}
            onSelectedItemChange={(tab, item) => {
              setSelectedProcessingTab(tab);
              setSelectedProcessingItem(item);
            }}
          />
        );
      case "store":
        return <StoreTab />;
      case "files":
        return <ContractFilesTab tab={filesTab} onTabChange={setFilesTab} />;
    }
  };

  const renderContractDetailContent = () => {
    if (isContractUnstable || isMainCategoryUnauthorized) {
      return;
    }

    return (
      <>
        {/* Content */}
        <FlexColumn>
          {/* Header */}
          <HeaderContainer>
            <HeaderButtonContainer>
              {renderContractStatusBadge({
                t,
                type: contractStatus as ContractStatusType | null,
                size: "L",
              })}
            </HeaderButtonContainer>
            <HeaderButtonContainer>
              {/* BookMark */}
              <BookMarkStatus
                bookMarkStatus={isBookmarked}
                bookMarkCount={bookmarkUserList.length}
                onClickBookMark={handleBookmarkClick}
                onClickCount={() => setDialogState(DialogState.BOOK_MARK)}
              />
            </HeaderButtonContainer>
          </HeaderContainer>

          <Tabs role="tablist">
            {TAB_LIST.map(({ id, label }) => {
              return (
                <StyledTabItem
                  key={id}
                  tabIndex={id === tab ? 0 : -1}
                  isSelect={id === tab}
                  tabValue={id}
                  onClick={() => setTab(id)}
                  onFocusItem={(value) => setTab(value as TabType)}
                >
                  <Typo typoType="b5m" color={id === tab ? "white" : "gray6"}>
                    {t(label)}
                  </Typo>
                </StyledTabItem>
              );
            })}
          </Tabs>

          {renderContentByTab()}

          {/* Last Editor */}
          <EditorContainer>
            <figure>
              <Icon iconSrc={DotGray7Svg} iconSize={4} />
              <Typo color="gray6" typoType="b9r">
                {`${t("contract:importer.detail.editor.registratant")} (${t(
                  "contract:importer.detail.editor.registratantAt"
                )}) ${contractWriterName}`}
              </Typo>
              <Typo color="gray7" typoType="b9r">
                {contractCreatedAt}
              </Typo>
            </figure>
            <figure>
              <Icon iconSrc={DotGray4Svg} iconSize={4} />
              <Typo color="gray4" typoType="b9r">
                {`${t("contract:importer.detail.editor.lastEditor")} (${t(
                  "contract:importer.detail.editor.lastEditorAt"
                )}) ${contractLastEditorName}`}
              </Typo>
              <Typo color="gray7" typoType="b9r">
                {contractUpdatedAt}
              </Typo>
            </figure>
          </EditorContainer>
        </FlexColumn>

        {/* Bottom Fixed */}
        <BottomFixedContainer>
          <BottomButtonContainer>
            <StyledButton
              buttonColor="black"
              buttonGrade="tertiary"
              onClick={() => navigate(-1)}
            >
              <Icon iconSrc={ChevronLeft} iconSize={16} />
              {t("contract:importer.detail.bottomBottom.backToPreviousButton")}
            </StyledButton>

            {renderFooter()}
          </BottomButtonContainer>
        </BottomFixedContainer>
      </>
    );
  };

  useEffect(() => {
    (async () => {
      try {
        const { contractStatus } = await getContractDetail({
          id: Number(params.id),
        }).unwrap();
        if (contractStatus === "NOT_LINKED") {
          setDocumentTab("sc");
        }
      } catch (e: any) {
        const message = Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message;

        if (e.data.statusCode !== 404) {
          alert.showAlert({ type: "error", message });
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getContractDetail, params.id]);

  useEffect(() => {
    if (isContractDetailFetching) {
      const setTimeoutId = setTimeout(() => {
        handleContentLoadingOn();
      }, 300);

      loadingTimeoutId.current = setTimeoutId;
    } else {
      if (loadingTimeoutId.current) {
        clearTimeout(loadingTimeoutId.current);
      }
      handleContentLoadingOff();
    }

    return () => handleContentLoadingOff();
  }, [
    isContractDetailFetching,
    handleContentLoadingOff,
    handleContentLoadingOn,
  ]);

  useEffect(() => {
    (async () => {
      const poResult = await getImporterPoHistories({
        contractId: Number(params.id),
      });
      const scResult = await getImporterScHistories({
        contractId: Number(params.id),
      });

      await getContractSignatureHistories({ id: Number(params.id) });

      if (poResult.isError) {
        setDocumentTab("sc");
      }

      if (scResult.isError) {
        setDocumentTab("po");
      }

      if (!poVersion) {
        setPoVersion?.(poResult.data?.rows?.[0].id);
      }
      if (!scVersion) {
        setScVersion?.(scResult.data?.rows?.[0].id);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ImporterMainLayout
      breadcrumb={
        isContractStable && isMainCategoryAuthorized
          ? [t("sideNav:importContract"), t("sideNav:importContractDetail")]
          : []
      }
      pageTitle={
        <>
          {isContractStable &&
            isMainCategoryAuthorized &&
            `${t("contract:importer.detail.header.title")} ${poNo ?? "-"}`}

          {isMainCategoryAuthorized && <ClientName>{clientName}</ClientName>}
        </>
      }
    >
      {/* Contents */}
      {renderContractDetailContent()}
      {renderUnauthorizedDescription()}

      {/* Dialogs , AlertDialogs*/}
      {renderDialogs()}
      {renderAlertDialogs()}
    </ImporterMainLayout>
  );
};

export default ImporterContractDetailPage;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderButtonContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 72px;
`;

const BottomButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    gap: 8px;
  }
`;

const EditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  figure {
    display: flex;
    align-items: center;
    gap: 4px;
  }
`;

const ArrowHookIcon = styled(ArrowHookSvg)<{
  color?: ColorType;
  disabled?: boolean;
}>`
  width: 16px;
  height: 16px;

  path {
    ${({ color }) =>
      color &&
      css`
        fill: ${colorSet[color]};
      `}

    ${({ disabled }) =>
      disabled &&
      css`
        fill: ${colorSet.red6};
      `}
  }
`;

const NoDataContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ClientName = styled(Typo).attrs({
  as: "h5",
})`
  padding-top: 8px;
  color: ${colorSet.gray1};
  ${typo.b5m}
`;

const Tabs = styled.div`
  display: flex;
  border-radius: 8px;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
`;

const StyledTabItem = styled(TabItem)<{
  isSelect?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 8px 16px;
  border: none;
  border-right: 1px solid ${colorSet.gray9};
  cursor: pointer;
  background: ${({ isSelect }) =>
    isSelect ? colorSet.indigo : colorSet.gray11};
  border: 1px solid ${colorSet.gray9};

  &:last-child {
    border-right: none;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
`;
