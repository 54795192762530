import React, { useEffect, useRef, useState } from "react";
import Dialog from "@/src/components/atom/Dialog";
import DialogFooterContainer from "@/src/components/atom/Dialog/DialogFooterContainer";
import { Button } from "@/src/components/atom/Button";
import Typo from "@/src/components/atom/Typo";
import CancelAlertDialog from "@/src/components/molecule/CancelAlertDialog";
import styled from "styled-components";
import { ReactComponent as InfoSvg } from "@/src/assets/icons/icon-info-gray6.svg";
import { ReactComponent as DeleteSvg } from "@/src/assets/icons/icon-cancle-red.svg";
import colorSet, { ColorType } from "@/src/styles/color";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import {
  useGetCommonCodeViaCodeNameQuery,
  useLazyGetCommonCodeViaCodeNameQuery,
} from "@/src/store/apis/common";
import FormItem, { InputError } from "@/src/components/molecule/FormItem";
import Loader from "@/src/components/atom/Loader";
import { DIRECT_INPUT_LABEL, DIRECT_INPUT_VALUE } from "@/src/constant/index";
import Input from "@/src/components/atom/Input";
import AddSvg from "@/src/assets/icons/icon-add-black.svg";
import AddBlueSvg from "@/src/assets/icons/icon-add-blue.svg";
import Icon from "@/src/components/atom/Icon";
import TextArea from "@/src/components/atom/TextArea";
import { PartialCommonCodeItemDto } from "@/src/store/apis/common/interface";
import {
  useLazyGetItemCodeCheckQuery,
  useLazyGetRegistrableItemsQuery,
} from "@/src/store/apis/corporate/companyItemManagement";
import { ITEM_AVAILABLE_MESSAGE } from "@/src/store/apis/corporate/companyItemManagement/interface";
import { useGetSessionQuery } from "@/src/store/apis/auth";
import { useTranslation } from "react-i18next";

export type AddItemType = {
  mainCategoryCodeItemName: string;
  subCategoryCodeItemName: string;
  item: string;
  exporterItemCodeList: { itemCode: string; description?: string }[];
};

interface AddItemDialogProps {
  open: boolean;
  onOpenChange: (value: boolean) => void;
  onSave: (newItems: AddItemType) => void;
  isSingleItemCode?: boolean;
  allowedMainCategories?: string[];
}

const emptyArray: PartialCommonCodeItemDto[] = [];
const mainCategoryEmptyArray: string[] = [];

function AddItemDialog({
  open,
  onOpenChange,
  onSave,
  isSingleItemCode = false,
  allowedMainCategories,
}: AddItemDialogProps) {
  const { t } = useTranslation();
  const hiddenButtonRef = useRef<HTMLButtonElement>(null);

  // API
  const { mainCategoryList } = useGetSessionQuery(undefined, {
    refetchOnMountOrArgChange: true,
    selectFromResult: ({ currentData, isError, isFetching }) => {
      const isErrorAndUndefined =
        isError || isFetching || currentData === undefined;

      const isManager =
        currentData?.row.exporterUserType === "MANAGER" ||
        currentData?.row.exporterUserType === "MIDDLE_MANAGER";

      return {
        mainCategoryList: !isErrorAndUndefined
          ? isManager
            ? currentData.row.mainCategoryCodeItemNames
            : currentData.row.exporter.mainCategoryCodeItemNames
          : mainCategoryEmptyArray,
      };
    },
  });
  const [getItemCodeCheck] = useLazyGetItemCodeCheckQuery();
  const { isMainCategoryFetching, mainCategoryCurrentData } =
    useGetCommonCodeViaCodeNameQuery(
      {
        codeName: "MAIN_CATEGORY",
      },
      {
        refetchOnMountOrArgChange: true,
        selectFromResult: ({ currentData, isError, isFetching }) => {
          const isErrorAndUndefined = isError || currentData === undefined;

          return {
            isMainCategoryFetching: isFetching,
            mainCategoryCurrentData: !isErrorAndUndefined
              ? currentData ?? emptyArray
              : emptyArray,
          };
        },
      }
    );

  const [
    getSubCategoryList,
    { subCategoryCurrentData, isSubCategoryFetching },
  ] = useLazyGetCommonCodeViaCodeNameQuery({
    selectFromResult: ({ currentData, isError, isFetching }) => {
      const isErrorAndUndefined = isError || currentData === undefined;

      return {
        isSubCategoryFetching: isFetching,
        subCategoryCurrentData: !isErrorAndUndefined
          ? currentData ?? emptyArray
          : emptyArray,
      };
    },
  });

  const [getItemList, { isItemListFetching, itemCurrentData }] =
    useLazyGetRegistrableItemsQuery({
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isErrorAndUndefined = isError || currentData === undefined;

        return {
          isItemListFetching: isFetching,
          itemCurrentData: !isErrorAndUndefined
            ? currentData.rows ?? emptyArray
            : emptyArray,
        };
      },
    });

  const mainCategoryOptionList = mainCategoryCurrentData
    ?.filter((item) => mainCategoryList.includes(item.codeItemName))
    .map((item) => {
      return {
        label: item.codeItemNameEn,
        value: item.codeItemName,
      };
    });

  const mainCategoryFilteringList = allowedMainCategories
    ? mainCategoryOptionList.filter((mainCategory) =>
        allowedMainCategories.includes(mainCategory.value)
      )
    : mainCategoryOptionList;

  const subCategoryOptionList = subCategoryCurrentData.map((item) => {
    return {
      label: item.codeItemNameEn,
      value: item.codeItemName,
    };
  });
  const itemOptionList = itemCurrentData.map((item) => {
    return {
      label: item.codeItemNameEn,
      value: item.codeItemName,
    };
  });

  const [isCancelAlertOpen, setIsCancelAlertOpen] = useState(false);

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    setFocus,
    getValues,
    formState: { errors },
    clearErrors,
  } = useForm<
    AddItemType & {
      itemDirectInput: string;
    }
  >({
    mode: "onBlur",
    defaultValues: {
      mainCategoryCodeItemName: "",
      subCategoryCodeItemName: "",
      item: "",
      itemDirectInput: "",
      exporterItemCodeList: [{ itemCode: "", description: "" }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "exporterItemCodeList",
  });

  const itemCodeValueList = watch("exporterItemCodeList").map(
    ({ itemCode }) => itemCode
  );

  useEffect(() => {
    setFocus("mainCategoryCodeItemName");
  }, [setFocus]);

  const handleSubmitClick = () => {
    const params = {
      mainCategoryCodeItemName: getValues("mainCategoryCodeItemName"),
      subCategoryCodeItemName: getValues("subCategoryCodeItemName"),
      item:
        getValues("item") === DIRECT_INPUT_VALUE
          ? getValues("itemDirectInput")
          : getValues("item"),
      exporterItemCodeList: getValues("exporterItemCodeList").map(
        ({ itemCode, description }) => {
          return {
            itemCode,
            description: description || undefined,
          };
        }
      ),
    };

    onSave?.(params);
  };

  const handleItemCodeCheck = async (itemCode: string) => {
    const params = {
      mainCategoryCodeItemName: watch("mainCategoryCodeItemName"),
      subCategoryCodeItemName: watch("subCategoryCodeItemName"),
      item:
        watch("item") === DIRECT_INPUT_VALUE
          ? watch("itemDirectInput")
          : watch("item"),
      itemCode,
    };

    const isValidation =
      params.item === "" ||
      params.itemCode === "" ||
      params.mainCategoryCodeItemName === "" ||
      params.subCategoryCodeItemName === "";

    if (isValidation) {
      return true;
    }

    try {
      const res = await getItemCodeCheck(params).unwrap();

      return res.row.message === ITEM_AVAILABLE_MESSAGE ? false : true;
    } catch (e: any) {
      return true;
    }
  };

  const validateDuplicateFields = async (value: string, index: number) => {
    const isDuplicateLocal = itemCodeValueList.indexOf(value) !== index;
    const isDuplicateServer = await handleItemCodeCheck(value);

    return isDuplicateLocal || isDuplicateServer
      ? t("error:itemCodeValidationError")
      : true;
  };

  return (
    <Dialog
      title={t("itemManagement:exporter.dialog.title.addItemCode")}
      open={open}
      width={640}
      onOpenChange={onOpenChange}
      height={560}
      destroyDialogWhenEscapePress={false}
      onEscapeKeyDown={() => setIsCancelAlertOpen(true)}
      footer={
        <DialogFooterContainer>
          <Button
            buttonGrade="tertiary"
            buttonColor="black"
            onClick={() => setIsCancelAlertOpen(true)}
          >
            <Typo typoType="btn3m" color="gray2">
              {t("itemManagement:exporter.dialog.buttons.cancelButton")}
            </Typo>
          </Button>
          <Button
            onClick={() => {
              hiddenButtonRef.current?.click();
            }}
          >
            <Typo typoType="btn3m" color="white">
              {t("itemManagement:exporter.dialog.buttons.saveButton")}
            </Typo>
          </Button>
        </DialogFooterContainer>
      }
    >
      {/* 취소 Alert */}
      {isCancelAlertOpen && (
        <CancelAlertDialog
          open={isCancelAlertOpen}
          onOpenChange={setIsCancelAlertOpen}
          onOk={() => onOpenChange(false)}
        />
      )}

      <Form onSubmit={handleSubmit(handleSubmitClick)}>
        <Callout>
          <InfoIcon color="gray6" />
          <Typo typoType="b9r" color="gray6">
            {t("itemManagement:exporter.dialog.callout")}
          </Typo>
        </Callout>

        {/* Form Item */}
        <GridFormItem
          label={t("itemManagement:exporter.dialog.label.mainCategory")}
          type="singleSelect"
          name="mainCategoryCodeItemName"
          control={control as any}
          rules={{ required: true }}
          errorsMessage={{
            required: t("error:required"),
          }}
          direction="horizontal"
          inputProps={{
            suffixIcon: isMainCategoryFetching ? <Loader /> : undefined,
            placeholder: t(
              "itemManagement:exporter.dialog.placeholder.mainCategory"
            ),
            getPopupContainer: (triggerNode: { parentElement: any }) => {
              return triggerNode.parentElement;
            },
            showAction: ["focus"],
            onChange: () => {
              setValue("subCategoryCodeItemName", "");
              setValue("item", "");
              clearErrors("exporterItemCodeList");

              // 싱글 아이템 경우 ItemCode 초기화
              isSingleItemCode &&
                setValue("exporterItemCodeList", [
                  { itemCode: "", description: "" },
                ]);

              (async () => {
                await getSubCategoryList({
                  parentCodeItemName: watch("mainCategoryCodeItemName"),
                });
              })();
            },
          }}
          options={mainCategoryFilteringList}
        />

        <GridFormItem
          label={t("itemManagement:exporter.dialog.label.subCategory")}
          type="singleSelect"
          name="subCategoryCodeItemName"
          control={control as any}
          rules={{ required: true }}
          errorsMessage={{
            required: t("error:required"),
          }}
          direction="horizontal"
          inputProps={{
            suffixIcon: isSubCategoryFetching ? <Loader /> : undefined,
            placeholder: t(
              "itemManagement:exporter.dialog.placeholder.subCategory"
            ),
            showAction: ["focus"],
            getPopupContainer: (triggerNode: { parentElement: any }) => {
              return triggerNode.parentElement;
            },
            disabled: watch("mainCategoryCodeItemName") === "",
            onChange: () => {
              setValue("item", "");
              clearErrors("exporterItemCodeList");

              // 싱글 아이템 경우 ItemCode 초기화
              isSingleItemCode &&
                setValue("exporterItemCodeList", [
                  { itemCode: "", description: "" },
                ]);

              (async () => {
                await getItemList({
                  subCategoryCodeItemName: watch("subCategoryCodeItemName"),
                });
              })();
            },
          }}
          options={subCategoryOptionList}
        />

        <GridFormItem
          label={t("itemManagement:exporter.dialog.label.item")}
          type="singleSelect"
          name="item"
          control={control as any}
          rules={{ required: true }}
          errorsMessage={{
            required: t("error:required"),
          }}
          direction="horizontal"
          inputProps={{
            suffixIcon: isItemListFetching ? <Loader /> : undefined,
            placeholder: t("itemManagement:exporter.dialog.placeholder.item"),
            showAction: ["focus"],
            getPopupContainer: (triggerNode: { parentElement: any }) => {
              return triggerNode.parentElement;
            },
            virtual: false,
            filterOption: (input, option) =>
              ((option?.label as string) ?? "")
                .toLowerCase()
                .includes(input.toLowerCase()) ||
              ((option?.value as string) ?? "")
                .toLowerCase()
                .includes(input.toLowerCase()) ||
              option?.value === DIRECT_INPUT_VALUE,
            dropdownRender: (menu) => {
              return <CustomDropdownWrapper>{menu}</CustomDropdownWrapper>;
            },
            disabled:
              watch("mainCategoryCodeItemName") === "" ||
              watch("subCategoryCodeItemName") === "",
            onChange: () => {
              const isDirectInput = watch("item") === DIRECT_INPUT_VALUE;
              const shouldInsertItemCodeOnBlur =
                !isDirectInput && !!watch("item");
              clearErrors("exporterItemCodeList");

              if (shouldInsertItemCodeOnBlur) {
                const itemCode =
                  itemOptionList.find(({ value }) => watch("item") === value)
                    ?.label || "";
                setValue("exporterItemCodeList.0.itemCode", itemCode);
              }
            },
          }}
          options={[
            { label: t(DIRECT_INPUT_LABEL), value: DIRECT_INPUT_VALUE },
            ...itemOptionList,
          ]}
          bottomAccessory={
            watch("item") === DIRECT_INPUT_VALUE && (
              <Controller
                control={control}
                rules={{
                  required:
                    watch("item") === DIRECT_INPUT_VALUE && t("error:required"),
                }}
                name="itemDirectInput"
                render={({ field }) => {
                  return (
                    <DirectInput>
                      <Input
                        {...field}
                        placeholder={t(
                          "itemManagement:exporter.dialog.placeholder.itemDirect"
                        )}
                        onClear={() => setValue("itemDirectInput", "")}
                        allowClear
                        data-invalid={!!errors.itemDirectInput}
                        onBlur={(e) => {
                          field.onBlur();
                          const shouldInsertItemCodeOnBlur = !getValues(
                            "exporterItemCodeList"
                          )[0].itemCode;
                          if (shouldInsertItemCodeOnBlur) {
                            setValue(
                              "exporterItemCodeList.0.itemCode",
                              e.target.value
                            );
                          }
                        }}
                      />
                      {errors.itemDirectInput && (
                        <InputError message={errors.itemDirectInput?.message} />
                      )}
                    </DirectInput>
                  );
                }}
              />
            )
          }
        />

        {fields.map((item, idx) => (
          <ItemCodeContainer key={item.id}>
            <ItemCodeLabel>
              <Typo color="gray1" typoType="b7r">
                {t("itemManagement:exporter.dialog.label.itemCode")}
              </Typo>{" "}
              <Typo color="red2" typoType="b7r">
                *
              </Typo>
            </ItemCodeLabel>

            <ItemContainer key={item.id}>
              <ItemCodeController>
                <Controller
                  control={control}
                  rules={{
                    required: t("error:required"),
                    validate: async (value) =>
                      await validateDuplicateFields(value, idx),
                  }}
                  name={`exporterItemCodeList.${idx}.itemCode`}
                  render={({ field }) => {
                    return (
                      <ItemCode>
                        <Input
                          {...field}
                          placeholder={t(
                            "itemManagement:exporter.dialog.placeholder.itemCode"
                          )}
                          data-invalid={
                            !!errors.exporterItemCodeList?.[idx]?.itemCode
                              ?.message
                          }
                          disabled={
                            watch("mainCategoryCodeItemName") === "" ||
                            watch("subCategoryCodeItemName") === "" ||
                            watch("item") === ""
                          }
                          onClear={() =>
                            setValue(`exporterItemCodeList.${idx}.itemCode`, "")
                          }
                          onChange={(e) => {
                            clearErrors(`exporterItemCodeList.${idx}.itemCode`);
                            setValue(
                              `exporterItemCodeList.${idx}.itemCode`,
                              e.target.value
                            );
                          }}
                        />
                        {errors.exporterItemCodeList && (
                          <StyledInputError
                            message={
                              errors.exporterItemCodeList[idx]?.itemCode
                                ?.message
                            }
                          />
                        )}
                      </ItemCode>
                    );
                  }}
                />

                {!isSingleItemCode && (
                  <DeleteButton
                    type="button"
                    onClick={() => remove(idx)}
                    buttonColor="black"
                    buttonGrade="tertiary"
                    disabled={watch("exporterItemCodeList").length === 1}
                  >
                    {watch("exporterItemCodeList").length === 1 ? (
                      <DeleteIcon color="red6" />
                    ) : (
                      <DeleteIcon color="red2" />
                    )}
                  </DeleteButton>
                )}
              </ItemCodeController>

              <TextAreaController isSingleItemCode={isSingleItemCode}>
                <Controller
                  control={control}
                  name={`exporterItemCodeList.${idx}.description`}
                  render={({ field }) => {
                    return (
                      <TextArea
                        {...field}
                        maxLength={100}
                        placeholder={t(
                          "itemManagement:exporter.dialog.placeholder.itemCodeDescription"
                        )}
                        disabled={
                          watch("mainCategoryCodeItemName") === "" ||
                          watch("subCategoryCodeItemName") === "" ||
                          watch("item") === ""
                        }
                      />
                    );
                  }}
                />
              </TextAreaController>
            </ItemContainer>
          </ItemCodeContainer>
        ))}

        {!isSingleItemCode && (
          <AddButton
            buttonColor="black"
            buttonGrade="tertiary"
            onClick={() => append({ itemCode: "", description: "" })}
            buttonSize={40}
          >
            <Icon iconSrc={AddSvg} iconSize={16} />
            {t("itemManagement:exporter.dialog.buttons.addItemCodeButton")}
          </AddButton>
        )}
        <Hidden ref={hiddenButtonRef} type="submit" />
      </Form>
    </Dialog>
  );
}

export default AddItemDialog;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Callout = styled.div`
  display: flex;
  padding: 16px;
  gap: 6px;
  border-radius: 8px;
  background: ${colorSet.gray11};
`;

const InfoIcon = styled(InfoSvg)<{ color: ColorType }>`
  width: 16px;
  height: 16px;
  flex-shrink: 0;

  path {
    fill: ${({ color }) => colorSet[color]};
  }
`;

const DeleteIcon = styled(DeleteSvg)<{ color: ColorType }>`
  width: 16px;
  height: 16px;
  flex-shrink: 0;

  path {
    fill: ${({ color }) => colorSet[color]};
  }
`;

const DirectInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Hidden = styled.button`
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
`;

const ItemCodeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;

const AddButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin-left: 172px;
`;

const DeleteButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 40px;
  height: 40px;
`;

const StyledInputError = styled(InputError)`
  grid-column: 2 span;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
`;

const TextAreaController = styled.div<{ isSingleItemCode: boolean }>`
  width: ${({ isSingleItemCode }) =>
    isSingleItemCode ? "100%" : "calc(100% - 48px)"};

  textarea {
    height: 96px;
  }
`;

const ItemCodeLabel = styled.div`
  width: 164px;
`;

const ItemCode = styled(DirectInput)`
  flex: 1;
`;

const ItemCodeController = styled.div`
  display: flex;
  gap: 8px;
`;

const CustomDropdownWrapper = styled.div`
  div.ant-select-item {
    &:first-of-type {
      color: ${colorSet.blue4};

      &::before {
        content: url(${AddBlueSvg});
        width: 18px;
        height: 18px;
        position: relative;
        margin-right: 8px;
        top: 4px;
      }
    }
  }
`;

const GridFormItem = styled(FormItem)`
  display: grid;
  grid-template-columns: auto minmax(412px, 420px);
`;
