import { Button } from "@/src/components/atom/Button";
import Dialog from "@/src/components/atom/Dialog";
import DialogFooterContainer from "@/src/components/atom/Dialog/DialogFooterContainer";
import Label from "@/src/components/atom/Label";
import Typo from "@/src/components/atom/Typo";
import FormItem from "@/src/components/molecule/FormItem";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import colorSet from "@/src/styles/color";
import { CSSProperties, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { ReactComponent as AddSvg } from "@/src/assets/icons/icon-add-white.svg";
import {
  useCreateSingleItemCodeMutation,
  useGetItemCodeListQuery,
} from "@/src/store/apis/corporate/companyItemManagement";
import { isUndefined } from "@/src/utils/is";
import Loader from "@/src/components/atom/Loader";
import Input from "@/src/components/atom/Input";
import { useGetCommonCodeViaCodeNameQuery } from "@/src/store/apis/common";
import SearchSelect from "@/src/components/atom/Select/SearchSelect";
import AddItemDialog, {
  AddItemType,
} from "@/components/organism/AddItemDialog";
import useAlert from "@/src/hooks/useAlert";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@/src/store";
import { GroupType, OptionType } from "types";

export type SalesItemType = {
  exporterItemId: string;
  exporterItemCodeId: string;
  itemName: string;
  itemCode: string;
  subCategory: string;
  mainCategory: string;
  quantity: string;
  quantityUnit: string;
  quantityUnitCodeItemName: string;
  unitPrice: string;
  unitPriceUnit: string;
  unitPriceUnitCodeItemName: string;
  amount: string;
  amountUnit: string;
};




interface AddSalesItemDialogProps {
  open: boolean;
  onOpenChange: (value: boolean) => void;
  onSave: (newItem: SalesItemType & { id: string }) => void;
}

enum DialogState {
  NULL,
  ADD_ITEM,
}

function AddSalesItemDialog({
  open,
  onOpenChange,
  onSave,
}: AddSalesItemDialogProps) {
  const { t } = useTranslation();
  const alert = useAlert();
  const user = useAppSelector((state) => state.auth.user);

  const [dialogState, setDialogState] = useState<DialogState>(DialogState.NULL);

  // API
  const [createSingleItemCode] = useCreateSingleItemCodeMutation();
  const { isItemFetching, itemList } = useGetItemCodeListQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          isItemFetching: isFetching,
          itemList: isStable ? currentData?.rows : [],
        };
      },
    }
  );
  const {
    currentData: measurementList,
    isFetching: isMeasurementListFetching,
  } = useGetCommonCodeViaCodeNameQuery(
    {
      codeName: "MEASUREMENT",
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const {
    currentData: currencyUnitList,
    isFetching: isCurrencyUnitListFetching,
  } = useGetCommonCodeViaCodeNameQuery(
    {
      codeName: "CURRENCY",
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const measurementListToOptionList = measurementList
    ? measurementList.reduce<OptionType[]>((acc, val) => {
        const resource = {
          label: val.codeItemNameEn,
          value: val.codeItemNameEn,
        };
        return [...acc, { ...resource }];
      }, [])
    : [];

  const currencyUnitListToOptionList = currencyUnitList
    ? currencyUnitList.reduce<OptionType[]>((acc, val) => {
        const resource = {
          label: val.codeItemNameEn,
          value: val.codeItemName,
        };
        return [...acc, { ...resource }];
      }, [])
    : [];

  const { watch, control, setValue, getValues } = useForm<SalesItemType>({
    mode: "onBlur",
    defaultValues: {
      quantityUnit: measurementListToOptionList ? "MT" : "",
      unitPriceUnit: currencyUnitListToOptionList ? "USD" : "",
    },
  });

  const isButtonDisabled =
    !!watch("exporterItemId") &&
    !!watch("quantity") &&
    !!watch("quantityUnit") &&
    !!watch("unitPriceUnit") &&
    !!watch("unitPrice");

  const itemMappingList = itemList
    .filter((item) =>
      user?.exporterUserType === "MANAGER"
        ? user?.mainCategoryCodeItemNames.includes(
            item.mainCategoryCodeItemName
          )
        : true
    )
    .reduce((acc: GroupType[], item) => {
      // 그룹 라벨 생성
      const groupLabel = `${item.mainCategory} > ${item.subCategory}`;

      // 그룹이 이미 존재하는지 확인
      let group = acc.find((g) => g.label === groupLabel);
      if (!group) {
        group = { label: groupLabel, options: [] };
        acc.push(group);
      }

      // 옵션 레이블 생성
      const isItemCodeSameAsItem = item.item === item.itemCode;
      const optionLabel = isItemCodeSameAsItem
        ? `${item.item}`
        : `${item.item}(${item.itemCode})`;

      // 옵션 추가
      group.options.push({
        label: optionLabel,
        value: `${item.id}`,
      });

      return acc;
    }, []);

  const handleSaveClick = () => {
    onOpenChange(false);

    const quantityUnitCodeItemName =
      measurementList?.find(
        (item) => item.codeItemNameEn === watch("quantityUnit")
      )?.codeItemName ?? "";

    const unitPriceUnitCodeItemName =
      currencyUnitList?.find(
        (item) => item.codeItemName === watch("unitPriceUnit")
      )?.codeItemNameEn ?? "";

    onSave({
      id: `${uuidv4()}`,
      exporterItemId: getValues("exporterItemId"),
      exporterItemCodeId: getValues("exporterItemCodeId"),
      itemName: getValues("itemName"),
      itemCode: getValues("itemCode"),
      subCategory: getValues("subCategory"),
      mainCategory: getValues("mainCategory"),
      quantity: watch("quantity").replace(/,/g, ","),
      quantityUnit: getValues("quantityUnit"),
      quantityUnitCodeItemName,
      unitPrice: watch("unitPrice").replace(/,/g, ","),
      unitPriceUnit: getValues("unitPriceUnit"),
      unitPriceUnitCodeItemName,
      amount: String(
        Number(getValues("quantity").replace(/,/g, "")) *
          Number(getValues("unitPrice").replace(/,/g, ""))
      ),
      amountUnit: getValues("unitPriceUnit"),
    });
  };

  const handleAddItems = async ({
    item,
    exporterItemCodeList,
    subCategoryCodeItemName,
    mainCategoryCodeItemName,
  }: AddItemType) => {
    try {
      const { row } = await createSingleItemCode({
        item,
        itemCode: exporterItemCodeList[0].itemCode,
        subCategoryCodeItemName: subCategoryCodeItemName,
        mainCategoryCodeItemName: mainCategoryCodeItemName,
      }).unwrap();

      if (row) {
        setValue("exporterItemId", `${row.exporterItemId}`);
        setValue("exporterItemCodeId", `${row.id}`);
        setValue("mainCategory", row.mainCategory ?? "");
        setValue("subCategory", row.subCategory ?? "");
        setValue("itemName", row.item ?? "");
        setValue("itemCode", row.itemCode ?? "");

        alert.showAlert({
          type: "success",
          message: t("alert:saveSuccess"),
        });
        setDialogState(DialogState.NULL);
      }
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const getAmount = () => {
    if (watch("quantity") && watch("unitPrice") && watch("unitPriceUnit")) {
      return `${(
        Number(watch("quantity").replace(/,/g, "")) *
        Number(watch("unitPrice").replace(/,/g, ""))
      ).toLocaleString("ko-KR")} ${watch("unitPriceUnit")}`;
    } else {
      return `0 ${watch("unitPriceUnit")}`;
    }
  };

  const getAmountMethod = () => {
    const qtyUnitData =
      measurementList &&
      measurementList.find(
        (item) => item.codeItemName === watch("quantityUnit")
      );

    return `${watch("quantity") ?? "0"} ${
      qtyUnitData?.codeItemNameEn ?? ""
    } X ${watch("unitPrice") ?? "0"} ${watch("unitPriceUnit")}`;
  };

  const renderDialog = () => {
    if (dialogState === DialogState.ADD_ITEM) {
      return (
        <AddItemDialog
          open={dialogState === DialogState.ADD_ITEM}
          onOpenChange={() => setDialogState(DialogState.NULL)}
          onSave={handleAddItems}
          isSingleItemCode
        />
      );
    }
  };

  return (
    <Dialog
      title={t("sales:common.addItem")}
      open={open}
      onOpenChange={onOpenChange}
      width={640}
      footer={
        <DialogFooterContainer>
          <Button
            buttonGrade="tertiary"
            buttonColor="black"
            onClick={() => onOpenChange(false)}
          >
            <Typo typoType="btn3m">{t("sales:common.button.cancel")}</Typo>
          </Button>

          <Button onClick={handleSaveClick} disabled={!isButtonDisabled}>
            <Typo color="white" typoType="btn3m">
              {t("sales:common.button.save")}
            </Typo>
          </Button>
        </DialogFooterContainer>
      }
    >
      <FlexColumn gap={16}>
        <StyledSectionCardRow
          label={
            <Label required>
              <Typo>{t("sales:common.item")}</Typo>
            </Label>
          }
          value={
            <GridTemplate gap={8} gridTemplateColumn={"291px auto"}>
              <FormItem
                type="singleSelect"
                direction="vertical"
                name="exporterItemCodeId"
                control={control}
                rules={{ required: true }}
                options={itemMappingList}
                inputProps={{
                  disabled: isItemFetching,
                  suffixIcon: isItemFetching ? <Loader /> : undefined,
                  placeholder: t("contract:exporter.add.placeholder.item"),
                  onChange: (exporterItemId) => {
                    const findItem = itemList.find(
                      ({ id }) => id === Number(exporterItemId)
                    );

                    if (findItem) {
                      setValue("exporterItemId", `${findItem.exporterItemId}`);
                      setValue("itemCode", findItem.itemCode);
                      setValue("itemName", findItem.item);
                      setValue("subCategory", findItem.subCategory);
                      setValue("mainCategory", findItem.mainCategory);
                    }
                  },
                  getPopupContainer: (triggerNode: { parentElement: any }) => {
                    return triggerNode.parentElement;
                  },
                }}
              />
              <StyledButton
                buttonColor="black"
                buttonGrade="tertiary"
                onClick={() => setDialogState(DialogState.ADD_ITEM)}
              >
                <AddIcon />
                {t("sales:common.button.addItem")}
              </StyledButton>

              <GridSectionCardRow
                label={
                  <StyledTypo typoType="b9r" color="gray6">
                    {t("sales:common.itemCode")}
                  </StyledTypo>
                }
                value={
                  <StyledInput
                    disabled
                    value={watch("itemCode")}
                    placeholder={t("sales:common.itemCode")}
                  />
                }
              />
              <GridSectionCardRow
                label={
                  <StyledTypo typoType="b9r" color="gray6">
                    {t("sales:common.item")}
                  </StyledTypo>
                }
                value={
                  <StyledInput
                    disabled
                    value={watch("itemName")}
                    placeholder={t("sales:common.item")}
                  />
                }
              />
              <GridSectionCardRow
                label={
                  <StyledTypo typoType="b9r" color="gray6">
                    {t("sales:common.subCategory")}
                  </StyledTypo>
                }
                value={
                  <StyledInput
                    disabled
                    value={watch("subCategory")}
                    placeholder={t("sales:common.subCategory")}
                  />
                }
              />
              <GridSectionCardRow
                label={
                  <StyledTypo typoType="b9r" color="gray6">
                    {t("sales:common.mainCategory")}
                  </StyledTypo>
                }
                value={
                  <StyledInput
                    disabled
                    value={watch("mainCategory")}
                    placeholder={t("sales:common.mainCategory")}
                  />
                }
              />
            </GridTemplate>
          }
        />

        <Divider />

        <FlexColumn gap={8}>
          <StyledSectionCardRow
            label={
              <Label required>
                <Typo>{t("common:amount")}</Typo>
              </Label>
            }
            value={
              <StyledInput
                disabled
                value={`${getAmount()} (${getAmountMethod()})`}
              />
            }
          />
          <StyledSectionCardRow
            label={
              <Label required>
                <Typo>{t("common:qty")}</Typo>
              </Label>
            }
            value={
              <GridTemplate gridTemplateColumn={"108px auto"} gap={8}>
                <Controller
                  name="quantityUnit"
                  control={control}
                  render={({ field }) => (
                    <SearchSelect
                      {...field}
                      options={measurementListToOptionList}
                      suffixIcon={
                        isMeasurementListFetching ? <Loader /> : undefined
                      }
                      getPopupContainer={(triggerNode) => {
                        return triggerNode.parentElement;
                      }}
                    />
                  )}
                />
                <Controller
                  name="quantity"
                  control={control}
                  render={({ field }) => (
                    <StyledInput
                      {...field}
                      placeholder={t("placeholder:enterQty")}
                      onChange={(e) =>
                        setValue(
                          "quantity",
                          Number(
                            e.target.value.replace(/[^0-9]/g, "")
                          ).toLocaleString("ko-KR")
                        )
                      }
                      onBlur={(e) => {
                        if (e.target.value === "0") {
                          setValue("quantity", "");
                        }
                      }}
                    />
                  )}
                />
              </GridTemplate>
            }
          />
          <StyledSectionCardRow
            label={
              <Label required>
                <Typo>{t("common:unitPrice")}</Typo>
              </Label>
            }
            value={
              <GridTemplate gridTemplateColumn={"108px auto"} gap={8}>
                <Controller
                  name="unitPriceUnit"
                  control={control}
                  render={({ field }) => (
                    <SearchSelect
                      {...field}
                      options={currencyUnitListToOptionList}
                      suffixIcon={
                        isCurrencyUnitListFetching ? <Loader /> : undefined
                      }
                      getPopupContainer={(triggerNode) => {
                        return triggerNode.parentElement;
                      }}
                    />
                  )}
                />
                <Controller
                  name="unitPrice"
                  control={control}
                  render={({ field }) => (
                    <StyledInput
                      {...field}
                      placeholder={t("placeholder:unitPrice")}
                      onChange={(e) =>
                        setValue(
                          "unitPrice",
                          Number(
                            e.target.value.replace(/[^0-9]/g, "")
                          ).toLocaleString("ko-KR")
                        )
                      }
                      onBlur={(e) => {
                        if (e.target.value === "0") {
                          setValue("unitPrice", "");
                        }
                      }}
                    />
                  )}
                />
              </GridTemplate>
            }
          />
        </FlexColumn>
      </FlexColumn>

      {renderDialog()}
    </Dialog>
  );
}

export default AddSalesItemDialog;

const FlexColumn = styled.div<{ gap: number }>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap}px;
`;

const GridTemplate = styled.div<{
  gridTemplateColumn: CSSProperties["gridTemplateColumns"];
  gap: number;
}>`
  display: grid;
  grid-template-columns: ${({ gridTemplateColumn }) => gridTemplateColumn};
  gap: ${({ gap }) => gap}px;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const AddIcon = styled(AddSvg)`
  width: 16px;
  height: 16px;

  path {
    fill: ${colorSet.gray2};
  }
`;

const GridSectionCardRow = styled(SectionCardRow)`
  grid-column: span 2;
  display: flex;
  align-items: center;

  & > p {
    &:first-of-type {
      min-width: 103px;
      max-width: 103px;
    }

    &:last-of-type {
      width: 100%;
    }
  }
`;

const StyledSectionCardRow = styled(SectionCardRow)`
  & > p {
    width: 100%;
  }
`;

const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colorSet.gray9};
`;

const StyledInput = styled(Input)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledTypo = styled(Typo)`
  &::after {
    content: " *";
    color: ${colorSet.red2};
  }
`;
