import React, { ReactNode, useEffect, useState } from "react";
import dayjs from "dayjs";
import { css, styled } from "styled-components";
import Typo from "@/src/components/atom/Typo";
import Dialog from "@/src/components/atom/Dialog";
import colorSet, { ColorType } from "@/src/styles/color";
import useAlert from "@/src/hooks/useAlert";
import { useLazyGetSingleShipmentContainerMediaQuery } from "@/src/store/apis/shipments/shipmentDetail";
import Loader from "@/src/components/atom/Loader";
import {
  renderContainerIcon,
  renderContainerText,
} from "@/src/pages/exporter/Task/components/detail/utils/renderContainerType";
import { ReactComponent as InfoSvg } from "@/src/assets/icons/icon-info-gray6.svg";
import { renderNoRowsComponent } from "@/src/components/atom/Table";
import {
  ContainerPhotoListType,
  MEDIA_CONTAINER_MAP,
  MEDIA_COUNT_KEY_LIST,
  MEDIA_MAP,
  MEDIA_UPLOAD_COUNT_MAP,
} from "./imageMapper";
import InformationItemTooltip from "./InformationItemTooltip";
import BreakWordTypo from "@/src/components/molecule/BreakWordTypo";
import RadixTooltip from "@/src/components/atom/Tooltip/RadixTooltip";
import typo from "@/src/styles/typography";
import { useTranslation } from "react-i18next";
import LoadingPhotoPreviewDialog from "@/src/components/organism/LoadingPhotoPreviewDialog";
import { MediaCoordinateRangeType } from "@/src/store/apis/tasks/taskRegister/interface";
import { toPascalCase } from "@/src/utils/transform";
import { IconButton } from "@/src/components/atom/Button";
import { downloadFile } from "@/src/utils/downloadFile";
import { ReactComponent as DownloadSvg } from "@/src/assets/icons/icon-download-black.svg";

interface SingleContainerPhotoDialogProps {
  onClose?: () => void;
  containerId: number;
}

const SingleContainerPhotoDialog = ({
  onClose,
  containerId,
}: SingleContainerPhotoDialogProps) => {
  const { t } = useTranslation();
  const [getSingleContainerMedia, singleContainerMediaResult] =
    useLazyGetSingleShipmentContainerMediaQuery();
  const alert = useAlert();
  const [isLazyLoading, setLazyLoading] = useState(false);
  const [selectedImageSrc, setSelectedImageSrc] = useState<{
    src: string;
    createdAt: string;
    latitude?: string;
    longitude?: string;
    zoomLevel?: number;
    mediaCoordinateRange?: MediaCoordinateRangeType;
    originalFileName?: string;
  }>();
  const [isPreviewDownloadLoading, setIsPreviewDownloadLoading] =
    useState(false);

  const containerPhotoList = singleContainerMediaResult.isSuccess
    ? Object.entries(singleContainerMediaResult.data).reduce<
        ContainerPhotoListType[]
      >((acc, [key, val]) => {
        if (
          MEDIA_COUNT_KEY_LIST.includes(
            key as (typeof MEDIA_COUNT_KEY_LIST)[number]
          )
        ) {
          const mediaCountKey = key as (typeof MEDIA_COUNT_KEY_LIST)[number];
          const containerType = MEDIA_CONTAINER_MAP[mediaCountKey];
          return [
            ...acc,
            {
              isActive: val !== 0,
              maxCount: val,
              uploadedCount: (singleContainerMediaResult.data as any)[
                MEDIA_UPLOAD_COUNT_MAP[mediaCountKey]
              ],
              imageList: (singleContainerMediaResult.data as any)[
                MEDIA_MAP[mediaCountKey]
              ],
              containerType: containerType,
              title: renderContainerText(t, containerType, "h6"),
              icon: renderContainerIcon(containerType),
              extraMediaRemark:
                singleContainerMediaResult.data.extraMediaRemark,
            },
          ];
        }

        return acc;
      }, [])
    : [];

  const totalMaxCount = containerPhotoList
    .filter(({ isActive }) => isActive)
    .reduce((acc, val) => acc + val.maxCount, 0);

  const totalUploadCount = containerPhotoList
    .filter(({ isActive }) => isActive)
    .reduce((acc, val) => acc + val.uploadedCount, 0);

  const handlePreviewDownloadClick = async () => {
    try {
      setIsPreviewDownloadLoading(true);
      const selectUrl = {
        ...selectedImageSrc,
        mediaUrl: selectedImageSrc?.src,
      };

      if (!selectUrl?.mediaUrl) {
        return;
      }

      await downloadFile(
        selectUrl?.mediaUrl ?? "",
        selectUrl?.originalFileName ?? ""
      );
    } finally {
      setIsPreviewDownloadLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        await getSingleContainerMedia({ id: containerId }).unwrap();
      } catch (e: any) {
        const message = Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message;

        alert.showAlert({ type: "error", message });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!singleContainerMediaResult.isFetching) {
      setTimeout(() => {
        setLazyLoading(false);
      }, 500);
    } else {
      setLazyLoading(true);
    }
  }, [singleContainerMediaResult.isFetching]);

  const renderImageList = () => {
    const isDashValue =
      !singleContainerMediaResult.isSuccess ||
      singleContainerMediaResult.isFetching ||
      singleContainerMediaResult.isError;

    return (
      <RestWidthFlexItem>
        <MiddleTitleSection>
          <p className="ellipsis-header">
            <Typo typoType="h6">
              {isDashValue
                ? "-"
                : singleContainerMediaResult.currentData.containerNo ?? "-"}
            </Typo>
          </p>
          <p>
            <Typo typoType="b8m" color="gray5" className="flex-shrink-0">
              {t("common:total")} {totalUploadCount} / {totalMaxCount}
            </Typo>
          </p>
        </MiddleTitleSection>

        <MiddleRestSection>
          {isLazyLoading ? (
            <FlexCenterContainer>
              <Loader size={50} />
            </FlexCenterContainer>
          ) : (
            <>
              {containerPhotoList
                .filter(({ isActive }) => isActive)
                .map(
                  ({
                    title,
                    icon,
                    maxCount,
                    uploadedCount,
                    imageList,
                    containerType,
                    extraMediaRemark,
                  }) => {
                    return (
                      <ContainerItemArticle>
                        <header>
                          <TitleWithIcon>
                            {icon}
                            <Typo
                              typoType="h6"
                              className="ellipsis-middle-header"
                            >
                              {title}
                              {containerType === "EXTRA" && (
                                <>
                                  <TooltipContainer>
                                    <RadixTooltip
                                      content={
                                        <TooltipContent>
                                          <span>{t("common:remark")}</span>

                                          {extraMediaRemark && (
                                            <span>{extraMediaRemark}</span>
                                          )}
                                        </TooltipContent>
                                      }
                                    >
                                      <InfoIcon color={"gray7"} />
                                    </RadixTooltip>
                                  </TooltipContainer>
                                </>
                              )}
                            </Typo>
                          </TitleWithIcon>
                          <Typo
                            typoType="b8m"
                            color="gray5"
                            className="flex-shrink-0"
                          >
                            {t("common:total")} {uploadedCount} / {maxCount}
                          </Typo>
                        </header>

                        <ImageSection>
                          {!!imageList?.length ? (
                            <>
                              {imageList.map(
                                ({ mediaUrl, createdAt, coordinate }, idx) => {
                                  const containerNo =
                                    singleContainerMediaResult?.currentData
                                      ?.containerNo || "-";

                                  return (
                                    <StyledImage
                                      onClick={() =>
                                        setSelectedImageSrc({
                                          src: mediaUrl,
                                          createdAt: createdAt,
                                          latitude: coordinate?.latitude,
                                          longitude: coordinate?.longitude,
                                          zoomLevel: coordinate?.zoomLevel
                                            ? Number(coordinate?.zoomLevel)
                                            : undefined,
                                          mediaCoordinateRange:
                                            coordinate?.mediaCoordinateRange,
                                          originalFileName: `${containerNo}_${toPascalCase(
                                            containerType
                                          )}${String(idx + 1).padStart(
                                            2,
                                            "0"
                                          )}.jpg`,
                                        })
                                      }
                                    >
                                      <img
                                        src={mediaUrl}
                                        alt={`${containerType}_${idx}`}
                                      />
                                    </StyledImage>
                                  );
                                }
                              )}
                            </>
                          ) : (
                            <NoDataContainer>
                              {renderNoRowsComponent()}
                            </NoDataContainer>
                          )}
                        </ImageSection>
                      </ContainerItemArticle>
                    );
                  }
                )}
            </>
          )}
        </MiddleRestSection>
      </RestWidthFlexItem>
    );
  };

  const renderInformation = () => {
    const isDashValue =
      !singleContainerMediaResult.isSuccess ||
      singleContainerMediaResult.isFetching ||
      singleContainerMediaResult.isError;

    return (
      <>
        {[
          {
            label: t("common:containerNo"),
            value: (
              <Typo typoType="h6" color="blue2">
                {isDashValue
                  ? "-"
                  : singleContainerMediaResult.currentData.containerNo ?? "-"}
              </Typo>
            ),
          },
          {
            label: t("common:sealNo"),
            value: (
              <Typo typoType="h6" color="blue2">
                {isDashValue
                  ? "-"
                  : singleContainerMediaResult.currentData.sealNo ?? "-"}
              </Typo>
            ),
          },
          { isGrayDivider: true },
          {
            label: t("common:workingDay"),
            value: isDashValue
              ? "-"
              : dayjs(
                  singleContainerMediaResult.currentData.workingDayAt
                ).format("YYYY-MM-DD"),
          },
          {
            label: t("common:factory"),
            value: isDashValue
              ? "-"
              : singleContainerMediaResult.currentData.workplaceName,
            isTooltip: true,
          },
          {
            label: t("common:buyer"),
            value: isDashValue
              ? "-"
              : singleContainerMediaResult.currentData.buyerName,
            isTooltip: true,
          },
          {
            label: t("common:item"),
            value: isDashValue
              ? "-"
              : singleContainerMediaResult.currentData.item,
            isTooltip: true,
          },
          {
            label: t("common:itemCode"),
            value: isDashValue
              ? "-"
              : singleContainerMediaResult.currentData.itemCode,
            isTooltip: true,
          },
          {
            label: t("common:netWeight"),
            value: isDashValue
              ? "-"
              : singleContainerMediaResult.currentData.netWeight ?? "-",
          },
        ].map(({ label, value, isGrayDivider = false, isTooltip }) => {
          if (isGrayDivider) {
            return <Divider />;
          }
          return (
            <InformationItem
              label={label}
              value={value}
              isTooltip={isTooltip}
            />
          );
        })}
      </>
    );
  };

  const renderDialog = () => {
    if (!selectedImageSrc) return null;
    const { src, ...rest } = selectedImageSrc;

    return (
      // 수출자 > 선적생성 > 컨테이너 선택 > 테이블 사진보기 > 작업사진 모아보기 > 단일항목 사진조회
      <LoadingPhotoPreviewDialog
        open
        onOpenChange={() => setSelectedImageSrc(undefined)}
        selectImage={{ ...rest, mediaUrl: src }}
        mediaCoordinateRange="ALL"
        photoDetailRightAccessory={
          <IconButton
            buttonGrade="tertiary"
            buttonColor="black"
            onClick={handlePreviewDownloadClick}
            isLoading={isPreviewDownloadLoading}
          >
            <DownloadIcon />
          </IconButton>
        }
      />
    );
  };

  return (
    <>
      <Dialog
        title={t("shipment:exporter.dialog.title.ViewPhotosOfContainerWork")}
        open
        onOpenChange={() => {
          onClose?.();
        }}
        width={976}
      >
        <InnerContent>
          <FlexBox>
            {renderImageList()}

            <FixedWidthFlexItem>
              <InformationTitleSection>
                <Typo typoType="h6">
                  {t("shipment:exporter.dialog.label.information")}
                </Typo>
              </InformationTitleSection>

              <MiddleRestSection className="gap-24">
                {renderInformation()}
              </MiddleRestSection>
            </FixedWidthFlexItem>
          </FlexBox>
        </InnerContent>
        {renderDialog()}
      </Dialog>
    </>
  );
};

export default SingleContainerPhotoDialog;

const InnerContent = styled.div`
  border: 1px solid ${colorSet.gray9};
  border-radius: 8px;
`;

const FlexBox = styled.div`
  display: flex;
`;

const FixedWidthFlexItem = styled.div`
  width: 220px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  position: relative;

  &:first-of-type {
    border-right: 1px solid ${colorSet.gray9};
  }

  &:last-of-type {
    border-left: 1px solid ${colorSet.gray9};
  }
`;
const RestWidthFlexItem = styled.div`
  flex: 1;
`;
const MiddleTitleSection = styled.div`
  width: 100%;
  height: 61px;
  padding: 16px 16px 10px 16px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  background: ${colorSet.gray11};
  border-bottom: 1px solid ${colorSet.gray9};
  border-radius: 8px 0 0;

  .ellipsis-header {
    max-width: 613px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const MiddleRestSection = styled.div`
  flex: 1;
  height: 689px;
  overflow: auto;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &.gap-24 {
    gap: 24px;
  }
`;

const ContainerItemArticle = styled.article`
  display: flex;
  flex-direction: column;
  gap: 16px;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid ${colorSet.gray9};

    .flex-shrink-0 {
      flex-shrink: 0;
    }

    .ellipsis-middle-header {
      max-width: 605px;
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

const TitleWithIcon = styled.p`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledImage = styled.div`
  width: 209px;
  height: 209px;
  border-radius: 8px;
  border: 1px solid ${colorSet.gray9};
  overflow: hidden;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.25s;

    &:hover {
      transform: scale(1.1);
    }
  }
`;

const ImageSection = styled.section`
  display: flex;
  gap: 16px;
`;

const InformationTitleSection = styled.div`
  height: 61px;
  padding: 16px 16px 10px 16px;
  display: flex;
  align-items: flex-end;
  border-bottom: 1px solid ${colorSet.gray9};
`;

const Divider = styled.div<{ gap?: number }>`
  width: 100%;
  border-bottom: 1px solid ${colorSet.gray9};
  ${({ gap }) =>
    gap &&
    css`
      margin: ${gap}px 0;
    `};
`;

const InformationItem = ({
  label,
  value,
  isTooltip,
}: {
  label: ReactNode;
  value: ReactNode;
  isTooltip?: boolean;
}) => {
  if (isTooltip) {
    return (
      <InformationItemContainer>
        <Typo typoType="b9m" color="gray6">
          {label}
        </Typo>

        <InformationItemTooltip
          trigger={
            <Typo typoType="h6" color="gray2" className="ellipsis">
              {value}
            </Typo>
          }
          content={
            <BreakWordTypo color="white" typoType="b10m">
              {value}
            </BreakWordTypo>
          }
        />
      </InformationItemContainer>
    );
  }

  return (
    <InformationItemContainer>
      <Typo typoType="b9m" color="gray6">
        {label}
      </Typo>
      <Typo typoType="h6" color="gray2" className="ellipsis">
        {value}
      </Typo>
    </InformationItemContainer>
  );
};

const InformationItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const NoDataContainer = styled.div`
  height: 210px;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const FlexCenterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const TooltipContainer = styled.span`
  margin-left: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 3px;
`;

const TooltipContent = styled.div`
  background: ${colorSet.gray1};
  opacity: 80%;
  max-width: 494px;
  border-radius: 4px;
  padding: 4px 8px;
  display: flex;
  flex-direction: column;
  gap: 2px;

  ${typo.b10m};
  color: ${colorSet.white};
`;

const InfoIcon = styled(InfoSvg)<{ color: ColorType }>`
  width: 16px;
  height: 16px;
  flex-shrink: 0;

  path {
    fill: ${({ color }) => colorSet[color]};
  }
`;

const DownloadIcon = styled(DownloadSvg)<{
  color?: ColorType;
  disabled?: boolean;
}>`
  width: 16px;
  height: 16px;

  path {
    ${({ color }) =>
      color &&
      css`
        fill: ${colorSet[color]};
      `}

    ${({ disabled }) =>
      disabled &&
      css`
        fill: ${colorSet.gray8};
      `}
  }
`;
