import { Button, IconButton } from "@/src/components/atom/Button";
import Dialog from "@/src/components/atom/Dialog";
import DialogFooterContainer from "@/src/components/atom/Dialog/DialogFooterContainer";
import Typo from "@/src/components/atom/Typo";
import React, { useEffect, useState } from "react";
import { css, styled } from "styled-components";
import useAlert from "@/src/hooks/useAlert";
import { useForm } from "react-hook-form";
import CancelAlertDialog from "@/src/components/molecule/CancelAlertDialog";
import FormItem from "@/src/components/molecule/FormItem";
import colorSet, { ColorType } from "@/src/styles/color";
import Icon from "@/src/components/atom/Icon";
import InfoSvg from "@/src/assets/icons/icon-info-gray.svg";
import { useCreateBlMutation } from "@/src/store/apis/shipments/shipmentDetail";
import { useParams } from "react-router-dom";
import { BlMediaFileDto } from "@/src/store/apis/shipments/shipmentDetail/interface";
import { MediaDto } from "@/src/store/apis/media/interface";
import { ReactComponent as DownloadSvg } from "@/src/assets/icons/icon-download-black.svg";
import { ReactComponent as PreviewSvg } from "@/src/assets/icons/icon-preview-black.svg";
import { downloadFile } from "@/src/utils/downloadFile";
import { useTranslation } from "react-i18next";
import { isValidUrl } from "@/src/utils/is";
import { sanitizeUrl } from "@/src/utils/sanitizeUrl";
import { decodeHtml, encodeHtml } from "@/src/utils/transform";

interface RegisterBlDialogProps {
  open: boolean;
  onClose: (value?: boolean) => void;
  type: "REGISTER" | "EDIT";
  draftBlFile?: BlMediaFileDto;
  finalBlFile?: BlMediaFileDto;
  blNo?: string;
  blStatus?: "DRAFT" | "FINAL";
  shippingLine: string;
  shippingLineUrl?: string;
}

function RegisterBlDialog({
  open,
  onClose,
  type,
  blNo,
  draftBlFile,
  finalBlFile,
  blStatus,
  shippingLine,
  shippingLineUrl,
}: RegisterBlDialogProps) {
  const { t } = useTranslation();
  const alert = useAlert();
  const params = useParams();
  const isEditModal = type === "EDIT";

  const [createBl] = useCreateBlMutation();
  const [isCancelAlertOpen, setIsCancelAlertOpen] = useState(false);
  const { watch, control, setFocus, setValue, getValues } = useForm<{
    documentType: "DRAFT" | "FINAL";
    blNo: string;
    draftBlFile: MediaDto[] | undefined;
    finalBlFile: MediaDto[] | undefined;
    shippingLine: string;
    url: string;
  }>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: {
      documentType: blStatus ?? "DRAFT",
      draftBlFile: draftBlFile
        ? [
            {
              id: draftBlFile.mediaId,
              originalFileName: draftBlFile.mediaOriginalFileName,
              mediaUrl: draftBlFile.mediaUrl,
            },
          ]
        : [],
      finalBlFile: finalBlFile
        ? [
            {
              id: finalBlFile.mediaId,
              originalFileName: finalBlFile.mediaOriginalFileName,
              mediaUrl: finalBlFile.mediaUrl,
            },
          ]
        : [],
      blNo: blNo ?? "",
      shippingLine: shippingLine ?? "",
      url: shippingLineUrl
        ? decodeHtml(shippingLineUrl)
            .replace(/&#47;/g, "/")
            .replace(/&#59;/g, ";")
            .replace(/&#183;/g, ".")
        : "",
    },
  });

  const handleSubmitClick = async () => {
    const {
      documentType,
      blNo,
      url,
      draftBlFile: draftBlFileList,
      finalBlFile: finalBlFileList,
    } = getValues();

    // URL 검증
    if (url && !isValidUrl(sanitizeUrl(url))) {
      alert.showAlert({
        type: "error",
        message: t("alert:invalidUrl"),
      });
      return;
    }

    // URL 처리 로직
    let processedUrl;
    if (url) {
      processedUrl = encodeHtml(sanitizeUrl(url));
    } else if (isEditModal) {
      processedUrl = null;
    } else {
      processedUrl = undefined;
    }

    const blMedias: {
      documentType: "BL_DRAFT" | "BL_FINAL";
      mediaId: number;
    }[] = [];

    const draftBlFile = draftBlFileList?.[0];
    const finalBlFile = finalBlFileList?.[0];
    const blMediaId =
      documentType === "DRAFT" ? draftBlFile?.id : finalBlFile?.id;

    if (draftBlFile) {
      blMedias.push({
        documentType: "BL_DRAFT",
        mediaId: draftBlFile.id,
      });
    }

    if (finalBlFile) {
      blMedias.push({
        documentType: "BL_FINAL",
        mediaId: finalBlFile.id,
      });
    }
    try {
      await createBl({
        id: Number(params.id),
        blStatus: documentType === "DRAFT" ? "BL_DRAFT" : "BL_FINAL",
        blNo: blNo || (isEditModal ? null : undefined),
        shippingLineUrl: processedUrl,
        blMediaId,
        blMedias,
      }).unwrap();
      alert.showAlert({
        type: "success",
        message: t("alert:saveSuccess"),
      });
      onClose?.();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const renderBlFileContainer = (
    label: string,
    key: "draftBlFile" | "finalBlFile"
  ) => {
    return (
      <FileFormItemContainer>
        <StyledFormItem
          type="file"
          label={
            <FileFormItemLabel>
              <Typo>{label}</Typo>
            </FileFormItemLabel>
          }
          name={key}
          control={control as any}
          inputContainerClassName="file-input-container"
          inputProps={{
            hideButton: (watch(key)?.length ?? 0) > 0,
            defaultFileList: watch(key)?.length
              ? [new File([""], watch(key)?.[0].originalFileName ?? "")]
              : undefined,
            onRemove: () => setValue(key, []),
          }}
        />
        {(watch(key)?.length ?? 0) > 0 && (
          <FilePreviewAndDownloadButtonContainer>
            <IconButton
              buttonSize={32}
              buttonColor="black"
              buttonGrade="tertiary"
              disabled={!watch(key)?.[0].mediaUrl}
              onClick={() => {
                window.open(watch(key)?.[0].mediaUrl, "_blank");
              }}
            >
              <PreviewIcon disabled={!watch(key)?.[0].mediaUrl} />
            </IconButton>
            <IconButton
              buttonSize={32}
              buttonColor="black"
              buttonGrade="tertiary"
              disabled={!watch(key)?.[0].mediaUrl}
              onClick={() => {
                if (watch(key)?.[0].mediaUrl) {
                  downloadFile(
                    watch(key)?.[0].mediaUrl ?? "",
                    watch(key)?.[0].originalFileName ?? ""
                  );
                }
              }}
            >
              <DownloadIcon disabled={!watch(key)?.[0].mediaUrl} />
            </IconButton>
          </FilePreviewAndDownloadButtonContainer>
        )}
      </FileFormItemContainer>
    );
  };

  useEffect(() => {
    setFocus("blNo");
  }, [setFocus]);

  return (
    <Dialog
      title={type === "REGISTER" ? t("common:registerBL") : t("common:editBL")}
      width={496}
      open={open}
      onOpenChange={onClose}
      destroyDialogWhenEscapePress={false}
      onEscapeKeyDown={() => setIsCancelAlertOpen(true)}
      footer={
        <DialogFooterContainer>
          <Button
            buttonGrade="tertiary"
            buttonColor="black"
            onClick={() => setIsCancelAlertOpen(true)}
          >
            <Typo typoType="btn3m">
              {t("shipment:exporter.detail.button.cancelButton")}
            </Typo>
          </Button>
          <Button onClick={handleSubmitClick}>
            <Typo typoType="btn3m" color="white">
              {t("shipment:exporter.detail.button.saveButton")}
            </Typo>
          </Button>
        </DialogFooterContainer>
      }
    >
      {/* 취소 Alert */}
      {isCancelAlertOpen && (
        <CancelAlertDialog
          open={isCancelAlertOpen}
          onOpenChange={setIsCancelAlertOpen}
          onOk={() => onClose(false)}
        />
      )}

      <Form>
        <FormItem
          label={t("common:blNo")}
          type="text"
          name="blNo"
          direction="vertical"
          control={control}
          inputProps={{
            placeholder: t("placeholder:blNo"),
            onBlur: (e) => {
              setValue("blNo", e.target.value.toUpperCase());
            },
          }}
        />

        <FormItem
          label={t("common:documentType")}
          type="radioGroup"
          name="documentType"
          direction="vertical"
          control={control}
          options={[
            {
              label: t("common:draft"),
              value: "DRAFT",
            },
            {
              label: t("common:final"),
              value: "FINAL",
            },
          ]}
        />

        <FormItem
          label={t("common:shippingLine")}
          type="text"
          name="shippingLine"
          direction="vertical"
          control={control}
          inputProps={{ placeholder: "-", disabled: true }}
        />

        <FormItem
          label="URL"
          type="text"
          name="url"
          direction="vertical"
          control={control}
          inputProps={{ placeholder: t("placeholder:enterURL") }}
        />

        <div>
          <StyledSectionCardValue>
            <BlFileLabelAndNotice>
              <Typo>{t("common:BLDocument")}</Typo>
              <Notice>
                <StyledIcon iconSrc={InfoSvg} />
                <Typo typoType="b9r" color="gray6">
                  {t("common:BLDocumentDescription")}
                </Typo>
              </Notice>
            </BlFileLabelAndNotice>
            <HorizontalDivider />

            {renderBlFileContainer(t("common:draft"), "draftBlFile")}
            {renderBlFileContainer(t("common:final"), "finalBlFile")}
          </StyledSectionCardValue>
        </div>
      </Form>
    </Dialog>
  );
}

export default RegisterBlDialog;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const StyledSectionCardValue = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid ${colorSet.gray9};
  padding: 16px;
  gap: 16px;
`;

const Notice = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`;

const StyledIcon = styled(Icon)`
  flex-shrink: 0;
`;

const BlFileLabelAndNotice = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const HorizontalDivider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${colorSet.gray9};
`;

const StyledFormItem = styled(FormItem)`
  & .file-input-container {
    justify-content: center;
  }
`;

const FileFormItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FileFormItemLabel = styled.div`
  flex-shrink: 0;
  width: 42px;
  height: 32px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 8px;
`;

const FilePreviewAndDownloadButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const DownloadIcon = styled(DownloadSvg)<{
  color?: ColorType;
  disabled?: boolean;
  size?: number;
}>`
  width: ${({ size }) => (size ? `${size}px` : "20px")};
  height: ${({ size }) => (size ? `${size}px` : "20px")};

  path {
    ${({ color }) =>
      color &&
      css`
        fill: ${colorSet[color]};
      `}

    ${({ disabled }) =>
      disabled &&
      css`
        fill: ${colorSet.gray8};
      `}
  }
`;

const PreviewIcon = styled(PreviewSvg)<{
  color?: ColorType;
  disabled?: boolean;
}>`
  width: 20px;
  height: 20px;

  path {
    ${({ color }) =>
      color &&
      css`
        fill: ${colorSet[color]};
      `}

    ${({ disabled }) =>
      disabled &&
      css`
        fill: ${colorSet.gray8};
      `}
  }
`;
