import { keyframes } from "styled-components";

export const down = (h: string) => keyframes`
  0% {
    height: 0px;
  }
  100% {
    height: var(${h});
  }
`;

export const up = (h: string) => keyframes`
0% {
    height: var(${h});
  }
  100% {
    height: 0px;
  }
`;

export const exporterSideNavSlideDown = keyframes`
  0% {
    opacity: 0;
    height: 0;
    padding-bottom: 0;
    background: none;
  }
  100% {
    opacity: 1;
    height: var(--radix-accordion-content-height);  
    padding-bottom: 12px;
    background: rgba(0, 0, 0, 0.25);
  }
`;

export const exporterSideNavSlideUp = keyframes`
  0% {
    opacity: 1;
    height: var(--radix-accordion-content-height);
    padding-bottom: 12px;
    background: rgba(0, 0, 0, 0.25);
  }
  100% {
    height: 0;
    opacity: 0;
    padding-bottom: 0;
    background: none;
  }
`;

export const importerSideNavSlideDown = keyframes`
    0% {
        opacity: 0;
        height: 0;
        padding-bottom: 0;
        background: none;
    }
    100% {
        opacity: 1;
        height: var(--radix-accordion-content-height);
        padding-bottom: 12px;
        background: #0000001A;
    }
`;

export const importerSideNavSlideUp = keyframes`
    0% {
        opacity: 1;
        height: var(--radix-accordion-content-height);
        padding-bottom: 12px;
        background: #0000001A;
    }
    100% {
        height: 0;
        opacity: 0;
        padding-bottom: 0;
        background: none;
    }
`;

export const slideDown = keyframes`
    0% {
      height: 0;
    }
    100% {
      height: var(--radix-accordion-content-height);
    }
`;

export const slideUp = keyframes`
    0% {
      height: var(--radix-accordion-content-height);
    }
    100% {
      height: 0;
    }
`;
