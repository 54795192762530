export const sanitizeUrl = (url: string): string => {
  const sanitized = url.trim();
  if (!sanitized) return "";

  // 허용된 프로토콜만 통과
  if (!sanitized.startsWith("http://") && !sanitized.startsWith("https://")) {
    return `https://${sanitized}`;
  }
  return sanitized;
};
