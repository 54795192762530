import React from "react";
import colorSet from "@/src/styles/color";
import styled from "styled-components";
import Typo from "@/src/components/atom/Typo";
import Icon from "@/src/components/atom/Icon";
import ClockSvg from "@/src/assets/icons/icon-clock-gray5.svg";
import ChevronRightSvg from "@/src/assets/icons/icon-chevron-right.svg";
import IconButton from "@/src/components/atom/Button/IconButton";
import { renderNoRowsComponent } from "@/src/components/atom/Table";
import {
  useUpdateAlarmMutation,
  useGetAlarmsQuery,
} from "@/src/store/apis/alarms";
import useAlert from "@/src/hooks/useAlert";
import { useNavigate } from "react-router-dom";
import getDateDifference from "@/src/utils/getDateDifference";
import { ContainerStatusType } from "@/src/store/apis/tasks/taskDetail/interface";
import { PulseLoader } from "react-spinners";
import { isUndefined } from "@/src/utils/is";
import { AlarmDto } from "@/src/store/apis/alarms/interface";
import BookmarkSvg from "@/src/assets/icons/icon-bookmark.svg";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import { renderLoadingStatusBadge } from "../../Task/utils/renderTaskStatusBadge";
import { useTranslation } from "react-i18next";

const emptyArray: AlarmDto[] = [];

function RecentActivitiesCard() {
  const { t, i18n } = useTranslation();
  const alert = useAlert();
  const navigate = useNavigate();
  const [updateAlarm] = useUpdateAlarmMutation();

  const { loadingAlramData, isFetching, isUnstable } = useGetAlarmsQuery(
    {
      service: "EXPORT_WEB",
      serviceDetail: "DASHBOARD",
      domainType: "TASK",
      isRead: false,
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isUndefined(currentData) || isError || isFetching;

        return {
          isUnstable,
          isFetching,
          loadingAlramData: !isUnstable
            ? currentData?.rows ?? emptyArray
            : emptyArray,
        };
      },
    }
  );

  const loadingAlaramList = loadingAlramData.map(
    ({
      status,
      titleKr,
      titleEn,
      bodyKr,
      createdAt,
      domainId,
      id,
      isBookmarked,
    }) => {
      return {
        alramId: id,
        loadingId: domainId,
        loadingStatus: status as ContainerStatusType,
        loadingTitle: i18n.language === "ko" ? titleKr : titleEn,
        loadingNumber: bodyKr,
        createdAt,
        isBookmarked,
      };
    }
  );
  const handleCardClick = async (params: {
    alramId: number;
    taskId: number;
  }) => {
    const { alramId, taskId } = params;

    try {
      await updateAlarm({ id: alramId, isRead: true }).unwrap();

      navigate(`${EXPORTER_PRIVATE_PATH.LOADING_DETAIL}/${taskId}`);
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const renderRecentActivitiesCard = () => {
    if (isFetching) {
      return (
        <LoaderContainer>
          <PulseLoader color={colorSet.blue4} />
        </LoaderContainer>
      );
    }

    if (isUnstable) {
      return renderNoRowsComponent();
    }

    return (
      <RecentActivitiesCardContainer>
        {loadingAlaramList.map(
          (
            {
              loadingStatus,
              loadingNumber,
              loadingTitle,
              createdAt,
              loadingId,
              alramId,
              isBookmarked,
            },
            idx
          ) => {
            return (
              <RecentActivitiesCardItem
                key={idx.toString()}
                onClick={() => handleCardClick({ alramId, taskId: loadingId })}
              >
                <TaskInfoContainer>
                  <TaskInfo>
                    <BadgeContainer>
                      {renderLoadingStatusBadge({
                        type: loadingStatus,
                        t,
                        size: "S",
                      })}
                      {isBookmarked && (
                        <Icon iconSrc={BookmarkSvg} iconSize={20} />
                      )}
                    </BadgeContainer>

                    <Typo typoType="btn2m">{loadingTitle ?? "-"}</Typo>
                    <Typo typoType="b9r" color="gray5">
                      {loadingNumber ?? "-"}
                    </Typo>
                  </TaskInfo>

                  <Time>
                    <Icon iconSrc={ClockSvg} iconSize={16} />
                    <Typo typoType="b9m" color="gray5">
                      {getDateDifference({ updateAt: createdAt })}
                    </Typo>
                  </Time>
                </TaskInfoContainer>

                <IconButton
                  buttonColor="black"
                  buttonGrade="tertiary"
                  buttonSize={32}
                >
                  <Icon iconSrc={ChevronRightSvg} iconSize={16} />
                </IconButton>
              </RecentActivitiesCardItem>
            );
          }
        )}
      </RecentActivitiesCardContainer>
    );
  };

  return renderRecentActivitiesCard();
}

export default RecentActivitiesCard;

const RecentActivitiesCardContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const RecentActivitiesCardItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  gap: 8px;
  width: 292px;
  padding: 16px 16px 16px 24px;
  border: 1px solid ${colorSet.gray9};
  border-radius: 8px;
  transition: box-shadow 70ms ease-in-out;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.2);
  }
`;

const BadgeContainer = styled.figure`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const TaskInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 214px;

  span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

const TaskInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Time = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
`;
