import JSZip from "jszip";
import { addExifMetadataToImage } from "./imageMetadataUtils";

/**
 * 이미지 정보를 담는 인터페이스
 */
export interface ImageItem {
  mediaUrl: string;
  originalFileName: string;
  latitude?: number;
  longitude?: number;
  zoomLevel?: number;
}

/**
 * 폴더 정보를 담는 인터페이스
 */
export interface Folder {
  folderName: string;
  images: ImageItem[];
}

/**
 * 주어진 데이터를 기반으로 압축 파일을 생성하고 다운로드하는 함수
 * @param packageName - 압축 파일명 (패키지 이름)
 * @param folderList - 폴더 배열
 *   각 폴더는 폴더명을 가지며, 이미지 목록을 포함
 * @returns Promise<void>
 */
export const createZipWithImages = async (
  packageName: string,
  folderList: Folder[]
) => {
  const zip = new JSZip();

  for (const folderItem of folderList) {
    const folder = zip.folder(folderItem.folderName); // 폴더 생성

    const imagePromises = folderItem.images.map(async (image: ImageItem) => {
      const { mediaUrl, originalFileName, latitude, longitude } = image;

      // 이미지 다운로드 및 JPG 변환
      const blob = await fetch(mediaUrl, {
        mode: "cors", // CORS 문제 해결
        headers: { "Cache-Control": "no-cache" }, // 캐시 무효화를 위한 헤더
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(`Failed to fetch image: ${res.statusText}`);
          }
          return res.blob(); // Blob으로 변환하여 반환
        })
        .then((blob) => addExifMetadataToImage(blob, latitude, longitude)) // EXIF 메타데이터 추가
        .catch((error) => {
          console.error("Error fetching image:", error);
          throw error;
        });

      // 파일 이름에 확장자가 없으면 .jpeg 확장자 추가
      let fileName = originalFileName;
      if (!/\.[^/.]+$/.test(fileName)) {
        fileName += ".jpg"; // 확장자가 없으면 추가
      }

      folder?.file(fileName, blob, { binary: true }); // 폴더에 파일 추가
    });

    await Promise.all(imagePromises); // 모든 이미지 다운로드 및 추가
  }

  // 압축 파일 생성
  const zipBlob = await zip.generateAsync({ type: "blob" });

  // 압축 파일 다운로드
  const link = document.createElement("a");
  link.href = URL.createObjectURL(zipBlob);
  link.setAttribute("download", `${packageName}.zip`); // 파일명 설정
  document.body.appendChild(link);
  link.click();

  // 다운로드 후 리소스 정리
  document.body.removeChild(link);
  URL.revokeObjectURL(link.href);
};
