import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-build/build/ckeditor";
import { uploadPlugin } from "./ImageUploader";

interface EditorProps {
  value: string;
  onChange?: (event: string) => void;
  placeholder?: string;
  disabled?: boolean;
  minHeight?: number;
  height?: number;
  /**
   * @description Editor 데이터 읽기용
   */
  isReadOnly?: boolean;
}

function Editor({
  value,
  onChange,
  placeholder,
  minHeight = 200,
  height,
  disabled,
  isReadOnly = false,
}: EditorProps) {
  const editorConfig = {
    extraPlugins: [uploadPlugin],
    alignment: {
      options: ["left", "center", "right"],
    },
    fontSize: {
      options: [9, 11, 13, 15, 17, 19, 21],
    },
    heading: {
      options: [
        {
          model: "paragraph",
          title: "Paragraph",
          class: "ck-heading_paragraph",
        },
        {
          model: "heading1",
          view: "h1",
          title: "Heading 1",
          class: "ck-heading_heading1",
        },
        {
          model: "heading2",
          view: "h2",
          title: "Heading 2",
          class: "ck-heading_heading2",
        },
        {
          model: "heading3",
          view: "h3",
          title: "Heading 3",
          class: "ck-heading_heading3",
        },
        {
          model: "heading4",
          view: "h4",
          title: "Heading 4",
          class: "ck-heading_heading4",
        },
      ],
    },
    toolbar: {
      items: [
        "heading",
        "|",
        "fontSize",
        "fontColor",
        "fontBackgroundColor",
        "|",
        "bold",
        "italic",
        "underline",
        "link",
        "|",
        "bulletedList",
        "numberedList",
        "|",
        "alignment",
        "outdent",
        "indent",
        "|",
        "imageUpload",
        "insertTable",
        "|",
        "undo",
        "redo",
      ],
      shouldNotGroupWhenFull: true,
    },

    placeholder: placeholder ?? "placeholder",
  };

  const readOnlyEditorConfig = {
    ...editorConfig,
    toolbar: {
      items: [],
    },
  };

  return (
    <article
      style={
        {
          "--ck-editor-min-height": `${minHeight}px`,
          "--ck-editor-height": `${height}px`,
        } as React.CSSProperties
      }
    >
      <CKEditor
        editor={ClassicEditor as any}
        config={isReadOnly ? readOnlyEditorConfig : editorConfig}
        data={value}
        disabled={disabled || isReadOnly}
        onChange={(_event: any, editor: any) => {
          onChange && onChange(editor.getData());
        }}
        onReady={(editor: any) => {
          const toolbarElement = editor.ui.view.toolbar.element;
          // isReadOnly Tool Bar
          if (isReadOnly) {
            toolbarElement.style.display = "none";
          } else {
            toolbarElement.style.display = "flex";
          }

          editor.editing.view.change(
            (writer: {
              setStyle: (arg0: string, arg1: string, arg2: any) => void;
            }) => {
              if (isReadOnly) {
                writer.setStyle(
                  "border-radius",
                  `8px`,
                  editor.editing.view.document.getRoot()
                );
                writer.setStyle(
                  "border",
                  "none",
                  editor.editing.view.document.getRoot()
                );
              }
            }
          );
        }}
      />
    </article>
  );
}

export default Editor;
