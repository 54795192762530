import { useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { useForm } from "react-hook-form";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Button } from "@/src/components/atom/Button";
import Icon from "@/src/components/atom/Icon";
import Loader from "@/src/components/atom/Loader";
import Typo from "@/src/components/atom/Typo";
import AddressFormItem from "@/src/components/molecule/AddressFormItem";
import ContactFormItem from "@/src/components/molecule/ContactFormItem";
import FormItem, { FormItemProps } from "@/src/components/molecule/FormItem";
import FormInputPropsWithInputType from "@/src/components/molecule/FormItem/type";
import SectionCard from "@/src/components/molecule/SectionCard";
import ExporterMainLayout from "@/src/components/template/Layout/exporter/ExporterMainLayout";
import { useGetCommonCodeViaCodeNameQuery } from "@/src/store/apis/common";
import { mediaQuery } from "@/src/styles/mediaQuery";
import typo from "@/src/styles/typography";
import colorSet, { ColorType } from "@/src/styles/color";
import AlertDialog from "@/src/components/atom/AlertDialog";
import { useCreateBuyerMutation } from "@/src/store/apis/client/buyer";
import useAlert from "@/src/hooks/useAlert";
import { GenerateBuyerEmployeeDto } from "@/src/store/apis/client/buyer/interface";
import Table from "@/src/components/atom/Table";
import { addBuyerContactPersonListColumn } from "./columns/column";
import { createDeleteAction, editAction } from "@/src/utils/row-data-util";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import { useNavigate } from "react-router-dom";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import AddSvg from "@/src/assets/icons/icon-add-black.svg";
import ChevronLeftSvg from "@/src/assets/icons/icon-chevron-left-black.svg";
import { ReactComponent as InfoSvg } from "@/src/assets/icons/icon-info-gray6.svg";
import BottomFixedContainer from "@/src/components/molecule/BottomFixedContainer";
import BuyerEmployeeAddDialog from "./dialog/BuyerEmployeeAddDialog";
import BuyerEmployeeEditDialog from "./dialog/BuyerEmployeeEditDialog";
import { useTranslation } from "react-i18next";
import ClientIntegrationInviteCodeDialog from "@/src/components/organism/ClientIntegrationInviteCodeDialog";
import { useAppSelector } from "@/src/store";
import { useSendCompanyLinkCodeMutation } from "@/src/store/apis/corporate/company";
import { useGetSessionQuery } from "@/src/store/apis/auth";
import { isUndefined } from "@/src/utils/is";

type BasicInformationType = {
  infoCountry: string;
  buyerName: string;
  buyerNameCode: string;
  businessRegistration: any;
  businessNumber: string;
  mainCategoryCodeItemNames: string[];
  tradedItems: any;
  apartment: string;
  city: string;
  state: string;
  postal: string;
  country: string;
  contactPrefix: string;
  contact: string;
  faxPrefix: string;
  fax: string;
};

enum AlertDialogState {
  NULL,
  REGISTER_CONTACT_PERSON,
  BACK_TO_PREVIOUS,
}

enum DialogState {
  NULL,
  ADD,
  EDIT,
  INVITE_CODE,
}

const ExporterClientManagementAddPage = () => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.auth.user);
  const {
    control,
    register,
    watch,
    setValue,
    handleSubmit,
    getValues,
    formState: { errors },
    clearErrors,
    setFocus,
  } = useForm<BasicInformationType>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: {
      infoCountry: "",
      buyerName: "",
      buyerNameCode: "",
      businessRegistration: [],
      businessNumber: "",
      mainCategoryCodeItemNames: [],
      tradedItems: "",
      apartment: "",
      city: "",
      state: "",
      postal: "",
      country: "",
      contactPrefix: "",
      contact: "",
      faxPrefix: "",
      fax: "",
    },
  });

  const alert = useAlert();
  const navigate = useNavigate();
  const submitButtonRef = useRef<HTMLButtonElement | null>(null);
  const agGridRef = useRef<AgGridReact>(null);
  const [dialogState, setDialogState] = useState<DialogState>(DialogState.NULL);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState<AlertDialogState>(
    AlertDialogState.NULL
  );
  const [editedPersonUuid, setEditedPersonUuid] = useState<string | null>(null);
  const [columnDefs] = useState<ColDef[]>(addBuyerContactPersonListColumn);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [contactPersonList, setContactPersonList] = useState<
    (GenerateBuyerEmployeeDto & { uniqueId: string })[]
  >([]);

  // API
  const { exporterUserType, userMainCategory } = useGetSessionQuery(undefined, {
    refetchOnMountOrArgChange: true,
    selectFromResult: ({ currentData, isError, isFetching }) => {
      const isUnstable = isError || isFetching || isUndefined(currentData);
      const isStable = !isUnstable;

      return {
        userMainCategory: isStable
          ? currentData.row.mainCategoryCodeItemNames
          : [],
        exporterUserType: currentData?.row.exporterUserType,
      };
    },
  });
  const [sendCompanyLinkCode, { isLoading: isSendLinkCodeLoading }] =
    useSendCompanyLinkCodeMutation();
  const { isFetching, countryCodeList } = useGetCommonCodeViaCodeNameQuery(
    {
      codeName: "COUNTRY",
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ isFetching, currentData }) => ({
        isFetching,
        countryCodeList: currentData ?? [],
      }),
    }
  );
  const { isMainCategoryFetching, mainCategory } =
    useGetCommonCodeViaCodeNameQuery(
      {
        codeName: "MAIN_CATEGORY",
      },
      {
        refetchOnMountOrArgChange: true,
        selectFromResult: ({ currentData, isError, isFetching }) => {
          const isErrorAndUndefined = isError || currentData === undefined;

          return {
            isMainCategoryFetching: isFetching,
            mainCategory: !isErrorAndUndefined ? currentData ?? [] : [],
          };
        },
      }
    );

  const [registerBuyer] = useCreateBuyerMutation();

  const countryCodeListOption = countryCodeList.reduce<
    { label: string; value: string }[]
  >((acc, val) => {
    return [
      ...acc,
      {
        label: `${val.codeItemName}_${val.codeItemNameEn}`,
        value: val.codeItemNameKo,
      },
    ];
  }, []);

  const mainCategoryOptionList = mainCategory
    .filter((filterItem) => {
      // 일반매니저, 중간관리자가 아닌 경우 필터링 없이 그대로 유지
      return exporterUserType === "MANAGER" ||
        exporterUserType === "MIDDLE_MANAGER"
        ? userMainCategory.includes(filterItem.codeItemName)
        : true;
    })
    .map((item) => {
      return {
        label: item.codeItemNameEn,
        value: item.codeItemName,
      };
    });

  useAgGridHeaderRefresh({
    gridRef: agGridRef.current,
    isReady,
    headerSet: [
      { columnKey: "name", langKey: "clientManagement:exporter.common.name" },
      { columnKey: "email", langKey: "clientManagement:exporter.common.email" },
      {
        columnKey: "personalContact",
        langKey: "clientManagement:exporter.common.personalContact",
      },
      {
        columnKey: "officeContact",
        langKey: "clientManagement:exporter.common.officeContact",
      },
      {
        columnKey: "buyerEmployeeRemark",
        langKey: "clientManagement:exporter.common.remark",
      },
      {
        columnKey: "isActivated",
        langKey: "clientManagement:exporter.common.use",
      },
      {
        columnKey: "edit",
        langKey: "clientManagement:exporter.common.edit",
      },
      {
        columnKey: "delete",
        langKey: "clientManagement:exporter.common.delete",
      },
    ],
  });

  const basicInformationFormItemList: (FormItemProps<
    BasicInformationType,
    keyof BasicInformationType
  > &
    FormInputPropsWithInputType)[] = [
    {
      label: t("clientManagement:exporter.common.clientName"),
      type: "text",
      name: "buyerName",
      control: control,
      rules: { required: true },
      errorsMessage: {
        required: t("error:required"),
      },
      inputProps: {
        placeholder: t("clientManagement:exporter.placeholder.client"),
        onBlur: (e) => {
          if (!getValues("buyerNameCode")) {
            setValue("buyerNameCode", e.target.value);
            clearErrors("buyerNameCode");
          }
        },
      },
    },
    {
      label: t("clientManagement:exporter.common.clientCode"),
      type: "text",
      name: "buyerNameCode",
      control: control,
      rules: { required: true },
      errorsMessage: {
        required: t("error:required"),
      },
      inputProps: {
        placeholder: t("clientManagement:exporter.placeholder.clientCode"),
      },
    },
    {
      label: t("clientManagement:exporter.common.country"),
      type: "singleSelect",
      name: "infoCountry",
      control: control,
      options: countryCodeListOption ?? [],
      rules: { required: true },
      errorsMessage: {
        required: t("error:required"),
      },
      inputProps: {
        suffixIcon: isFetching ? <Loader /> : undefined,
        disabled: isFetching,
        placeholder: t("clientManagement:exporter.placeholder.country"),
        onChange: (value) => {
          const prefix = countryCodeList.find(
            ({ codeItemNameKo }) => codeItemNameKo === value
          )?.value1;
          setValue("infoCountry", value);
          setValue("contactPrefix", prefix || "");
          setValue("faxPrefix", prefix || "");
        },
      },
    },
    {
      label: t("clientManagement:exporter.common.categoryInCharge"),
      type: "multipleSelect",
      name: "mainCategoryCodeItemNames",
      rules: { required: true },
      errorsMessage: {
        required: t("error:required"),
      },
      control: control,
      options: mainCategoryOptionList,
      inputProps: {
        placeholder: t(
          "clientManagement:exporter.placeholder.categoryInCharge"
        ),
        suffixIcon: isMainCategoryFetching ? <Loader /> : undefined,
        disabled: isMainCategoryFetching,
        onRemoveItem: (item) => {
          setValue(
            "mainCategoryCodeItemNames",
            getValues("mainCategoryCodeItemNames").filter(
              (mainCategoryItem) => mainCategoryItem !== item
            )
          );
        },
      },
    },
    {
      label: t("clientManagement:exporter.common.businessNumber"),
      type: "text",
      name: "businessNumber",
      control: control,
      inputProps: {
        placeholder: t("clientManagement:exporter.placeholder.businessNumber"),
      },
    },
    {
      label: t("clientManagement:exporter.common.businessRegistration"),
      type: "file",
      name: "businessRegistration",
      control: control,
      inputProps: {
        defaultFileList: watch("businessRegistration").length
          ? [
              new File(
                [""],
                watch("businessRegistration")?.[0]?.originalFileName ?? ""
              ),
            ]
          : undefined,
        onRemove: () => setValue("businessRegistration", []),
      },
    },
  ];

  // 거래처 생성
  const clientRegister = async () => {
    const registerInfoValues = getValues();
    const countryCodeItemName =
      countryCodeList.find(
        ({ codeItemNameKo }) =>
          codeItemNameKo === registerInfoValues.infoCountry
      )?.codeItemName ?? "";

    try {
      const validBodyData = Object.entries({
        telPrefix: registerInfoValues.contactPrefix || undefined,
        faxPrefix: registerInfoValues.faxPrefix || undefined,
        tel: registerInfoValues.contact || undefined,
        fax: registerInfoValues.fax || undefined,
      })
        .filter(([_key, value]) => value !== undefined) // undefined인 값 필터링
        .reduce<any>((acc, [key, value]) => {
          acc[key] = value; // 유효한 값만 추가
          return acc;
        }, {});

      const { row } = await registerBuyer({
        countryCodeItemName,
        companyType: "BUYER",
        buyerName: registerInfoValues.buyerName,
        buyerNameCode: registerInfoValues.buyerNameCode,
        isActivated: true,
        buyerCountryName: registerInfoValues.country,
        mainCategoryCodeItemNames: registerInfoValues.mainCategoryCodeItemNames,
        buyerRegion: registerInfoValues.state || undefined,
        buyerLocality: registerInfoValues.city || undefined,
        buyerStreetAddress: registerInfoValues.apartment || undefined,
        buyerPostalCode: registerInfoValues.postal || undefined,
        buyerEmployees: contactPersonList,
        businessNumber: registerInfoValues.businessNumber || undefined,
        businessRegistrationMediaId: registerInfoValues.businessRegistration
          .length
          ? registerInfoValues.businessRegistration[0].id
          : null,
        ...validBodyData,
      }).unwrap();

      navigate(
        `${EXPORTER_PRIVATE_PATH.CLIENT_MANAGEMENT_DETAIL}/${row.buyerQueryResultDto.id}`
      );
      alert.showAlert({ type: "success", message: t("alert:saveSuccess") });

      return row;
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  // 연동 코드 전송
  const handleClientIntegrationInviteCode = async (
    data: GenerateBuyerEmployeeDto
  ) => {
    try {
      const res = await clientRegister();

      if (!!res) {
        await sendCompanyLinkCode({
          emailList: [data.email],
        });
        setDialogState(DialogState.NULL);
      }
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const handlerRegister = async () => {
    if (contactPersonList.length === 0) {
      setIsAlertDialogOpen(AlertDialogState.REGISTER_CONTACT_PERSON);
      return;
    }

    // 기업관리자 경우 링크코드 전송 모달
    if (user?.exporterUserType === "CORPORATE_MANAGER") {
      return setDialogState(DialogState.INVITE_CODE);
    }

    try {
      await clientRegister();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const handleAddContactPerson = (values: {
    name: string;
    email: string;
    personalContactPrefix?: string;
    personalContact?: string;
    officeContactPrefix?: string;
    officeContact?: string;
    isActivated: string;
    buyerEmployeeRemark?: string;
  }) => {
    const validData = Object.entries(values)
      .filter(([key, value]) => value)
      .reduce<any>((acc, [validKey, validValue]) => {
        return { ...acc, [validKey]: validValue };
      }, {});

    setContactPersonList((prev) => [
      ...prev,
      {
        ...validData,
        isActivated: values.isActivated === "Y",
        uniqueId: `${uuidv4()}`,
      },
    ]);
    setDialogState(DialogState.NULL);
  };

  const handleEditContactPerson = (editData: GenerateBuyerEmployeeDto) => {
    const validData = Object.entries(editData)
      .filter(([key, value]) => value)
      .reduce<any>((acc, [validKey, validValue]) => {
        return { ...acc, [validKey]: validValue };
      }, {});

    setContactPersonList((prev) =>
      prev.map((person) =>
        person.uniqueId === editedPersonUuid
          ? {
              uniqueId: person.uniqueId,
              isActivated: editData.isActivated,
              ...validData,
            }
          : person
      )
    );
    setDialogState(DialogState.NULL);
    setEditedPersonUuid(null);
  };

  const handleDetailClick = (data: any) => {
    setEditedPersonUuid(data.uniqueId);
    setDialogState(DialogState.EDIT);
  };

  const handleDeleteClick = (data: any) => {
    setContactPersonList((prev) =>
      prev.filter(({ uniqueId }) => uniqueId !== data.uniqueId)
    );
  };

  const renderDialog = () => {
    const closeDialog = () => setDialogState(DialogState.NULL);

    if (dialogState === DialogState.ADD) {
      return (
        <BuyerEmployeeAddDialog
          defaultCountryCode={
            countryCodeList.find(
              ({ codeItemNameKo }) => codeItemNameKo === watch("infoCountry")
            )?.value1
          }
          onSave={handleAddContactPerson}
          onClose={closeDialog}
        />
      );
    }

    if (dialogState === DialogState.EDIT && editedPersonUuid) {
      const editing = contactPersonList.find(
        ({ uniqueId }) => uniqueId === editedPersonUuid
      );
      if (!editing) return;

      return (
        <BuyerEmployeeEditDialog
          name={editing.name}
          email={editing.email}
          isActivated={editing.isActivated ? "Y" : "N"}
          personalContact={editing.personalContact}
          officeContact={editing.officeContact}
          personalContactPrefix={editing.personalContactPrefix}
          officeContactPrefix={editing.officeContactPrefix}
          buyerEmployeeRemark={editing.buyerEmployeeRemark}
          onSave={handleEditContactPerson}
          onClose={closeDialog}
        />
      );
    }

    // 기업관리자 경우 거래처 생성시 코드 발송 모달
    if (dialogState === DialogState.INVITE_CODE) {
      return (
        <ClientIntegrationInviteCodeDialog
          onClose={closeDialog}
          onSave={handleClientIntegrationInviteCode}
          personList={contactPersonList}
          isLoading={isSendLinkCodeLoading}
        />
      );
    }
  };

  const renderAlertDialog = () => {
    if (isAlertDialogOpen === AlertDialogState.NULL) return null;
    if (isAlertDialogOpen === AlertDialogState.REGISTER_CONTACT_PERSON) {
      return (
        <AlertDialog
          open
          title={t(
            "clientManagement:exporter.buyerListAddPage.alertDialog.registerOneContactPerson.title"
          )}
          onOpenChange={() => setIsAlertDialogOpen(AlertDialogState.NULL)}
          onOk={() => {
            setDialogState(DialogState.ADD);
            setIsAlertDialogOpen(AlertDialogState.NULL);
          }}
          okText={t("clientManagement:exporter.common.ok")}
          cancelText={t("clientManagement:exporter.common.exit")}
        >
          {t(
            "clientManagement:exporter.buyerListAddPage.alertDialog.registerOneContactPerson.description"
          )}
        </AlertDialog>
      );
    }
    if (isAlertDialogOpen === AlertDialogState.BACK_TO_PREVIOUS) {
      return (
        <AlertDialog
          open
          title={t(
            "clientManagement:exporter.buyerListAddPage.alertDialog.backToPrevious.title"
          )}
          onOpenChange={() => setIsAlertDialogOpen(AlertDialogState.NULL)}
          onOk={() => {
            navigate(-1);
          }}
          okText={t("clientManagement:exporter.common.ok")}
          cancelText={t("clientManagement:exporter.common.exit")}
        >
          {t(
            "clientManagement:exporter.buyerListAddPage.alertDialog.backToPrevious.description"
          )}
        </AlertDialog>
      );
    }
  };

  const contactPersonListToRowData = contactPersonList
    .map((person, idx) => {
      return {
        no: idx + 1,
        personalContact:
          person.personalContactPrefix && person.personalContact
            ? `${person.personalContactPrefix} ${person.personalContact}`
            : "-",
        officeContact:
          person.officeContactPrefix && person.officeContact
            ? `${person.officeContactPrefix} ${person.officeContact}`
            : "-",
        email: person.email,
        isActivated: person.isActivated,
        name: person.name,
        uniqueId: person.uniqueId,
        buyerEmployeeRemark: person.buyerEmployeeRemark,
      };
    })
    .filter((item, idx) => {
      const endIndex = pagination.page * pagination.pageSize - 1;
      const startIndex =
        pagination.page * pagination.pageSize - pagination.pageSize;
      return idx >= startIndex && idx <= endIndex;
    });

  useEffect(() => {
    setFocus("buyerName");
  }, [setFocus]);

  return (
    <ExporterMainLayout
      breadcrumb={[
        t("sideNav:clientManagement"),
        t("sideNav:clientManagementRegistration"),
      ]}
      pageTitle={t("sideNav:clientManagementRegistration")}
    >
      <SectionContainer>
        <SectionCard
          cardTitle={t("clientManagement:exporter.common.basicInformation")}
        >
          <CallOut>
            <InfoIcon color="gray6" />
            <Typo typoType="b9r" color="gray6">
              {t("clientManagement:exporter.common.callout")}
            </Typo>
          </CallOut>
          <StyledForm onSubmit={handleSubmit(handlerRegister)}>
            <HalfDiv>
              {basicInformationFormItemList.map(
                ({
                  label,
                  type,
                  name,
                  control,
                  options,
                  rules,
                  inputProps,
                  disabled,
                  errorsMessage,
                }) => {
                  return (
                    <StyledFormItem
                      label={label}
                      type={type as any}
                      name={name as keyof BasicInformationType}
                      control={control as any}
                      options={options ?? []}
                      inputProps={inputProps as any}
                      rules={rules}
                      disabled={disabled}
                      errorsMessage={errorsMessage}
                    />
                  );
                }
              )}
            </HalfDiv>
            <HalfDiv>
              <StyledAddressFormItem
                label={t("signupCorp:content.address")}
                register={register as any}
                isRequired
                setValue={setValue as any}
                error={errors}
                clearErrors={clearErrors}
              />
              <StyledContactFormItem
                label={t("signupCorp:content.contact")}
                prefixName="contactPrefix"
                register={register as any}
                setValue={setValue as any}
                restContactName="contact"
                prefixValue={watch("contactPrefix") || undefined}
                isRequired={false}
              />
              <StyledContactFormItem
                label={t("clientManagement:exporter.common.fax")}
                prefixName="faxPrefix"
                register={register as any}
                setValue={setValue as any}
                restContactName="fax"
                prefixValue={watch("faxPrefix") || undefined}
                isRequired={false}
              />
            </HalfDiv>
            <Hidden type="submit" ref={submitButtonRef} />
          </StyledForm>
        </SectionCard>
        <SectionCard
          cardTitle={t("clientManagement:exporter.common.contactPersonList")}
          rightAccessory={
            <StyledButton
              buttonGrade="tertiary"
              buttonSize={32}
              buttonColor="black"
              onClick={() => setDialogState(DialogState.ADD)}
            >
              <Icon iconSrc={AddSvg} iconSize={16} />
              <Typo typoType="btn3m" color="gray2">
                {t(
                  "clientManagement:exporter.buyerListAddPage.addContactPerson"
                )}
              </Typo>
            </StyledButton>
          }
        >
          <Table
            ref={agGridRef}
            rowData={contactPersonListToRowData}
            columnDefs={columnDefs}
            components={{
              ...editAction({ onClick: handleDetailClick }),
              ...createDeleteAction(handleDeleteClick),
            }}
            isPaginationDataMaping
            totalPage={contactPersonList.length}
            page={pagination.page}
            pageSize={pagination.pageSize}
            onGridReady={() => setIsReady(true)}
            handlePaginationClick={(page, pageSize) =>
              setPagination({ page, pageSize })
            }
          />
        </SectionCard>

        <BottomFixedContainer>
          <FooterButtonContainer>
            <FooterButton
              buttonColor="black"
              buttonGrade="tertiary"
              onClick={() =>
                setIsAlertDialogOpen(AlertDialogState.BACK_TO_PREVIOUS)
              }
              style={{ display: "flex", alignItems: "center", gap: "4px" }}
            >
              <Icon iconSrc={ChevronLeftSvg} iconSize={16} />{" "}
              {t("clientManagement:exporter.common.backToPrevious")}
            </FooterButton>
            <FooterButton onClick={() => submitButtonRef.current?.click()}>
              {t("clientManagement:exporter.common.save")}
            </FooterButton>
          </FooterButtonContainer>
        </BottomFixedContainer>
      </SectionContainer>
      {renderDialog()}
      {renderAlertDialog()}
    </ExporterMainLayout>
  );
};

export default ExporterClientManagementAddPage;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 72px;
`;

const StyledForm = styled.form`
  display: flex;
  gap: 24px;
`;

const HalfDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const StyledFormItem = styled(FormItem)`
  ${mediaQuery.FORM_INPUT_TO_FULL_INPUT} {
    flex-direction: column;
    gap: 8px;
  }
`;

const StyledAddressFormItem = styled(AddressFormItem)`
  ${mediaQuery.FORM_INPUT_TO_FULL_INPUT} {
    flex-direction: column;
    gap: 8px;
    > div {
      width: 100% !important;
    }
  }
`;
const StyledContactFormItem = styled(ContactFormItem)`
  ${mediaQuery.FORM_INPUT_TO_FULL_INPUT} {
    flex-direction: column;
    gap: 8px;
  }
`;
const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const FooterButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FooterButton = styled(Button)`
  min-width: 158px;
  text-align: center;
  ${typo.btn3m}
`;

const Hidden = styled.button`
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
`;

const CallOut = styled.div`
  background: ${colorSet.gray11};
  border-radius: 8px;
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 24px;
`;

const InfoIcon = styled(InfoSvg)<{ color: ColorType }>`
  width: 16px;
  height: 16px;

  path {
    fill: ${({ color }) => colorSet[color]};
  }
`;
