import { useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import Dialog from "@/src/components/atom/Dialog";
import Loader from "@/src/components/atom/Loader";
import Select from "@/src/components/atom/Select";
import Table from "@/src/components/atom/Table";
import { useGetBuyersQuery } from "@/src/store/apis/client/buyer";
import { ColDef } from "ag-grid-community";
import { Button } from "@/src/components/atom/Button";
import Typo from "@/src/components/atom/Typo";
import { AgGridReact } from "ag-grid-react";
import colorSet from "@/src/styles/color";
import useAlert from "@/src/hooks/useAlert";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import { useLazyGetFilteredBookingListAssociatedWithTaskQuery } from "@/src/store/apis/tasks/taskRegister";
import { TaskBookingListViewDto } from "@/src/store/apis/tasks/taskRegister/interface";
import Icon from "@/src/components/atom/Icon";
import InfoSvg from "@/src/assets/icons/icon-info-gray6.svg";
import { isUndefined } from "@/src/utils/is";
import { useTranslation } from "react-i18next";
import { columnLoadBooking } from "../../../columns/columnLoadBooking";
import { authApi } from "@/src/store/apis/auth";
import DialogFooterContainer from "@/src/components/atom/Dialog/DialogFooterContainer";

interface LoadBookingDialogProps {
  open: boolean;
  onClose: () => void;
  onSelect?: (detail?: TaskBookingListViewDto) => void;
  defaultSelectedItemId?: number;
  maxContainer?: number;
}

interface BookingListGridRowDataType {
  id: number;
  bookingNo: string;
  etdAt: string;
  initialEtdAt: string;
  buyerNameCode: string;
  item: string;
  numberOfContainer: string;
  remainingNumberOfContainer: string;
  pureRemainingCount: number;
  isDisable: boolean;
  isChecked: boolean;
}

const LoadBookingDialog = ({
  open,
  onClose,
  onSelect,
  defaultSelectedItemId,
  maxContainer,
}: LoadBookingDialogProps) => {
  const { t } = useTranslation();
  const alert = useAlert();
  const gridRef = useRef<AgGridReact>(null);

  const [buyerCode, setBuyerCode] = useState<string>();
  const [selectedId, setSelectedId] = useState<number | null>(
    defaultSelectedItemId || null
  );
  const [isReady, setIsReady] = useState<boolean>(false);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
  const [columnDefs] = useState<ColDef[]>(
    columnLoadBooking(setSelectedId, maxContainer)
  );

  useAgGridHeaderRefresh({
    gridRef: gridRef.current,
    isReady,
    headerSet: [
      {
        columnKey: "bookingNo",
        langKey: "table:bookingNo",
      },
      {
        columnKey: "remainingNumberOfContainer",
        langKey: "table:remainingContainer",
      },
      {
        columnKey: "buyerNameCode",
        langKey: "table:buyerCode",
      },
      {
        columnKey: "etdAt",
        langKey: "table:etd",
      },
      {
        columnKey: "item",
        langKey: "table:itemCode",
      },
      {
        columnKey: "numberOfContainer",
        langKey: "table:noOfContainer",
      },
    ],
  });

  const { mainCategoryCodeItemNames, exporterUserType } =
    authApi.endpoints.getSession.useQueryState(undefined, {
      selectFromResult: ({ currentData }) => {
        return {
          user: currentData?.row,
          mainCategoryCodeItemNames: currentData?.row.mainCategoryCodeItemNames,
          exporterUserType: currentData?.row.exporterUserType,
        };
      },
    });

  const { buyerList, isBuyerListError, isBuyerListFetching } =
    useGetBuyersQuery(
      {},
      {
        selectFromResult: ({ currentData, isError, isFetching }) => {
          return {
            buyerList: currentData?.data ?? [],
            isBuyerListError: isError,
            isBuyerListFetching: isFetching,
          };
        },
        refetchOnMountOrArgChange: true,
      }
    );
  const [getBookingList, { bookingList, count, isFetching }] =
    useLazyGetFilteredBookingListAssociatedWithTaskQuery({
      selectFromResult: ({ currentData, isFetching, isError }) => ({
        bookingList:
          !isError && !isUndefined(currentData) ? currentData.rows : [],
        isFetching,
        count: currentData?.count || 0,
      }),
    });
  const buyersListToOptions = isBuyerListError
    ? []
    : buyerList
        .filter((val) => {
          // userType이 MANAGER인 경우 필터링
          // 일반매니저, 중간관리자가 아닌 경우 필터링 없이 그대로 유지
          return exporterUserType === "MANAGER" ||
        exporterUserType === "MIDDLE_MANAGER"
            ? val.mainCategoryCodeItemNames.some((category) =>
                mainCategoryCodeItemNames?.includes(category)
              )
            : true;
        })
        .reduce<{ label: string; value: string }[]>(
          (acc, val) => {
            const resource = {
              label: val?.buyerNameCode || "",
              value:
                `${val.buyerNameCode}_${val.buyerListQueryResultDto.id}` || "",
            };
            return [...acc, resource];
          },
          [{ label: t("common:all"), value: "all" }]
        );

  const handleSelect = () => {
    onSelect?.(bookingList.find(({ id }) => id === selectedId));
    onClose();
    alert.showAlert({
      type: "success",
      message: t("task:add.alert.selectBookingSuccess"),
    });
  };

  const bookingListRowData = bookingList.reduce<BookingListGridRowDataType[]>(
    (acc, val) => {
      const resource = {
        id: val.id,
        bookingNo: val.bookingNo,
        etdAt: val.etdAt,
        initialEtdAt: val.initialEtdAt,
        buyerNameCode: val.buyerNameCode || "-",
        item: val.itemCode || "-",
        numberOfContainer: `${val.numberOfContainer.toLocaleString("ko-KR")}${t(
          "common:ea"
        )}`,
        remainingNumberOfContainer: `${val.remainingNumberOfContainer.toLocaleString(
          "ko-KR"
        )}${t("common:ea")}`,
        pureRemainingCount: val.remainingNumberOfContainer,
        isDisable: maxContainer
          ? val.remainingNumberOfContainer < maxContainer
          : false,
        isChecked: val.id === selectedId,
      };
      return [...acc, resource];
    },
    []
  );

  useEffect(() => {
    if (isFetching && gridRef.current) {
      gridRef.current.api.showLoadingOverlay();
    }
  }, [isFetching]);

  useEffect(() => {
    (async () => {
      await getBookingList({
        page: 1,
        pageSize: 10,
      });
    })();
  }, [getBookingList]);

  return (
    <Dialog
      title={t("task:add.dialog.selectBooking")}
      open={open}
      onOpenChange={onClose}
      width={1000}
      footer={
        <DialogFooterContainer>
          <Button buttonGrade="tertiary" buttonColor="black" onClick={onClose}>
            {t("task:add.common.exit")}
          </Button>
          <Button disabled={!selectedId} onClick={handleSelect}>
            {t("task:add.buttonLabel.select")}
          </Button>
        </DialogFooterContainer>
      }
    >
      <Content>
        <InputContainer>
          <Typo typoType="b7m">{t("common:buyerCode")}</Typo>
          <Select
            options={buyersListToOptions}
            showSearch
            defaultValue={"all"}
            suffixIcon={isBuyerListFetching ? <Loader /> : undefined}
            disabled={isBuyerListFetching}
            style={{ width: "100%" }}
            filterOption={(input, option) =>
              ((option?.label as string) ?? "")
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            getPopupContainer={(triggerNode) => {
              return triggerNode.parentElement;
            }}
            onChange={async (value, option: any) => {
              setBuyerCode(value === "all" ? undefined : option.label);
              setPagination({ page: 1, pageSize: pagination.pageSize });
              await getBookingList({
                buyerNameCode: value === "all" ? undefined : option.label,
                page: 1,
                pageSize: pagination.pageSize,
              });
            }}
          />
        </InputContainer>

        <FlexAlignCenter>
          <Icon className="info-svg" iconSrc={InfoSvg} iconSize={16} />
          <Typo typoType="b9r" color="gray6">
            {t("task:add.dialog.bookingInfo")}
          </Typo>
        </FlexAlignCenter>

        <Table
          ref={gridRef}
          onGridReady={() => setIsReady(true)}
          columnDefs={columnDefs}
          rowData={bookingListRowData}
          totalPage={count}
          page={pagination.page}
          pageSize={pagination.pageSize}
          handlePaginationClick={async (page, pageSize) => {
            setPagination({ page, pageSize });
            await getBookingList({
              page,
              pageSize,
              buyerNameCode: buyerCode,
            });
          }}
          onRowClicked={(params) => {
            if (
              maxContainer &&
              maxContainer > params?.data?.pureRemainingCount
            ) {
              return;
            }
            setSelectedId(params.data.id);
          }}
        />
      </Content>
    </Dialog>
  );
};

export default LoadBookingDialog;

const Content = styled.div`
  min-height: 400px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .ag-theme-alpine {
    outline: 1px solid ${colorSet.gray9};
    border-radius: 8px;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const FlexAlignCenter = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  .info-svg {
    flex-shrink: 0;
  }
`;
