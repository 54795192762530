import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { AgGridReact } from "ag-grid-react";
import { useNavigate } from "react-router-dom";
import { RowSelectedEvent } from "ag-grid-community";
import { Controller, useForm } from "react-hook-form";
import Dialog from "@/src/components/atom/Dialog";
import { Button } from "@/src/components/atom/Button";
import {
  linkApi,
  useLazyGetCorporateManagerInfoViaLinkCodeQuery,
  useLinkExistedClientMutation,
  useLinkNewClientMutation,
} from "@/src/store/apis/link";
import { isUndefined } from "@/src/utils/is";
import Table from "@/src/components/atom/Table";
import {
  clientCorporateManagerColumn,
  existClientColumn,
  staffManagerColumn,
} from "./columns/column";
import colorSet from "@/src/styles/color";
import { useGetCommonCodeViaCodeNameQuery } from "@/src/store/apis/common";
import { PartialCommonCodeItemDto } from "@/src/store/apis/common/interface";
import Input from "@/src/components/atom/Input";
import { ClientType } from "@/src/store/apis/auth/interface";
import RadioGroup from "@/src/components/atom/RadioGroup";
import { InputError } from "@/src/components/molecule/FormItem";
import {
  useLazyCheckClientNameCodeQuery,
  useLazyGetBuyersQuery,
} from "@/src/store/apis/client/buyer";
import Typo from "@/src/components/atom/Typo";
import GridCardRadio from "@/src/components/molecule/GridCardRadio";
import { useGetStaffsQuery } from "@/src/store/apis/corporate/staffManagement";
import SectionFoldableCard from "@/src/components/molecule/SectionFoldableCard";
import { BuyerListViewDto } from "@/src/store/apis/client/buyer/interface";
import useAlert from "@/src/hooks/useAlert";
import RightArrowIcon from "@/src/components/molecule/SectionCard/RightArrowIcon";
import IMPORTER_PRIVATE_PATH from "@/src/routes/importer/path";
import AlertDialog from "@/src/components/atom/AlertDialog";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import { useTranslation } from "react-i18next";
import { CLIENT_TYPE_OPTION_LIST } from "@/src/constant/optionList";
import DialogFooterContainer from "@/src/components/atom/Dialog/DialogFooterContainer";

interface LinkCompanyDetailDialogProps {
  onClose: () => void;
  linkCode: string;
  defaultClientCode: string;
  defaultClientType: ClientType;
}

enum AlertDialogState {
  NULL,
  SUCCESS,
  EXIST_FAIL,
}

const EMPTY_ARRAY: PartialCommonCodeItemDto[] = [];
const EMPTY_CLIENT_ARRAY: BuyerListViewDto[] = [];
const EMPTY_STRING_ARRAY: string[] = [];

const TABLE_INNER_CONTENT_HEIGHT = 253;

const LinkCompanyDetailDialog = ({
  onClose,
  linkCode,
  defaultClientCode,
  defaultClientType,
}: LinkCompanyDetailDialogProps) => {
  const { t } = useTranslation();
  const alert = useAlert();
  const corpManagerGridRef = useRef<AgGridReact>(null);
  const staffManagerGridRef = useRef<AgGridReact>(null);
  const existClientGridRef = useRef<AgGridReact>(null);
  const [isCorpManagerGridReady, setIsCorpManagerGridReady] =
    useState<boolean>(false);
  const [isStaffManagerGridReady, setStaffManagerIsGridReady] =
    useState<boolean>(false);
  const [isExistClientGridReady, setIsExistClientGridReady] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const {
    formState: { errors },
    control,
    setValue,
    watch,
    getValues,
  } = useForm<{ clientCode: string; clientType: ClientType }>({
    mode: "onBlur",
    defaultValues: {
      clientCode: defaultClientCode,
      clientType: defaultClientType,
    },
  });
  const [staffPagination, setStaffPagination] = useState(1);
  const [existClientPagination, setExistClientPagination] = useState(1);
  const [selectedStaffList, setSelectedStaffList] = useState<number[]>([]);
  const [selectedClient, setSelectedClient] = useState<number>();
  const [clientSelection, setClientSelection] = useState<"new" | "exist">(
    "new"
  );
  const [searchBuyerName, setSearchBuyerName] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isClientCardOpen, setIsClientCardOpen] = useState(true);
  const [alertDialogState, setAlertDialogState] = useState<AlertDialogState>(
    AlertDialogState.NULL
  );
  const [errorContent, setErrorContent] = useState<string>();
  const [column] = useState(clientCorporateManagerColumn);
  const [staffColumn] = useState(staffManagerColumn);
  const [clientColumn] = useState(existClientColumn);
  const [getBuyers, { clientCount, clientData }] = useLazyGetBuyersQuery({
    selectFromResult: ({ isError, isFetching, currentData }) => {
      const isUnstable = isError || isFetching || isUndefined(currentData);
      const isStable = !isUnstable;
      return {
        clientCount: isStable ? currentData.count : 0,
        clientData: isFetching
          ? undefined
          : isError || isUndefined(currentData)
          ? EMPTY_CLIENT_ARRAY
          : currentData.data,
      };
    },
  });
  const { isError, isFetching, currentData } = useGetStaffsQuery(
    {
      isActivated: true,
      page: staffPagination,
      pageSize: 5,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const [checkClientCode] = useLazyCheckClientNameCodeQuery();
  const [linkNewClient] = useLinkNewClientMutation();
  const [linkExistedClient] = useLinkExistedClientMutation();
  const { mainCategory } = useGetCommonCodeViaCodeNameQuery(
    {
      codeName: "MAIN_CATEGORY",
    },
    {
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);

        return {
          mainCategory: !isUnstable ? currentData : EMPTY_ARRAY,
        };
      },
    }
  );
  const [
    getCorporateManagerInfoViaLinkCode,
    {
      currentData: clientCorporateManager,
      isFetching: isClientCorporateManagerFetching,
    },
  ] = useLazyGetCorporateManagerInfoViaLinkCodeQuery();
  const {
    client,
    contact,
    country,
    businessNumber,
    mainCategoryCode,
    exporterId,
    address,
  } = linkApi.endpoints.getCompanyInfoViaLinkCode.useQueryState(
    {
      companyLinkCode: linkCode,
    },
    {
      selectFromResult: ({ isError, isFetching, currentData }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnstable;
        return {
          client: isStable ? currentData.row.companyName : "-",
          clientType: isStable ? currentData.row.companyType : undefined,
          contact: isStable
            ? `${currentData.row?.telPrefix || ""} ${
                currentData.row?.tel || ""
              }`
            : "-",
          country: isStable ? currentData.row.country : "-",
          businessNumber: isStable ? currentData.row.businessNumber : "-",
          mainCategoryCode: isStable
            ? currentData.row.mainCategoryCodeItemNames
            : EMPTY_STRING_ARRAY,
          exporterId: isStable ? currentData.row.id : 0,
          address: isStable
            ? [
                currentData.row.postalCode,
                currentData.row.streetAddress,
                currentData.row.locality,
                currentData.row.region,
                currentData.row.countryName,
              ]
                .filter((addressPartial) => !!addressPartial)
                .join(", ")
            : "-",
        };
      },
    }
  );

  const mainCategoryCapitalize = mainCategoryCode
    .map(
      (itemCode) =>
        mainCategory.find(({ codeItemName }) => codeItemName === itemCode)
          ?.codeItemNameEn
    )
    .join(", ");

  // AgGrid Checkbox
  const handleSelectionChanged = (e: RowSelectedEvent) => {
    if (
      e.source === "rowClicked" ||
      e.source === "uiSelectAll" ||
      e.source === "checkboxSelected"
    ) {
      const selectedNodesData = e.api.getSelectedNodes();
      const accountKey = e.node.data.exporterUserListQueryResultDto.id;
      const keys = selectedNodesData.map(
        (item) => item.data.exporterUserListQueryResultDto.id
      ) as number[];

      if (selectedStaffList.includes(accountKey)) {
        setSelectedStaffList((prev) => prev.filter((id) => id !== accountKey));
      } else {
        const set: any = new Set([...selectedStaffList, ...keys]);
        setSelectedStaffList([...set]);
      }
    }
  };

  // AgGrid 페이지 네이션
  const handleRowDataUpdated = () => {
    const isLiveGridRef = !!staffManagerGridRef.current;

    if (isLiveGridRef) {
      staffManagerGridRef.current?.api.forEachNode((rowNode) => {
        if (
          selectedStaffList.includes(
            rowNode.data.exporterUserListQueryResultDto.id
          )
        ) {
          rowNode.setSelected(true);
        }
      });
    }
  };

  const handleConnect = async () => {
    try {
      setIsSubmitting(true);

      const { isError } = await checkClientCode({
        buyerNameCode: getValues("clientCode"),
      });

      if (isError) {
        // eslint-disable-next-line no-throw-literal
        throw {
          data: {
            message: t("error:alreadyExistedClientCode"),
          },
        };
      }

      if (clientSelection === "exist") {
        await linkExistedClient({
          exporterId,
          companyLinkCode: linkCode,
          buyerNameCode: getValues("clientCode"),
          companyType: getValues("clientType"),
          buyerId: selectedClient as number,
          exporterUserIdList: selectedStaffList,
        }).unwrap();
      } else {
        await linkNewClient({
          exporterId,
          companyLinkCode: linkCode,
          buyerNameCode: getValues("clientCode"),
          companyType: getValues("clientType"),
          exporterUserIdList: selectedStaffList,
        }).unwrap();
      }
      setAlertDialogState(AlertDialogState.SUCCESS);
    } catch (e: any) {
      if (e.data.statusCode === 409) {
        setErrorContent(e.data.message);
        setAlertDialogState(AlertDialogState.EXIST_FAIL);
      } else {
        const message = Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message;
        alert.showAlert({ type: "error", message });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  useAgGridHeaderRefresh({
    gridRef: corpManagerGridRef.current,
    isReady: isCorpManagerGridReady,
    headerSet: [
      {
        columnKey: "name",
        langKey: "clientManagement:importer.common.name",
      },
      {
        columnKey: "aId",
        langKey: "clientManagement:importer.common.email",
      },
      {
        columnKey: "personalContact",
        langKey: "clientManagement:importer.common.personalContact",
      },
      {
        columnKey: "officeContact",
        langKey: "clientManagement:importer.common.officeContact",
      },
    ],
  });

  useAgGridHeaderRefresh({
    gridRef: staffManagerGridRef.current,
    isReady: isStaffManagerGridReady,
    headerSet: [
      {
        columnKey: "name",
        langKey: "clientManagement:importer.common.name",
      },
      {
        columnKey: "email",
        langKey: "clientManagement:importer.common.email",
      },
      {
        columnKey: "personalContact",
        langKey: "clientManagement:importer.common.personalContact",
      },
      {
        columnKey: "officeContact",
        langKey: "clientManagement:importer.common.officeContact",
      },
    ],
  });

  useAgGridHeaderRefresh({
    gridRef: existClientGridRef.current,
    isReady: isExistClientGridReady,
    headerSet: [
      {
        columnKey: "buyerName",
        langKey: "clientManagement:importer.common.clientName",
      },
      {
        columnKey: "companyType",
        langKey: "clientManagement:importer.common.clientType",
      },
      {
        columnKey: "buyerNameCode",
        langKey: "clientManagement:importer.common.clientCode",
      },
      {
        columnKey: "country",
        langKey: "clientManagement:importer.common.country",
      },
    ],
  });

  useEffect(() => {
    (async () => {
      try {
        await getCorporateManagerInfoViaLinkCode({ companyLinkCode: linkCode });
      } catch (e) {}
    })();
  }, [getCorporateManagerInfoViaLinkCode, linkCode]);

  const renderCorpInfo = () => {
    return (
      <InfoBox>
        <Box>
          <InfoRow>
            <InfoLabel>
              <Typo typoType="b7m">
                {t(
                  "clientManagement:importer.linkCompanyDetailDialog.clientInfo.client"
                )}
              </Typo>
            </InfoLabel>
            <InfoValue>
              <Typo typoType="b7r" color="gray5">
                {client}
              </Typo>
            </InfoValue>
          </InfoRow>
          <InfoRow>
            <InfoLabel>
              {t(
                "clientManagement:importer.linkCompanyDetailDialog.clientInfo.clientCode"
              )}
            </InfoLabel>
            <InfoValue>
              <Controller
                control={control}
                name="clientCode"
                rules={{
                  required: true,
                  validate: async (buyerNameCode) => {
                    const { isError } = await checkClientCode({
                      buyerNameCode,
                    });
                    return !isError;
                  },
                }}
                render={({ field }) => {
                  return (
                    <Input
                      data-invalid={!!errors.clientCode}
                      onClear={() => setValue("clientCode", "")}
                      {...field}
                    />
                  );
                }}
              />
              {errors.clientCode && (
                <StyledInputError
                  message={
                    errors.clientCode.type === "validate"
                      ? t("error:alreadyExistedClientCode")
                      : t("error:required")
                  }
                />
              )}
            </InfoValue>
          </InfoRow>
          <InfoRow>
            <InfoLabel>
              <Typo typoType="b7m">
                {t(
                  "clientManagement:importer.linkCompanyDetailDialog.clientInfo.clientType"
                )}
              </Typo>
            </InfoLabel>
            <InfoValue>
              <Controller
                control={control}
                name="clientType"
                render={({ field }) => {
                  return (
                    <StyledRadioGroup
                      size="large"
                      {...field}
                      typoType="b7r"
                      options={CLIENT_TYPE_OPTION_LIST.map((item) => {
                        return { ...item, label: t(item.langKey) };
                      })}
                    />
                  );
                }}
              />
            </InfoValue>
          </InfoRow>
          <InfoRow>
            <InfoLabel>
              <Typo typoType="b7m">
                {t(
                  "clientManagement:importer.linkCompanyDetailDialog.clientInfo.country"
                )}
              </Typo>
            </InfoLabel>
            <InfoValue>
              <Typo typoType="b7r" color="gray5">
                {country}
              </Typo>
            </InfoValue>
          </InfoRow>
        </Box>
        <Divider />
        <Box>
          {[
            {
              label: t(
                "clientManagement:importer.linkCompanyDetailDialog.clientInfo.businessNumber"
              ),
              value: businessNumber,
            },
            {
              label: t(
                "clientManagement:importer.linkCompanyDetailDialog.clientInfo.mainCategory"
              ),
              value: mainCategoryCapitalize,
            },
            {
              label: t(
                "clientManagement:importer.linkCompanyDetailDialog.clientInfo.contact"
              ),
              value: contact,
            },
            {
              label: t(
                "clientManagement:importer.linkCompanyDetailDialog.clientInfo.address"
              ),
              value: address,
            },
          ].map(({ label, value }) => {
            return (
              <InfoRow>
                <InfoLabel>
                  <Typo typoType="b7m">{label}</Typo>
                </InfoLabel>
                <InfoValue>
                  <Typo typoType="b7r" color="gray5">
                    {value}
                  </Typo>
                </InfoValue>
              </InfoRow>
            );
          })}
        </Box>
      </InfoBox>
    );
  };

  const renderCorpManagerInfo = () => {
    return (
      <Article>
        <Typo typoType="b7m">
          {t(
            "clientManagement:importer.linkCompanyDetailDialog.clientCorporateManager"
          )}
        </Typo>
        <Table
          ref={corpManagerGridRef}
          columnDefs={column}
          isPagination={false}
          height={84}
          rowData={
            isClientCorporateManagerFetching || !clientCorporateManager
              ? undefined
              : [clientCorporateManager.row]
          }
          onGridReady={() => {
            setIsCorpManagerGridReady(true);
          }}
        />
      </Article>
    );
  };

  const renderClientSelection = () => {
    return (
      <Article>
        <Typo typoType="b7m">
          {t(
            "clientManagement:importer.linkCompanyDetailDialog.selectNewOrRegisteredClient"
          )}{" "}
          <strong style={{ color: `${colorSet.red2}` }}>*</strong>
        </Typo>

        <GridCardRadio.Container>
          <GridCardRadio.Radio
            label={
              <Typo
                typoType="h9"
                color={clientSelection === "new" ? "blue4" : "gray6"}
              >
                {t(
                  "clientManagement:importer.linkCompanyDetailDialog.newClient"
                )}
              </Typo>
            }
            value={"new"}
            gridSpan={1}
            checked={clientSelection === "new"}
            onChange={() => {
              setClientSelection("new");
              setIsExistClientGridReady(false);
            }}
          />
          <GridCardRadio.Radio
            label={
              <Typo
                typoType="h9"
                color={clientSelection === "exist" ? "blue4" : "gray6"}
              >
                {t(
                  "clientManagement:importer.linkCompanyDetailDialog.registeredClient"
                )}
              </Typo>
            }
            value={"exist"}
            gridSpan={1}
            checked={clientSelection === "exist"}
            onChange={async () => {
              try {
                await getBuyers({
                  buyerName: searchBuyerName || undefined,
                  linkedStatus: "PENDING",
                  isActivated: true,
                  page: existClientPagination,
                  pageSize: 5,
                }).unwrap();
              } catch (e: any) {
                const message = Array.isArray(e.data.message)
                  ? e.data.message[0]
                  : e.data.message;

                if (e.data.statusCode !== 404) {
                  alert.showAlert({ type: "error", message });
                }
              } finally {
                setIsClientCardOpen(true);
                setClientSelection("exist");
              }
            }}
          />
        </GridCardRadio.Container>
      </Article>
    );
  };

  const renderCorpStaffList = () => {
    return (
      <Article>
        <Typo typoType="b7m">
          {t("clientManagement:importer.linkCompanyDetailDialog.staffList")}{" "}
          <strong style={{ color: `${colorSet.red2}` }}>*</strong>
        </Typo>
        <TableWrapper>
          <Table
            ref={staffManagerGridRef}
            columnDefs={staffColumn}
            height={TABLE_INNER_CONTENT_HEIGHT}
            rowSelection={"multiple"}
            rowMultiSelectWithClick={true}
            totalPage={currentData ? currentData.count : 0}
            page={staffPagination}
            pageSize={5}
            handlePaginationClick={(page) => setStaffPagination(page)}
            onRowSelected={handleSelectionChanged}
            onRowDataUpdated={handleRowDataUpdated}
            rowData={
              isFetching
                ? undefined
                : isError || isUndefined(currentData)
                ? []
                : currentData.rows
            }
            onGridReady={() => {
              setStaffManagerIsGridReady(true);
            }}
          />
        </TableWrapper>
      </Article>
    );
  };

  const renderRegisteredCorpList = () => {
    return (
      <SectionFoldableCard
        open={isClientCardOpen}
        onOpenChange={setIsClientCardOpen}
        cardTitle={t(
          "clientManagement:importer.linkCompanyDetailDialog.registeredClientList"
        )}
        rightAccessory={
          <RightArrowIcon
            isCardOpen={isClientCardOpen}
            onClick={() => setIsClientCardOpen((prev) => !prev)}
          />
        }
      >
        <FlexColumn>
          <Flex>
            <Input
              value={searchBuyerName}
              onChange={(e) => setSearchBuyerName(e.target.value)}
              placeholder={t(
                "clientManagement:importer.linkCompanyDetailDialog.placeholder.client"
              )}
              allowClear={false}
              onKeyDown={async (e) => {
                try {
                  if (e.code === "Enter") {
                    await getBuyers({
                      buyerName: searchBuyerName || undefined,
                      linkedStatus: "PENDING",
                      isActivated: true,
                      page: existClientPagination,
                      pageSize: 5,
                    });
                  }
                } catch (e: any) {
                  const message = Array.isArray(e.data.message)
                    ? e.data.message[0]
                    : e.data.message;

                  if (e.data.statusCode !== 404) {
                    alert.showAlert({ type: "error", message });
                  }
                } finally {
                  setExistClientPagination(1);
                }
              }}
            />
            <StyledButton
              buttonSize={40}
              buttonColor="blue"
              buttonGrade="secondary"
              onClick={async () => {
                try {
                  await getBuyers({
                    buyerName: searchBuyerName || undefined,
                    linkedStatus: "PENDING",
                    isActivated: true,
                    page: existClientPagination,
                    pageSize: 5,
                  });
                } catch (e: any) {
                  const message = Array.isArray(e.data.message)
                    ? e.data.message[0]
                    : e.data.message;

                  if (e.data.statusCode !== 404) {
                    alert.showAlert({ type: "error", message });
                  }
                } finally {
                  setExistClientPagination(1);
                }
              }}
            >
              {t(
                "clientManagement:importer.linkCompanyDetailDialog.buttons.search"
              )}
            </StyledButton>
          </Flex>
          <TableWrapper>
            <Table
              ref={existClientGridRef}
              columnDefs={clientColumn}
              height={TABLE_INNER_CONTENT_HEIGHT}
              rowSelection={"single"}
              totalPage={clientData ? clientCount : 0}
              page={existClientPagination}
              pageSize={5}
              handlePaginationClick={async (page) => {
                try {
                  await getBuyers({
                    buyerName: searchBuyerName || undefined,
                    linkedStatus: "PENDING",
                    isActivated: true,
                    page: page,
                    pageSize: 5,
                  }).unwrap();
                  setExistClientPagination(page);
                } catch (e: any) {
                  const message = Array.isArray(e.data.message)
                    ? e.data.message[0]
                    : e.data.message;

                  if (e.data.statusCode !== 404) {
                    alert.showAlert({ type: "error", message });
                  }
                }
              }}
              onRowSelected={(e) => {
                setSelectedClient(e.data.buyerListQueryResultDto.id);
                setIsClientCardOpen(false);
              }}
              rowData={clientData?.map((client) => ({
                ...client,
                isChecked: client.buyerListQueryResultDto.id === selectedClient,
              }))}
              onGridReady={() => {
                setIsExistClientGridReady(true);
              }}
            />
          </TableWrapper>
        </FlexColumn>
      </SectionFoldableCard>
    );
  };

  const renderAlertDialog = () => {
    if (alertDialogState === AlertDialogState.NULL) return null;

    if (alertDialogState === AlertDialogState.SUCCESS) {
      return (
        <AlertDialog
          open
          title={t(
            "clientManagement:importer.linkCompanyDetailDialog.alertDialog.success.title"
          )}
          onOpenChange={() => {
            setAlertDialogState(AlertDialogState.NULL);
            onClose();
          }}
          onOk={() => {
            setAlertDialogState(AlertDialogState.NULL);
            onClose();
            navigate(`${IMPORTER_PRIVATE_PATH.CLIENT_MANAGEMENT}`);
          }}
          okText={t("common:ok")}
        >
          {t(
            "clientManagement:importer.linkCompanyDetailDialog.alertDialog.success.description"
          )}
        </AlertDialog>
      );
    }

    if (alertDialogState === AlertDialogState.EXIST_FAIL) {
      return (
        <AlertDialog
          open
          title={t("common:registeredClient")}
          onOpenChange={() => {
            setAlertDialogState(AlertDialogState.NULL);
            onClose();
          }}
          onOk={() => {
            setAlertDialogState(AlertDialogState.NULL);
            onClose();
          }}
          okText={t("common:ok")}
        >
          {errorContent}
        </AlertDialog>
      );
    }
  };

  return (
    <>
      <Dialog
        title={t(
          "clientManagement:importer.linkCompanyDetailDialog.dialogTitle"
        )}
        open
        onOpenChange={onClose}
        width={1000}
        footer={
          <DialogFooterContainer>
            <Button
              buttonGrade={"tertiary"}
              buttonColor="black"
              onClick={onClose}
            >
              {t("common:cancel")}
            </Button>
            <Button
              disabled={
                !watch("clientCode") ||
                !!errors.clientCode ||
                selectedStaffList.length === 0 ||
                (clientSelection === "exist" && !selectedClient)
              }
              onClick={handleConnect}
              isLoading={isSubmitting}
            >
              {t(
                "clientManagement:importer.linkCompanyDetailDialog.buttons.submit"
              )}
            </Button>
          </DialogFooterContainer>
        }
      >
        <TextContainer>
          <PreLineTypo typoType="h2">
            {t("clientManagement:importer.linkCompanyDetailDialog.title")}
          </PreLineTypo>
          <PreLineTypo typoType="b7m">
            {t("clientManagement:importer.linkCompanyDetailDialog.description")}
          </PreLineTypo>
        </TextContainer>
        <InnerContainer>
          {renderCorpInfo()}

          <RowDivider />

          {renderCorpManagerInfo()}

          <RowDivider />

          {renderClientSelection()}

          {clientSelection === "exist" && <>{renderRegisteredCorpList()}</>}

          <RowDivider />

          {renderCorpStaffList()}
        </InnerContainer>

        {renderAlertDialog()}
      </Dialog>
    </>
  );
};
export default LinkCompanyDetailDialog;

const InfoBox = styled.div`
  border: 1px solid ${colorSet.gray9};
  border-radius: 8px;
  padding: 16px;
  display: flex;
  gap: 16px;
`;

const Box = styled.div`
  width: calc(50% - 8px);
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Divider = styled.div`
  width: 1px;
  background: ${colorSet.gray9};
`;

const RowDivider = styled.div`
  width: 100%;
  height: 1px;
  background: ${colorSet.gray9};
`;

const InfoRow = styled.div`
  display: flex;
`;

const InfoLabel = styled.div`
  width: 164px;
`;
const InfoValue = styled.div`
  flex: 1;
`;
const TextContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin-bottom: 40px;
`;
const InnerContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const StyledInputError = styled(InputError)`
  padding-top: 8px;
`;

const StyledRadioGroup = styled(RadioGroup)`
  &.ant-radio-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
  }
`;

const Article = styled.article`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Flex = styled.div`
  display: flex;
  gap: 8px;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const TableWrapper = styled.div`
  .ant-pagination-options {
    display: none;
  }
`;

const PreLineTypo = styled(Typo)`
  white-space: pre-line;
  text-align: center;
`;
