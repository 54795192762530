import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SectionCard from "@/src/components/molecule/SectionCard";
import FormItem, {
  InputError,
  InputSuccess,
} from "@/src/components/molecule/FormItem";
import { Controller, useForm } from "react-hook-form";
import { Button, TextButton } from "@/src/components/atom/Button";
import Typo from "@/src/components/atom/Typo";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import Input from "@/src/components/atom/Input";
import Label from "@/src/components/atom/Label";
import Icon from "@/src/components/atom/Icon";
import EyeSvg from "@/src/assets/icons/icon-eye.svg";
import EyeOffSvg from "@/src/assets/icons/icon-eye-off.svg";
import IconInput from "@/src/components/atom/Input/IconInput";
import { emailRegex, passwordRegex } from "@/src/utils/regex";
import {
  useGetCommonCodeViaCodeNameQuery,
  useGetTermsQuery,
} from "@/src/store/apis/common";
import Loader from "@/src/components/atom/Loader";
import Checkbox from "@/src/components/atom/Checkbox";
import MultipleSelect from "@/src/components/atom/Select/MultipleSelect";
import colorSet from "@/src/styles/color";
import { SUBSCRIPTION_PATH_OPTION_LIST } from "@/src/constant/optionList";
import Dialog from "@/src/components/atom/Dialog";
import typo from "@/src/styles/typography";
import useTimer from "@/src/hooks/useTimer";
import {
  useCheckSendEmailCodeMutation,
  useCorpSignUpMutation,
  useLazyCheckIdQuery,
  useSendTokenEmailMutation,
} from "@/src/store/apis/auth";
import useAlert from "@/src/hooks/useAlert";
import {
  useCorporationSignUpButtonStatusContext,
  useCorporationSignUpFormContext,
  useCorporationStepContext,
} from "./hooks";
import TextArea from "@/src/components/atom/TextArea";
import SearchSelect from "@/src/components/atom/Select/SearchSelect";
import { useTranslation } from "react-i18next";

const SEPARATOR = "&";

const CorporateSignUpStepThree = () => {
  const { t, i18n } = useTranslation();
  const alert = useAlert();
  const signUpForm = useCorporationSignUpFormContext();
  const buttonStatus = useCorporationSignUpButtonStatusContext();
  const { onStepPrev, onStepNext } = useCorporationStepContext();

  const {
    data: mainCategoryCodeList,
    isFetching: isMainCategoryCodeListFetching,
    isError: isMainCategoryCodeListError,
  } = useGetCommonCodeViaCodeNameQuery(
    { codeName: "MAIN_CATEGORY" },
    { refetchOnMountOrArgChange: true }
  );

  const { data: termsResponse = [], isFetching } = useGetTermsQuery({
    service: "EXPORT_WEB",
  });

  const [checkValidId] = useLazyCheckIdQuery();
  const [corpSignUp, corpSignUpData] = useCorpSignUpMutation();
  const [sendEmail, sendEmailResult] = useSendTokenEmailMutation();
  const [checkSendEmail] = useCheckSendEmailCodeMutation();

  const [passwordInputType, setPasswordInputType] = useState({
    password: "password",
    rePassword: "password",
  });
  const [viewingTerm, setViewingTerm] = useState<{
    titleEn: string;
    bodyEn: string;
    titleKo: string;
    bodyKo: string;
  } | null>(null);

  const { min, second, restart, reset, pauseOn } = useTimer({
    start: buttonStatus?.isCodeSendClick,
  });

  const {
    control,
    setFocus,
    setValue,
    formState: { errors },
    handleSubmit,
    getValues,
    clearErrors,
    setError,
    watch,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: {
      name: signUpForm?.name,
      aId: signUpForm?.aId,
      password: signUpForm?.password,
      rePassword: "",
      mainCategoryCodeItemNames: signUpForm?.mainCategoryCodeItemNames,
      verifyString: signUpForm?.verifyString,

      joinPath: signUpForm?.joinPath,
      joinPathDescription: signUpForm?.joinPathDescription,
    },
  });

  const formFieldNameType = getValues();
  const isTimeOver = Number(min) === 0 && Number(second) === 0;
  const termsHistoryLength = termsResponse
    .filter((item) => !item.isOptional)
    .map((item) => item.currentTermsHistory.id).length;

  const mainCategoryCodeListToSelectOption = mainCategoryCodeList?.reduce<
    { label: string; value: string }[]
  >((acc, val) => {
    return [
      ...acc,
      {
        label: i18n.language === "ko" ? val.codeItemNameKo : val.codeItemNameEn,
        value: [val.codeItemName, val.codeItemNameEn, val.codeItemNameKo].join(
          SEPARATOR
        ),
      },
    ];
  }, []) as { label: string; value: string }[];

  const isAgreeAll =
    termsResponse
      .map(({ currentTermsHistory }) => currentTermsHistory.id)
      .every((id) => {
        const requiredTermsIdList = signUpForm?.requiredTermsIdList
          ? signUpForm?.requiredTermsIdList
          : [];
        const optionalTermsHistoryIdList =
          signUpForm?.optionalTermsHistoryIdList
            ? signUpForm?.optionalTermsHistoryIdList
            : [];

        return [...requiredTermsIdList, ...optionalTermsHistoryIdList].includes(
          id
        );
      }) && (signUpForm?.isMarketingEmailReceive as boolean);

  const categoryValue = watch("mainCategoryCodeItemNames")?.map((codeName) => {
    const categoryItem = mainCategoryCodeList?.find(
      ({ codeItemName }) => codeName === codeItemName
    );
    return [
      categoryItem?.codeItemName,
      categoryItem?.codeItemNameEn,
      categoryItem?.codeItemNameKo,
    ].join(SEPARATOR);
  });

  const isCreateButtonDisabled = !(
    !!watch("name") &&
    !!watch("aId") &&
    !!watch("password") &&
    !!watch("rePassword") &&
    !!watch("verifyString") &&
    (watch("mainCategoryCodeItemNames") as []).length >= 1 &&
    buttonStatus?.isCodeConfirm &&
    buttonStatus?.isEmailConfirm &&
    signUpForm?.requiredTermsIdList.length === termsHistoryLength
  );

  const handleEmailConfirmClick = async () => {
    try {
      const data = await checkValidId({ id: getValues("aId") });
      reset();
      buttonStatus?.onButtonStatusChange((prev) => {
        return { ...prev, isEmailConfirm: !data.isError };
      });
      if (data.isError) {
        setError("aId", { type: "validate" });
      }
    } catch (e) {
      buttonStatus?.onButtonStatusChange((prev) => {
        return { ...prev, isEmailConfirm: false };
      });
    }
  };

  const handleStepPrevClick = () => {
    signUpForm?.onFormValueChange({
      ...(signUpForm as any),
      ...getValues(),
    });

    onStepPrev();
  };

  const handleRequiredErrorsBlur = (
    type: keyof typeof formFieldNameType,
    value?: string
  ) => {
    if (value !== "") {
      clearErrors(type);
    } else {
      setError(type, { type: "required" });
    }
  };

  const handleResetAgree = () => {
    signUpForm?.onFormValueChange((prevForm) => {
      return {
        ...prevForm,
        isMarketingEmailReceive: false,
        requiredTermsIdList: [],
        optionalTermsHistoryIdList: [],
      };
    });
  };

  const handleAgreeAll = () => {
    signUpForm?.onFormValueChange((prevForm) => {
      return {
        ...prevForm,
        requiredTermsIdList: termsResponse
          ?.filter((term) => !term.isOptional)
          ?.map((requiredTerm) => requiredTerm.currentTermsHistory.id),
        optionalTermsHistoryIdList: termsResponse
          ?.filter((term) => term.isOptional)
          ?.map((optionalTerm) => optionalTerm.currentTermsHistory.id),
        isMarketingEmailReceive: true,
      };
    });
  };

  const handleTermClick = (id: number, isOptional: boolean) => {
    signUpForm?.onFormValueChange((prev) => {
      if (isOptional) {
        const isChecked = prev.optionalTermsHistoryIdList?.includes(id);
        const optionalTermsHistoryIdList = prev.optionalTermsHistoryIdList
          ? prev.optionalTermsHistoryIdList
          : [];

        return {
          ...prev,
          optionalTermsHistoryIdList: isChecked
            ? prev.optionalTermsHistoryIdList?.filter((termId) => termId !== id)
            : [...optionalTermsHistoryIdList, id],
        };
      }

      const isChecked = prev?.requiredTermsIdList?.includes(id);
      const requiredTermsIdList = prev.requiredTermsIdList
        ? prev.requiredTermsIdList
        : [];

      return {
        ...prev,
        requiredTermsIdList: isChecked
          ? prev.requiredTermsIdList?.filter((termId) => termId !== id)
          : [...requiredTermsIdList, id],
      };
    });
  };

  const handleSubmitClick = async () => {
    if (!signUpForm) {
      return;
    }

    const {
      name,
      aId,
      password,
      mainCategoryCodeItemNames,
      joinPath,
      joinPathDescription,
    } = getValues();

    const body = {
      companyType: signUpForm.companyType,
      countryName: signUpForm.countryName,
      countryCodeItemName: signUpForm?.countryCodeItemName?.split("&")[0],
      businessNumber: signUpForm.businessNumber,
      companyName: signUpForm.companyName,
      tel: signUpForm.tel,
      telPrefix: signUpForm.telPrefix,
      faxPrefix: signUpForm.faxPrefix,
      fax: signUpForm.fax || undefined,
      locality: signUpForm.locality,
      postalCode: signUpForm.postalCode,
      streetAddress: signUpForm.streetAddress,
      region: signUpForm.region,
      industryCodeItemNames: signUpForm.industryCodeItemNames as string[],
      name,
      aId,
      password,
      mainCategoryCodeItemNames,
      authTokenId: signUpForm.authTokenId,
      optionalTermsHistoryIdList: [],
      isMarketingEmailReceive: signUpForm.isMarketingEmailReceive,
      joinPath: joinPath || undefined,
      joinPathDescription: joinPathDescription || undefined,
      city: signUpForm.city,
      gmtOffset: signUpForm.gmtOffset,
    };

    try {
      await corpSignUp(body).unwrap();
      onStepNext();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ message, type: "error" });
    }
  };

  const handleCodeConfirmClick = async () => {
    if (!signUpForm) {
      return;
    }

    const body = {
      verifyString: getValues("verifyString"),
      authTokenId: signUpForm.authTokenId,
    };

    try {
      await checkSendEmail(body).unwrap();
      buttonStatus?.onButtonStatusChange((prev) => {
        return { ...prev, isCodeConfirm: true };
      });
      pauseOn();
    } catch (e) {
      setError("verifyString", { type: "validate" });

      buttonStatus?.onButtonStatusChange((prev) => {
        return { ...prev, isCodeConfirm: false };
      });
    }
  };

  const handleEmailChangeClick = () => {
    buttonStatus?.onButtonStatusChange(() => {
      return {
        isCodeConfirm: false,
        isEmailConfirm: false,
        isCodeSendClick: false,
      };
    });
    setValue("verifyString", "");
  };

  const handleResendEmailClick = async () => {
    try {
      const { authTokenId } = await sendEmail({
        contact: getValues("aId"),
      }).unwrap();
      signUpForm?.onFormValueChange((prev) => ({ ...prev, authTokenId }));

      restart();
      setValue("verifyString", "");
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ message, type: "error" });
    }
  };

  const handleSendEmailClick = async () => {
    try {
      const { authTokenId } = await sendEmail({
        contact: getValues("aId"),
      }).unwrap();
      signUpForm?.onFormValueChange((prev) => ({ ...prev, authTokenId }));

      setValue("verifyString", "");
      restart();
      buttonStatus?.onButtonStatusChange((prev) => {
        return {
          ...prev,
          isCodeSendClick: true,
        };
      });
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ message, type: "error" });
    }
  };

  const renderCodeSendButton = () => {
    const isCodeConfirmDisabled =
      buttonStatus?.isCodeConfirm || !getValues("verifyString");

    if (buttonStatus?.isCodeSendClick) {
      return (
        <StyledButton
          disabled={isCodeConfirmDisabled || isTimeOver}
          onClick={handleCodeConfirmClick}
        >
          {t("signupCorp:button.verify")}
        </StyledButton>
      );
    }

    return (
      <StyledButton
        buttonGrade="secondary"
        disabled={
          !buttonStatus?.isEmailConfirm ||
          sendEmailResult.isLoading ||
          sendEmailResult.isError
        }
        isLoading={sendEmailResult.isLoading}
        onClick={handleSendEmailClick}
      >
        {t("signupCorp:button.certificationNo")}
      </StyledButton>
    );
  };

  const renderEmailConfirmButton = (disabled: boolean) => {
    if (buttonStatus?.isEmailConfirm) {
      return (
        <StyledButton
          onClick={handleEmailChangeClick}
          buttonColor="blue"
          buttonGrade="secondary"
        >
          {t("signupCorp:button.changeEmail")}
        </StyledButton>
      );
    }

    return (
      <StyledButton disabled={disabled} onClick={handleEmailConfirmClick}>
        {t("signupCorp:button.doubleCheck")}
      </StyledButton>
    );
  };

  const renderDialog = () => {
    if (viewingTerm !== null) {
      return (
        <Dialog
          open
          onOpenChange={(openState) => {
            if (!openState) {
              setViewingTerm(null);
            }
          }}
          width={850}
          title={
            i18n.language === "ko" ? viewingTerm.titleKo : viewingTerm.titleEn
          }
        >
          <div
            className="ck ck-content"
            dangerouslySetInnerHTML={{
              __html:
                i18n.language === "ko"
                  ? viewingTerm?.bodyKo || "-"
                  : viewingTerm?.bodyEn || "-",
            }}
          />
        </Dialog>
      );
    }
  };

  useEffect(() => {
    setFocus("name");
  }, [setFocus]);

  useEffect(() => {
    if (buttonStatus.isCodeConfirm) {
      pauseOn();
    }
  }, [buttonStatus.isCodeConfirm, pauseOn]);

  return (
    <StyledSectionCard
      cardTitle={
        <Typo typoType="h4">{t("temporarySignupCorp:steps.thirdStep")}</Typo>
      }
    >
      <Form onSubmit={handleSubmit(handleSubmitClick)}>
        <Content>
          <FormItem
            label={t("signupCorp:content.name")}
            type="text"
            name="name"
            control={control}
            rules={{ required: true }}
            errorsMessage={{
              required: t("error:required"),
            }}
            direction="vertical"
            inputProps={{
              placeholder: t("signupCorp:content.namePlaceholder"),
            }}
          />
          <StyledSectionCardRow
            label={<Label required>{t("signupCorp:content.id")}</Label>}
            direction="vertical"
            value={
              <Controller
                control={control}
                rules={{
                  required: true,
                  pattern: emailRegex,
                }}
                name="aId"
                render={({ field }) => {
                  const isTestFailed = emailRegex.test(field.value as string);

                  return (
                    <FlexColumn>
                      <InputWithButtonContainer>
                        <Input
                          {...field}
                          placeholder={t("signupCorp:content.idPlaceholder")}
                          disabled={
                            buttonStatus?.isCodeConfirm ||
                            buttonStatus?.isEmailConfirm
                          }
                          onClear={() => {
                            setValue("aId", "");
                          }}
                          data-invalid={!!errors.aId}
                          onBlur={() => {
                            handleRequiredErrorsBlur(
                              "aId",
                              field.value as string
                            );
                          }}
                          onChange={(e) => {
                            setValue("aId", e.target.value);
                            clearErrors("aId");
                          }}
                        />
                        {renderEmailConfirmButton(!isTestFailed)}
                      </InputWithButtonContainer>

                      {errors.aId?.type === "required" && (
                        <InputError message={t("error:required")} />
                      )}
                      {errors.aId?.type === "pattern" && (
                        <InputError
                          message={t("error:login.emailTypeMissMatching")}
                        />
                      )}
                      {errors.aId?.type === "validate" && (
                        <InputError message={t("error:alreadyExistedId")} />
                      )}
                      {buttonStatus?.isEmailConfirm && (
                        <InputSuccess message={t("success:availableId")} />
                      )}
                    </FlexColumn>
                  );
                }}
              />
            }
          />
          <StyledSectionCardRow
            label={
              <FormItemLabelContainer>
                <Label required>{t("signupCorp:content.code")}</Label>
                {buttonStatus?.isCodeSendClick && (
                  <Button
                    buttonSize={24}
                    buttonGrade="tertiary"
                    buttonColor="black"
                    disabled={
                      !buttonStatus?.isEmailConfirm ||
                      !buttonStatus?.isCodeSendClick ||
                      buttonStatus?.isCodeConfirm
                    }
                    onClick={handleResendEmailClick}
                  >
                    {t("signupCorp:button.reSend")}
                  </Button>
                )}
              </FormItemLabelContainer>
            }
            direction="vertical"
            value={
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                name="verifyString"
                render={({ field }) => {
                  return (
                    <FlexColumn>
                      <InputWithButtonContainer>
                        <Input
                          {...field}
                          onChange={(e) => {
                            setValue("verifyString", e.target.value);
                            clearErrors("verifyString");
                          }}
                          disabled={buttonStatus?.isCodeConfirm}
                          placeholder={t("signupCorp:content.codePlaceholder")}
                          onClear={() => setValue("verifyString", "")}
                          data-invalid={!!errors.verifyString}
                          onBlur={() => {
                            handleRequiredErrorsBlur(
                              "verifyString",
                              field.value
                            );
                          }}
                          suffix={
                            buttonStatus?.isCodeSendClick && (
                              <Typo
                                typoType="b9m"
                                color={
                                  buttonStatus?.isCodeConfirm ||
                                  !buttonStatus?.isCodeSendClick
                                    ? "gray7"
                                    : "red2"
                                }
                              >
                                {min}:{second} sec
                              </Typo>
                            )
                          }
                        />
                        {renderCodeSendButton()}
                      </InputWithButtonContainer>

                      {errors.verifyString?.type === "required" && (
                        <InputError message={t("error:required")} />
                      )}
                      {errors.verifyString?.type === "validate" && (
                        <InputError
                          message={t("error:sendCodeValidationError")}
                        />
                      )}
                      {buttonStatus?.isCodeConfirm && (
                        <InputSuccess message={t("success:codeConfirm")} />
                      )}
                    </FlexColumn>
                  );
                }}
              />
            }
          />
          <StyledSectionCardRow
            label={<Label required>{t("signupCorp:content.password")}</Label>}
            direction="vertical"
            value={
              <Controller
                control={control}
                rules={{ required: true, pattern: passwordRegex }}
                name="password"
                render={({ field }) => (
                  <FlexColumn>
                    <IconInput
                      {...field}
                      type={passwordInputType.password}
                      onClear={() => setValue("password", "")}
                      placeholder={t("signupCorp:content.passwordPlaceholder")}
                      data-invalid={!!errors.password}
                      icon={
                        <StyledIcon
                          iconSrc={
                            passwordInputType.password === "password"
                              ? EyeOffSvg
                              : EyeSvg
                          }
                          iconSize={20}
                          onClick={() =>
                            setPasswordInputType((prev) => {
                              return {
                                ...prev,
                                password:
                                  prev.password === "password"
                                    ? "text"
                                    : "password",
                              };
                            })
                          }
                        />
                      }
                    />
                    {errors.password?.type === "required" && (
                      <InputError message={t("error:required")} />
                    )}
                    {errors.password?.type === "pattern" && (
                      <InputError message={t("error:passwordPatterError")} />
                    )}
                  </FlexColumn>
                )}
              />
            }
          />
          <StyledSectionCardRow
            label={
              <Label required>{t("signupCorp:content.reEnterPassword")}</Label>
            }
            direction="vertical"
            value={
              <Controller
                control={control}
                rules={{
                  required: true,
                  validate: (value, formValues) =>
                    value === formValues.password,
                }}
                name="rePassword"
                render={({ field }) => (
                  <FlexColumn>
                    <IconInput
                      {...field}
                      type={passwordInputType.rePassword}
                      onClear={() => setValue("rePassword", "")}
                      placeholder={t(
                        "signupCorp:content.rePasswordPlaceholder"
                      )}
                      data-invalid={!!errors.rePassword}
                      icon={
                        <StyledIcon
                          iconSrc={
                            passwordInputType.rePassword === "password"
                              ? EyeOffSvg
                              : EyeSvg
                          }
                          iconSize={20}
                          onClick={() =>
                            setPasswordInputType((prev) => {
                              return {
                                ...prev,
                                rePassword:
                                  prev.rePassword === "password"
                                    ? "text"
                                    : "password",
                              };
                            })
                          }
                        />
                      }
                    />
                    {errors.rePassword?.type === "required" && (
                      <InputError message={t("error:required")} />
                    )}
                    {errors.rePassword?.type === "validate" && (
                      <InputError message={t("error:passwordRecheckFail")} />
                    )}
                  </FlexColumn>
                )}
              />
            }
          />
          <StyledSectionCardRow
            label={
              <FormItemLabelContainer>
                <Label required>{t("signupCorp:content.category")}</Label>

                <StyledLabel>
                  <Checkbox
                    id="all-select"
                    checked={
                      watch("mainCategoryCodeItemNames")?.length ===
                      mainCategoryCodeListToSelectOption?.length
                    }
                    onChange={() => {
                      watch("mainCategoryCodeItemNames")?.length ===
                      mainCategoryCodeListToSelectOption?.length
                        ? setValue("mainCategoryCodeItemNames", [])
                        : setValue(
                            "mainCategoryCodeItemNames",
                            mainCategoryCodeListToSelectOption?.map(
                              ({ value }) => value.split(SEPARATOR)[0]
                            ) ?? []
                          );
                      clearErrors("mainCategoryCodeItemNames");
                    }}
                  />
                  <AllSelectLabel htmlFor="all-select">
                    {t("signupCorp:content.allCategory")}
                  </AllSelectLabel>
                </StyledLabel>
              </FormItemLabelContainer>
            }
            direction="vertical"
            value={
              <Controller
                name="mainCategoryCodeItemNames"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <FlexColumn>
                    <StyledCheckboxSelect
                      {...field}
                      data-invalid={!!errors.mainCategoryCodeItemNames}
                      placeholder={t(
                        "signupCorp:content.mainCategoryCodeItemNamesPlaceholder"
                      )}
                      suffixIcon={
                        isMainCategoryCodeListFetching ? <Loader /> : undefined
                      }
                      disabled={
                        isMainCategoryCodeListFetching ||
                        isMainCategoryCodeListError
                      }
                      value={categoryValue}
                      onChange={(selectedItems) => {
                        const realValueList = selectedItems.map(
                          (item: string) => item.split(SEPARATOR)[0]
                        );
                        setValue("mainCategoryCodeItemNames", realValueList);
                        clearErrors("mainCategoryCodeItemNames");
                      }}
                      onRemoveItem={(item) => {
                        const codeNameValue = item.split(SEPARATOR)[0];
                        setValue(
                          "mainCategoryCodeItemNames",
                          watch("mainCategoryCodeItemNames")?.filter(
                            (codeName) => codeName !== codeNameValue
                          )
                        );
                      }}
                      options={mainCategoryCodeListToSelectOption}
                    />

                    {errors.mainCategoryCodeItemNames?.type === "required" && (
                      <InputError message={t("error:required")} />
                    )}
                  </FlexColumn>
                )}
              />
            }
          />

          <StyledSectionCardRow
            label={<Label>{t("signupCorp:content.subscriptionPath")}</Label>}
            direction="vertical"
            value={
              <FlexColumn>
                <Controller
                  name="joinPath"
                  control={control}
                  render={({ field }) => (
                    <SearchSelect
                      {...field}
                      placeholder={t(
                        "signupCorp:content.subscriptionPathPlaceholder"
                      )}
                      options={SUBSCRIPTION_PATH_OPTION_LIST.map(
                        ({ langKey, value }) => {
                          return { label: t(langKey), value };
                        }
                      )}
                      allowClear={!!watch("joinPath")}
                    />
                  )}
                />
                {watch("joinPath") === "ETC" && (
                  <Controller
                    name="joinPathDescription"
                    control={control}
                    render={({ field }) => (
                      <StyledTextArea
                        {...field}
                        placeholder={t("common:joinPathDescriptionPlaceholder")}
                        maxLength={100}
                      />
                    )}
                  />
                )}
              </FlexColumn>
            }
          />

          <FlexColumn>
            <StyledLabel>
              <TermCheckboxLabelContainer>
                <Checkbox
                  id="all-select"
                  checked={isAgreeAll}
                  onChange={() => {
                    if (isAgreeAll) {
                      handleResetAgree();
                    } else {
                      handleAgreeAll();
                    }
                  }}
                />
                <Typo typoType="b7r" color="gray1">
                  {t("signupCorp:content.agreeAll")}
                </Typo>
              </TermCheckboxLabelContainer>
            </StyledLabel>

            <Divider />

            {isFetching ? (
              <LoadingContainer>
                <Loader size={25} />
              </LoadingContainer>
            ) : (
              termsResponse?.map(
                ({ isOptional, titleKo, titleEn, currentTermsHistory }) => {
                  const isChecked = isOptional
                    ? signUpForm?.optionalTermsHistoryIdList?.includes(
                        currentTermsHistory.id
                      )
                    : signUpForm?.requiredTermsIdList?.includes(
                        currentTermsHistory.id
                      );

                  return (
                    <>
                      <TermRowContainer key={currentTermsHistory.id}>
                        <StyledLabel>
                          <Checkbox
                            checked={isChecked}
                            onClick={() => {
                              handleTermClick(
                                currentTermsHistory.id,
                                isOptional
                              );
                            }}
                          />
                          <Typo typoType="b7r" color="gray1">
                            {i18n.language === "ko" ? titleKo : titleEn}{" "}
                            {isOptional ? (
                              <Typo typoType="b9r" color="gray6">
                                ({t("common:optional")})
                              </Typo>
                            ) : (
                              <Typo typoType="b9r" color="blue4">
                                ({t("common:required")})
                              </Typo>
                            )}
                          </Typo>
                        </StyledLabel>

                        <ViewTextButton
                          onClick={() => {
                            setViewingTerm({
                              bodyEn: currentTermsHistory.bodyEn,
                              bodyKo: currentTermsHistory.bodyKo,
                              titleEn,
                              titleKo,
                            });
                          }}
                        >
                          {t("common:view")}
                        </ViewTextButton>
                      </TermRowContainer>
                    </>
                  );
                }
              )
            )}

            <ServiceTermRow>
              <Checkbox
                checked={signUpForm?.isMarketingEmailReceive as boolean}
                onClick={() => {
                  signUpForm?.onFormValueChange((prev) => {
                    return {
                      ...prev,
                      isMarketingEmailReceive: !prev.isMarketingEmailReceive,
                    };
                  });
                }}
              />
              <Typo typoType="b7r" color="gray1" className="receive-email-term">
                {t("signupCorp:content.receiveEmailTerm")}
                <Typo typoType="b9r" color="gray6">
                  ({t("common:optional")})
                </Typo>
              </Typo>
            </ServiceTermRow>
          </FlexColumn>
        </Content>

        <ButtonContainer>
          <Button
            type="button"
            buttonGrade="tertiary"
            buttonColor="black"
            onClick={handleStepPrevClick}
          >
            {t("signupCorp:button.back")}
          </Button>
          <Button
            type="submit"
            disabled={isCreateButtonDisabled || corpSignUpData.isLoading}
            isLoading={corpSignUpData.isLoading}
          >
            {t("signupCorp:button.createCorporateAccount")}
          </Button>
        </ButtonContainer>
      </Form>

      {renderDialog()}
    </StyledSectionCard>
  );
};

export default CorporateSignUpStepThree;

const StyledSectionCard = styled(SectionCard)`
  width: 800px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  max-width: 480px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 480px;
  gap: 8px;

  button {
    flex: 1;
    text-align: center;
  }
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

const InputWithButtonContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const StyledButton = styled(Button)`
  flex-shrink: 0;
  min-width: 114px;
  text-align: center;
`;

const StyledSectionCardRow = styled(SectionCardRow)`
  p:nth-of-type(1) {
    max-width: 100%;
  }
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const FormItemLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const TermCheckboxLabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ServiceTermRow = styled(StyledLabel)`
  align-items: flex-start;

  .receive-email-term {
    word-break: keep-all;
    white-space: pre-wrap;
  }

  > input {
    flex-shrink: 0;
  }
`;

const StyledCheckboxSelect = styled(MultipleSelect)`
  &[data-invalid="true"] {
    .ant-select-selector {
      border: 1px solid ${colorSet.red2} !important;
    }
  }
`;

const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colorSet.gray9};
`;

const ViewTextButton = styled(TextButton)`
  color: ${colorSet.gray6};
  text-decoration-color: ${colorSet.gray6};
  ${typo.b9r};
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0 0 0;
`;

const TermRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledTextArea = styled(TextArea)`
  height: 137px;
`;

const AllSelectLabel = styled.label`
  cursor: pointer;
  color: ${colorSet.gray6};
  ${typo.b9r}
`;
