import React, { useEffect } from "react";
import styled from "styled-components";
import { Controller, useForm } from "react-hook-form";
import Dialog from "@/src/components/atom/Dialog";
import Typo from "@/src/components/atom/Typo";
import Label from "@/src/components/atom/Label";
import { Button } from "@/src/components/atom/Button";
import CallOut from "@/src/components/molecule/CallOut";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import DialogFooterContainer from "@/src/components/atom/Dialog/DialogFooterContainer";
import { useGetBuyerEmployeesQuery } from "@/src/store/apis/client/buyer";
import { isUndefined } from "@/src/utils/is";
import { BuyerEmployeeDto } from "@/src/store/apis/client/buyer/interface";
import Select from "@/src/components/atom/Select";
import colorSet from "@/src/styles/color";
import Loader from "@/src/components/atom/Loader";
import Tag from "@/src/components/atom/Tag";
import { InputError } from "@/src/components/molecule/FormItem";
import { useTranslation } from "react-i18next";

interface SendLinkCodeDialogProps {
  clientId: number;
  onOpenChange: (value: boolean) => void;
  linkCode: string;
  onOk: (emailList: string[]) => void;
  isLoading: boolean;
}

const EMPTY_LIST: BuyerEmployeeDto[] = [];

function SendLinkCodeSelectDialog({
  clientId,
  onOpenChange,
  linkCode,
  onOk,
  isLoading,
}: SendLinkCodeDialogProps) {
  const { t } = useTranslation();
  const { employeeList, isFetching, isError } = useGetBuyerEmployeesQuery(
    { id: clientId },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isFetching, isError }) => {
        const isUnstable = isFetching || isError || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          employeeList: isStable ? currentData.rows : EMPTY_LIST,
          isFetching,
          isError,
        };
      },
    }
  );

  const employeeEmailList = employeeList
    .filter(({ isActivated }) => isActivated)
    .map(({ email }) => ({
      value: email,
      label: email,
    }));

  const {
    control,
    watch,
    setValue,
    setFocus,
    getValues,
    formState: { errors },
  } = useForm<{
    emailList: string[];
  }>({
    mode: "onBlur",
    defaultValues: {
      emailList: [],
    },
  });

  useEffect(() => {
    if (!isFetching) {
      setFocus("emailList");
    }
  }, [isFetching, setFocus]);

  return (
    <Dialog
      open
      title={t("common:sendClientInviteCode")}
      onOpenChange={onOpenChange}
      width={640}
      footer={
        <DialogFooterContainer>
          <Button
            buttonGrade="tertiary"
            buttonColor="black"
            onClick={() => onOpenChange(false)}
          >
            <Typo typoType="btn3m">{t("common:cancel")}</Typo>
          </Button>
          <Button
            onClick={() => onOk(getValues("emailList"))}
            disabled={watch("emailList").length === 0 || isLoading}
            isLoading={isLoading}
          >
            <Typo typoType="btn3m" color="white">
              {t("common:send")}
            </Typo>
          </Button>
        </DialogFooterContainer>
      }
    >
      <FlexColumn>
        <Typo typoType="b7m">{t("common:sendClientInviteCodeGuide")}</Typo>

        <SectionCardRow
          label={t("common:linkCode")}
          direction="vertical"
          value={
            <StyledCallOut backgroundColor="white">
              <Typo typoType="d5" color="blue4">
                {linkCode}
              </Typo>
            </StyledCallOut>
          }
        />

        <SectionCardRow
          label={<Label required>{t("common:email")}</Label>}
          direction="vertical"
          value={
            <Controller
              control={control}
              name="emailList"
              rules={{ required: true }}
              render={({ field }) => (
                <CallOutContent>
                  <StyledSelect
                    {...field}
                    mode="multiple"
                    placeholder={t("placeholder:email")}
                    value={field.value}
                    disabled={isFetching || isError}
                    tagRender={(props) => {
                      return (
                        <Tag
                          style={{ margin: "4px" }}
                          label={props.label}
                          onRemove={() => {
                            const emailList = watch("emailList");
                            setValue(
                              "emailList",
                              emailList.filter((item) => item !== props.value)
                            );
                          }}
                        />
                      );
                    }}
                    listHeight={120}
                    data-invalid={!!errors.emailList}
                    showSearch
                    getPopupContainer={(triggerNode) => {
                      return triggerNode.parentElement;
                    }}
                    filterOption={(input, option) =>
                      ((option?.label as string) ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={employeeEmailList}
                    suffixIcon={isFetching ? <Loader /> : undefined}
                  />

                  {!!errors.emailList && (
                    <InputError message={t("error:required")} />
                  )}
                </CallOutContent>
              )}
            />
          }
        />
      </FlexColumn>
    </Dialog>
  );
}

export default SendLinkCodeSelectDialog;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 150px;
`;

const CallOutContent = styled(FlexColumn)`
  gap: 8px;
  padding-bottom: 0px;
`;

const StyledCallOut = styled(CallOut)`
  padding: 12px 16px;
  justify-content: center;
`;

const StyledSelect = styled(Select)`
  width: 100%;

  &[data-invalid="true"] {
    .ant-select-selector {
      border: 1px solid ${colorSet.red2} !important;
    }
  }
`;
