import { memo } from "react";
import { Trans } from "react-i18next";
import Typo from "../../atom/Typo";

interface GuideCountProps {
  count: number;
}

const GuideCount = ({ count }: GuideCountProps) => {
  return (
    <Typo typoType="b9m" color="gray7">
      <Trans
        i18nKey="common:guide.checkCount"
        components={{
          color: (
            <Typo typoType="h9" color={count === 0 ? "gray7" : "green2"} />
          ),
        }}
        values={{
          checkCount: count,
          total: 3,
        }}
      />
    </Typo>
  );
};

export default memo(GuideCount);
