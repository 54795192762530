import { useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import { AgGridReact } from "ag-grid-react";
import { useNavigate } from "react-router-dom";
import { CellClickedEvent, ColDef } from "ag-grid-community";
import Typo from "@/src/components/atom/Typo";
import Filter from "@/src/components/molecule/Filter";
import ImporterMainLayout from "@/src/components/template/Layout/importer/ImporterMainLayout";
import { useGetCommonCodeViaCodeNameQuery } from "@/src/store/apis/common";
import Loader from "@/src/components/atom/Loader";
import Table from "@/src/components/atom/Table";
import SectionCardWithoutHeader from "@/src/components/molecule/SectionCardWithoutHeader";
import { Button } from "@/src/components/atom/Button";
import Icon from "@/src/components/atom/Icon";
import AddSvg from "@/src/assets/icons/icon-add-white.svg";
import LinkIndigoSvg from "@/src/assets/icons/icon-link-indigo.svg";
import LinkSvg from "@/src/assets/icons/icon-link.svg";
import LinkDisabledSvg from "@/src/assets/icons/icon-link-disabled.svg";
import IMPORTER_PRIVATE_PATH from "@/src/routes/importer/path";
import { clientListColumn } from "./columns/column";
import { useLazyGetBuyersQuery } from "@/src/store/apis/client/buyer";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import useFilterSearch from "@/src/hooks/useFilterSearch";
import {
  BuyerListViewDto,
  GetBuyersRequestParams,
  LinkedStatusType,
} from "@/src/store/apis/client/buyer/interface";
import { ClientType } from "@/src/store/apis/auth/interface";
import { isUndefined } from "@/src/utils/is";
import {
  useGetExportersQuery,
  useSendCompanyLinkCodeMutation,
} from "@/src/store/apis/corporate/company";
import useAlert from "@/src/hooks/useAlert";
import LinkCodeDialog from "./components/LinkCodeDialog";
import LinkHistoryDialog from "./components/LinkHistoryDialog";
import SendLinkCodeDialog from "@/src/components/organism/SendLinkCodeDialog";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { CLIENT_TYPE_OPTION_LIST } from "@/src/constant/optionList";
import { useGetSessionQuery } from "@/src/store/apis/auth";
import SendLinkCodeSelectDialog from "@/src/components/organism/SendLinkCodeSelectDialog";
import { useAppSelector } from "@/src/store";

interface FilterItem {
  companyType?: string;
  buyerName?: string;
  countryCodeItemName?: string;
  isActivated?: string;
  buyerNameCode?: string;
  linkedStatus?: string;
}

const defaultValue: FilterItem = {
  companyType: "all",
  isActivated: "all",
  linkedStatus: "all",
  buyerName: undefined,
  countryCodeItemName: undefined,
  buyerNameCode: undefined,
};

const FAST_SEARCH_QUERY_KEY = [
  "companyType",
  "countryCodeItemName",
  "isActivated",
  "linkedStatus",
];

enum DialogState {
  NULL,
  SINGLE_CODE_SEND,
  MULTIPLE_CODE_SEND,
  LINK_HISTORY,
  CLIENT_LINK_CODE,
}

const EMPTY_ARRAY: BuyerListViewDto[] = [];

const ImporterClientManagementPage = () => {
  const { t, i18n } = useTranslation();
  const alert = useAlert();
  const navigate = useNavigate();
  const gridRef = useRef<AgGridReact>(null);
  const user = useAppSelector((state) => state.auth.user);

  const [columnDefs] = useState<ColDef[]>(clientListColumn);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [dialogState, setDialogState] = useState(DialogState.NULL);
  const [codeSendClientId, setCodeSendClientId] = useState(null);

  // API
  const { isCorporateManager } = useGetSessionQuery(undefined, {
    selectFromResult: ({ currentData, isError, isFetching }) => {
      const isUnstable = isError || isFetching || currentData === undefined;
      const isStable = !isUnstable;

      return {
        isCorporateManager: isStable
          ? currentData.row.exporterUserType === "CORPORATE_MANAGER"
          : false,
      };
    },
  });
  const [sendCompanyLinkCode, { isLoading }] = useSendCompanyLinkCodeMutation();
  const { companyLinkCode } = useGetExportersQuery(undefined, {
    refetchOnMountOrArgChange: true,
    selectFromResult: ({ currentData, isError, isFetching }) => {
      const isUnstable = isError || isFetching || currentData === undefined;
      const isStable = !isUnstable;
      return {
        companyLinkCode: isStable
          ? currentData.exporterDetailQueryResultDto.companyLinkCode
          : "-",
      };
    },
  });
  const { isFetching, data: countryCodeList } =
    useGetCommonCodeViaCodeNameQuery({
      codeName: "COUNTRY",
    });
  const [getBuyers, { buyerList, buyerListCount, isListFetching }] =
    useLazyGetBuyersQuery({
      selectFromResult: ({ isError, isFetching, currentData }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          buyerList: isStable ? currentData.data : EMPTY_ARRAY,
          buyerListCount: isStable ? currentData.count : 0,
          isListFetching: isFetching,
        };
      },
    });

  const {
    state: { pagination, filterData },
    func: {
      onPaginationChange,
      onSortChange,
      onSearch,
      onFilterDataChange,
      onResetFilter,
      onForceSearch,
    },
  } = useFilterSearch<GetBuyersRequestParams, FilterItem>({
    isReady,
    gridRef: gridRef.current,
    fetch: getBuyers,
    defaultFilterData: defaultValue,
    onBeforeSearch: (data) => {
      return {
        companyType:
          data.companyType === "all"
            ? undefined
            : (data.companyType as ClientType),
        buyerName: data.buyerName || undefined,
        linkedStatus:
          data.linkedStatus === "all"
            ? undefined
            : (data.linkedStatus as LinkedStatusType),
        countryCodeItemName:
          countryCodeList?.find(
            ({ codeItemNameKo }) => codeItemNameKo === data.countryCodeItemName
          )?.codeItemName || undefined,
        isActivated:
          data.isActivated === "true"
            ? true
            : data.isActivated === "false"
            ? false
            : undefined,
        buyerNameCode: data.buyerNameCode || undefined,
      };
    },
    onBeforeSetFilter: (urlObject) => {
      return {
        ...urlObject,
        countryCodeItemName:
          countryCodeList?.find(
            ({ codeItemName }) => codeItemName === urlObject.countryCodeItemName
          )?.codeItemNameKo || "all",
        companyType: urlObject.companyType || "all",
        isActivated: urlObject.isActivated || "all",
        linkedStatus: urlObject.linkedStatus || "all",
      };
    },
  });

  useAgGridHeaderRefresh({
    gridRef: gridRef.current,
    isReady,
    headerSet: [
      {
        columnKey: "buyerName",
        langKey: "clientManagement:importer.common.clientName",
      },
      {
        columnKey: "buyerNameCode",
        langKey: "clientManagement:importer.common.clientCode",
      },
      {
        columnKey: "clientType",
        langKey: "clientManagement:importer.common.clientType",
      },
      {
        columnKey: "countryCodeItemName",
        langKey: "clientManagement:importer.common.country",
      },
      {
        columnKey: "isActivated",
        langKey: "clientManagement:importer.common.use",
      },
      {
        columnKey: "createdAt",
        langKey: "clientManagement:importer.common.registeredAt",
      },
      {
        columnKey: "linkedStatus",
        langKey: "clientManagement:importer.common.linkedStatus",
      },
      {
        columnKey: "codeSend",
        langKey: "clientManagement:importer.common.codeSend",
      },
      {
        columnKey: "linkedAt",
        langKey: "clientManagement:importer.common.linkedAt",
      },
    ],
  });

  const allOptionItem = {
    label: t("clientManagement:importer.placeholder.all"),
    value: "all",
  };

  const countryCodeListToSelectOption = countryCodeList?.reduce<
    { label: string; value: string }[]
  >(
    (acc, val) => {
      return [
        ...acc,
        {
          label: `${val.codeItemName}_${val.codeItemNameEn}`,
          value: val.codeItemNameKo,
        },
      ];
    },
    [allOptionItem]
  );

  const goToDetail = (clientId: number) => {
    navigate(`${IMPORTER_PRIVATE_PATH.CLIENT_MANAGEMENT_DETAIL}/${clientId}`);
  };

  const handleCellClick = (e: CellClickedEvent) => {
    const isCodeSendColumn = e.column.getColId() === "codeSend";

    if (isCodeSendColumn) {
      return e.event?.preventDefault();
    } else {
      return goToDetail(e.data.buyerListQueryResultDto.id);
    }
  };

  const handleSendLinkCodeClick = async (emailList: string[]) => {
    try {
      await sendCompanyLinkCode({ emailList }).unwrap();
      setDialogState(DialogState.NULL);
      setCodeSendClientId(null);

      alert.showAlert({
        type: "success",
        message: t("sendLinkCodeDialog:importer.emailSuccessAlert"),
      });
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const renderDialog = () => {
    if (dialogState === DialogState.NULL) return null;
    if (dialogState === DialogState.SINGLE_CODE_SEND && codeSendClientId) {
      return (
        <SendLinkCodeSelectDialog
          clientId={codeSendClientId}
          onOpenChange={() => {
            setDialogState(DialogState.NULL);
            setCodeSendClientId(null);
          }}
          linkCode={companyLinkCode}
          onOk={handleSendLinkCodeClick}
          isLoading={isLoading}
        />
      );
    }
    if (dialogState === DialogState.MULTIPLE_CODE_SEND) {
      return (
        <SendLinkCodeDialog
          open
          linkCode={companyLinkCode}
          onOpenChange={() => {
            setDialogState(DialogState.NULL);
            setCodeSendClientId(null);
          }}
          onOk={handleSendLinkCodeClick}
        />
      );
    }
    if (dialogState === DialogState.LINK_HISTORY) {
      return (
        <LinkHistoryDialog onClose={() => setDialogState(DialogState.NULL)} />
      );
    }
    if (dialogState === DialogState.CLIENT_LINK_CODE) {
      return (
        <LinkCodeDialog onClose={() => setDialogState(DialogState.NULL)} />
      );
    }
  };

  useEffect(() => {
    if (isListFetching && gridRef.current) {
      gridRef.current.api.showLoadingOverlay();
    }
  }, [isListFetching]);

  return (
    <ImporterMainLayout
      breadcrumb={[t("sideNav:clientManagement")]}
      pageTitle={t("sideNav:clientManagement")}
    >
      <Filter
        filterKeyList={[
          {
            key: "companyType",
            label: t("clientManagement:importer.common.companyType"),
            type: "searchSelect",
            props: {
              placeholder: t("clientManagement:importer.placeholder.all"),
              suffixIcon: isFetching ? <Loader /> : undefined,
              disabled: isFetching,
            },
          },
          {
            key: "linkedStatus",
            label: t("clientManagement:importer.common.linkedStatus"),
            type: "searchSelect",
            props: {
              placeholder: t("clientManagement:importer.placeholder.all"),
            },
          },
          {
            key: "buyerName",
            label: t("clientManagement:importer.common.clientName"),
            type: "input",
            props: {
              placeholder: t("clientManagement:importer.placeholder.client"),
            },
          },
          {
            key: "countryCodeItemName",
            label: t("clientManagement:importer.common.country"),
            type: "searchSelect",
            props: {
              placeholder: t("clientManagement:importer.placeholder.all"),
              suffixIcon: isFetching ? <Loader /> : undefined,
              disabled: isFetching,
            },
          },
          {
            key: "isActivated",
            label: t("clientManagement:importer.common.use"),
            type: "searchSelect",
            props: {
              placeholder: t("clientManagement:importer.placeholder.all"),
            },
          },
          {
            key: "buyerNameCode",
            label: t("clientManagement:importer.common.clientCode"),
            type: "input",
            props: {
              placeholder: t(
                "clientManagement:importer.placeholder.clientCode"
              ),
            },
          },
        ]}
        filterData={{
          companyType: filterData.companyType,
          buyerName: filterData.buyerName,
          countryCodeItemName: filterData.countryCodeItemName,
          isActivated: filterData.isActivated,
          linkedStatus: filterData.linkedStatus,
          buyerNameCode: filterData.buyerNameCode,
        }}
        optionList={[
          {
            key: "companyType",
            option: [
              allOptionItem,
              ...CLIENT_TYPE_OPTION_LIST.map((item) => {
                return { ...item, label: t(item.langKey) };
              }),
            ],
          },
          {
            key: "linkedStatus",
            option: [
              allOptionItem,
              { value: "PENDING", label: t("status:client.pending") },
              { value: "SENT", label: t("status:client.sent") },
              { value: "RECEIVED", label: t("status:client.received") },
              { value: "COMPLETED", label: t("status:client.completed") },
            ],
          },
          {
            key: "countryCodeItemName",
            option: countryCodeListToSelectOption ?? [],
          },
          {
            key: "isActivated",
            option: [
              allOptionItem,
              { value: "true", label: "Y" },
              { value: "false", label: "N" },
            ],
          },
        ]}
        onSubmit={onSearch}
        onReset={onResetFilter}
        onChange={(key, value) => {
          onFilterDataChange(key, value);

          if (FAST_SEARCH_QUERY_KEY.includes(key)) {
            onForceSearch(key, value);
          }
        }}
      />

      <MiddleSection>
        <Typo typoType="b5m" color="gray4">
          {t("clientManagement:importer.buyerList.clientList")}
        </Typo>
        <Flex>
          {isCorporateManager && (
            <>
              <StyledButton
                buttonGrade="tertiary"
                buttonColor="black"
                onClick={() => setDialogState(DialogState.LINK_HISTORY)}
              >
                {t("clientManagement:importer.buyerList.linkHistory")}
              </StyledButton>
              <StyledButton
                buttonColor="blue"
                buttonGrade="secondary"
                onClick={() => {
                  setDialogState(DialogState.MULTIPLE_CODE_SEND);
                }}
              >
                <Icon iconSrc={LinkIndigoSvg} iconSize={16} />
                <Typo typoType="btn3m" color="indigo">
                  {t("clientManagement:importer.buyerList.sendCorpCode")}
                </Typo>
              </StyledButton>
              <StyledButton
                onClick={() => {
                  setDialogState(DialogState.CLIENT_LINK_CODE);
                }}
              >
                <Icon iconSrc={AddSvg} iconSize={16} />
                <Typo typoType="btn3m" color="white">
                  {t("common:clientCodeRegistration")}
                </Typo>
              </StyledButton>
            </>
          )}
          <StyledButton
            onClick={() => {
              navigate(IMPORTER_PRIVATE_PATH.CLIENT_MANAGEMENT_ADD);
            }}
          >
            <Icon iconSrc={AddSvg} iconSize={16} />
            <Typo typoType="btn3m" color="white">
              {t("clientManagement:importer.buyerList.clientRegistration")}
            </Typo>
          </StyledButton>
        </Flex>
      </MiddleSection>

      <SectionCardWithoutHeader>
        <Table
          ref={gridRef}
          rowData={buyerList.map((client) => ({
            ...client,
            lang: i18n.language,
          }))}
          columnDefs={columnDefs}
          onCellClicked={handleCellClick}
          isPaginationDataMaping
          totalPage={buyerListCount}
          page={pagination.page}
          pageSize={pagination.pageSize}
          onSortChange={(sortSource, isClickedHeader) =>
            !!isClickedHeader && onSortChange(sortSource as any)
          }
          handlePaginationClick={(page, pageSize) =>
            onPaginationChange({ page, pageSize })
          }
          onGridReady={() => setIsReady(true)}
          components={{
            SEND_CODE: (e: any) => {
              const clientId = e.data.id;
              const isLinkedStatusPending =
                e.data.buyerListQueryResultDto.linkedStatus === "PENDING";
              return (
                <SendCodeButtonContainer>
                  <SendCodeCell
                    disabled={
                      !isLinkedStatusPending ||
                      user?.exporterUserType !== "CORPORATE_MANAGER"
                    }
                    onClick={() => {
                      setCodeSendClientId(clientId);
                      setDialogState(DialogState.SINGLE_CODE_SEND);
                    }}
                    t={t}
                  />
                </SendCodeButtonContainer>
              );
            },
          }}
        />
      </SectionCardWithoutHeader>

      {renderDialog()}
    </ImporterMainLayout>
  );
};

export default ImporterClientManagementPage;

const MiddleSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0 8px;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;
const SendCodeButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const SendCodeCell = ({
  onClick,
  disabled,
  t,
}: {
  onClick: () => void;
  disabled: boolean;
  t: TFunction;
}) => {
  return (
    <StyledButton
      buttonGrade="tertiary"
      buttonColor="black"
      buttonSize={24}
      onClick={onClick}
      disabled={disabled}
    >
      <Icon iconSrc={disabled ? LinkDisabledSvg : LinkSvg} iconSize={16} />
      {t("clientManagement:importer.buyerList.codeSend")}
    </StyledButton>
  );
};
