import { memo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import Typo from "../../atom/Typo";
import Flex from "../../molecule/Flex";
import { ReactComponent as CheckSvg } from "@/src/assets/icons/icon-guide-check-white.svg";
import { ReactComponent as ChevronRightSvg } from "@/src/assets/icons/icon-chevron-right.svg";
import colorSet from "@/src/styles/color";
import { useAppDispatch, useAppSelector } from "@/src/store";
import {
  setExporterTwoDepsNav,
  setImporterTwoDepsNav,
} from "@/src/store/slice/sideNav";
import { EXPORTER_MANAGEMENT_NAV_MENU } from "@/src/components/template/Layout/exporter/menu";
import { IMPORTER_MANAGEMENT_NAV_MENU } from "@/src/components/template/Layout/importer/menu";

type GuideItemType = {
  title: string;
  description: React.ReactNode;
  isCheck: boolean;
  linkPath: string;
  circleColor: string;
  lineColor: string;
};

interface GuideContentProps {
  guideList: GuideItemType[];
}

const GuideContent = ({ guideList }: GuideContentProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { importerSelectTwoDepsNav, exporterSelectTwoDepsNav } = useAppSelector(
    (state) => state.sideNav
  );

  const handleItemClick = (path: string) => () => {
    const isExporter = location.pathname.includes("/export");
    const menuList = isExporter
      ? EXPORTER_MANAGEMENT_NAV_MENU
      : IMPORTER_MANAGEMENT_NAV_MENU;

    for (const menu of menuList) {
      const childPath = menu.children?.find((child) =>
        path.includes(child.destination.split("/").pop() || "")
      );

      if (childPath) {
        const currentNav = isExporter
          ? exporterSelectTwoDepsNav
          : importerSelectTwoDepsNav;

        const updatedNav = menuList.map((item, index) => ({
          ...item,
          isOpen: item === menu ? true : currentNav[index].isOpen,
        }));

        if (isExporter) {
          dispatch(setExporterTwoDepsNav(updatedNav));
        } else {
          dispatch(setImporterTwoDepsNav(updatedNav));
        }
        break;
      }
    }

    navigate(path);
  };

  return (
    <GuideContainer flexDirection="column">
      {guideList.map((item, idx) => (
        <GuideItem
          key={idx}
          alignItems="center"
          gap={8}
          isCheck={item.isCheck}
          lineColor={item.lineColor}
        >
          <Circle isCheck={item.isCheck} circleColor={item.circleColor}>
            {item.isCheck ? (
              <CheckIcon />
            ) : (
              <Typo typoType="b10m" color={item.isCheck ? "green1" : "gray6"}>
                {idx + 1}
              </Typo>
            )}
          </Circle>
          <Content
            onClick={handleItemClick(item.linkPath)}
            justifyContent="space-between"
            alignItems="center"
          >
            <Flex flexDirection="column" gap={4}>
              <Typo typoType="b7m">{item.title}</Typo>
              {!item.isCheck && (
                <Typo typoType="b9r" color="gray6">
                  {item.description}
                </Typo>
              )}
            </Flex>
            <ChevronRightIcon />
          </Content>
        </GuideItem>
      ))}
    </GuideContainer>
  );
};

export default memo(GuideContent);

const GuideContainer = styled(Flex)`
  margin-top: 16px;
`;

const GuideItem = styled(Flex)<{
  isCheck: boolean;
  lineColor: string;
}>`
  position: relative;

  &:not(:last-child)::before {
    content: "";
    position: absolute;
    left: 11px;
    top: 24px;
    width: 2px;
    height: calc(100%);
    pointer-events: none;
    background-color: ${({ lineColor }) => lineColor};
  }
`;

const Circle = styled.div<{
  isCheck: boolean;
  circleColor: string;
}>`
  position: relative;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  border: 2px solid ${({ circleColor }) => circleColor};
  line-height: 10px;
  background: ${({ isCheck }) => (isCheck ? colorSet.green2 : colorSet.white)};

  span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

const CheckIcon = styled(CheckSvg)`
  width: 11px;
  height: 8px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const Content = styled(Flex)`
  width: 100%;
  border-radius: 8px;
  padding: 8px 12px;
  transition: background-color 0.3s ease;
  cursor: pointer;

  &:hover {
    background-color: ${colorSet.gray11};
  }
`;

const ChevronRightIcon = styled(ChevronRightSvg)`
  width: 16px;
  height: 16px;
  path {
    fill: ${colorSet.gray7};
  }
`;
