import React, { useRef, useState } from "react";
import { Dayjs } from "dayjs";
import styled from "styled-components";
import {
  useGetBuyerEntireRankStatisticInfoQuery,
  useLazyGetExcelBuyerEntireRankStatisticInfoByItemQuery,
} from "@/src/store/apis/dashboard";
import colorSet from "@/src/styles/color";
import Dialog from "@/src/components/atom/Dialog";
import { Button } from "@/src/components/atom/Button";
import Table from "@/src/components/atom/Table";
import Icon from "@/src/components/atom/Icon";
import { isUndefined } from "@/src/utils/is";
import ExcelSvg from "@/src/assets/icons/icon-excel.svg";
import useAlert from "@/src/hooks/useAlert";
import { buyerItemColDef } from "./buyerItemRank.column";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import { useTranslation } from "react-i18next";
import Typo from "@/components/atom/Typo";
import { AgGridReact } from "ag-grid-react";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";

interface BuyerItemRankProps {
  from: Dayjs;
  to: Dayjs;
  mainCategoryCodeItemName?: string;
  onClose: () => void;
}

const PERIOD_FORMAT = DATE_FORMAT_STRINGS["YYYY.MM.DD"];
const emptyArray: any[] = [];

const BuyerItemRank = ({
  from,
  to,
  mainCategoryCodeItemName,
  onClose,
}: BuyerItemRankProps) => {
  const { t } = useTranslation();
  const alert = useAlert();
  const gridRef = useRef<AgGridReact>(null);
  const [columnDefs] = useState(buyerItemColDef);
  const [isReady, setIsReady] = useState<boolean>(false);

  const { rowData, isFetching } = useGetBuyerEntireRankStatisticInfoQuery(
    {
      etdAtFrom: from.startOf("day").toISOString(),
      etdAtTo: to.endOf("day").toISOString(),
      mainCategoryCodeItemName,
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isFetching, isError }) => {
        const isUnstable = isUndefined(currentData) || isFetching || isError;
        const isStable = !isUnstable;
        return {
          rowData: isStable ? currentData.rows : emptyArray,
          isFetching,
        };
      },
    }
  );
  const [getExcel] = useLazyGetExcelBuyerEntireRankStatisticInfoByItemQuery();

  const handleExcelDownload = async () => {
    try {
      await getExcel({
        etdAtFrom: from.startOf("day").toISOString(),
        etdAtTo: to.endOf("day").toISOString(),
        mainCategoryCodeItemName,
      }).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;

      alert.showAlert({ type: "error", message });
    }
  };

  useAgGridHeaderRefresh({
    gridRef: gridRef.current,
    isReady,
    headerSet: [
      {
        columnKey: "no",
        langKey: "table:no",
      },
      {
        columnKey: "buyerNameCode",
        langKey: "table:buyer",
      },
      {
        columnKey: "totalNetWeight",
        langKey: "table:totalNetWeight",
      },
      {
        columnKey: "itemTop1",
        langKey: "table:itemTop1",
      },
      {
        columnKey: "itemTop2",
        langKey: "table:itemTop2",
      },
      {
        columnKey: "itemTop3",
        langKey: "table:itemTop3",
      },
      {
        columnKey: "itemTop4",
        langKey: "table:itemTop4",
      },
      {
        columnKey: "itemTop5",
        langKey: "table:itemTop5",
      },
      {
        columnKey: "etc",
        langKey: "table:etc",
      },
    ],
  });

  return (
    <Dialog
      title={t("dashboard:exporter.buyerVolumeDialogItem")}
      open
      onOpenChange={onClose}
      width={1200}
    >
      <Content>
        <PeriodContainer>
          <Typo typoType="b7m" color="gray1">
            {from.format(PERIOD_FORMAT)}~{to.format(PERIOD_FORMAT)}{" "}
            {t("dashboard:exporter.totalVolume")}
          </Typo>

          <StyledExcelButton
            buttonSize={32}
            buttonColor="black"
            buttonGrade="tertiary"
            onClick={handleExcelDownload}
          >
            <Icon iconSrc={ExcelSvg} iconSize={16} />
            {t("common:excelDownload")}
          </StyledExcelButton>
        </PeriodContainer>

        <Table
          ref={gridRef}
          rowData={isFetching ? undefined : rowData}
          columnDefs={columnDefs}
          isPagination={false}
          onGridReady={() => setIsReady(true)}
        />
      </Content>
    </Dialog>
  );
};
export default BuyerItemRank;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const PeriodContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  border: 1px solid ${colorSet.gray9};
  border-radius: 8px;
`;

const StyledExcelButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
`;
