import { useEffect } from "react";
import Dialog from "../../atom/Dialog";
import DialogFooterContainer from "../../atom/Dialog/DialogFooterContainer";
import { Button } from "../../atom/Button";
import Typo from "../../atom/Typo";
import styled from "styled-components";
import CallOut from "../../molecule/CallOut";
import SectionCardRow from "../../molecule/SectionCardRow";
import Label from "../../atom/Label";
import { Controller, useForm } from "react-hook-form";
import MultipleInput from "../../atom/Input/MultipleInput";
import { emailRegex } from "@/src/utils/regex";
import { useTranslation } from "react-i18next";

interface SendLinkCodeDialogProps {
  open: boolean;
  onOpenChange: (value: boolean) => void;
  linkCode: string;
  onOk: (emailList: string[]) => void;
  isLoading?: boolean;
}

function SendLinkCodeDialog({
  open,
  onOpenChange,
  linkCode,
  onOk,
  isLoading,
}: SendLinkCodeDialogProps) {
  const { t } = useTranslation();

  const {
    control,
    watch,
    setValue,
    setFocus,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<{
    email: string;
    emailList: string[];
  }>({
    mode: "onBlur",
    defaultValues: {
      email: "",
      emailList: [],
    },
  });

  const validationEmail = (value: string) => {
    return emailRegex.test(value);
  };

  const filterUniqueStrings = (strings: string[]) => {
    return strings.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });
  };

  useEffect(() => {
    setFocus("email");
  }, [setFocus]);

  return (
    <Dialog
      open={open}
      title={t("common:sendClientInviteCode")}
      onOpenChange={onOpenChange}
      width={640}
      footer={
        <DialogFooterContainer>
          <Button
            buttonGrade="tertiary"
            buttonColor="black"
            onClick={() => onOpenChange(false)}
          >
            <Typo typoType="btn3m">{t("common:cancel")}</Typo>
          </Button>
          <Button
            onClick={() =>
              onOk(
                getValues("emailList").filter((email) => validationEmail(email))
              )
            }
            disabled={
              watch("emailList").filter((email) => validationEmail(email))
                .length === 0 || isLoading
            }
            isLoading={isLoading}
          >
            <Typo typoType="btn3m" color="white">
              {t("common:send")}
            </Typo>
          </Button>
        </DialogFooterContainer>
      }
    >
      <FlexColumn>
        <Typo typoType="b7m">{t("common:sendClientInviteCodeGuide")}</Typo>

        <SectionCardRow
          label={t("common:linkCode")}
          direction="vertical"
          value={
            <StyledCallOut backgroundColor="white">
              <Typo typoType="d5" color="blue4">
                {linkCode}
              </Typo>
            </StyledCallOut>
          }
        />

        <SectionCardRow
          label={<Label required>{t("common:email")}</Label>}
          direction="vertical"
          value={
            <Controller
              control={control}
              name="email"
              rules={{ required: true }}
              render={({ field }) => (
                <MultipleInput
                  {...field}
                  placeholder={t("placeholder:email")}
                  valueList={watch("emailList")}
                  onChangeValue={(value) => setValue("email", value)}
                  onChangeValueList={(valueList) => {
                    const uniqueEmailList = filterUniqueStrings(valueList);

                    if (uniqueEmailList.length >= 1) {
                      clearErrors("emailList");
                    }
                    return setValue("emailList", uniqueEmailList);
                  }}
                  onBlur={() => {
                    const isNoUniqueEmails =
                      filterUniqueStrings(watch("emailList")).length === 0;

                    isNoUniqueEmails &&
                      setError("emailList", { type: "require" });
                  }}
                  validation={validationEmail}
                  isError={!!errors.emailList}
                  errorMessage={t("error:required")}
                />
              )}
            />
          }
        />
      </FlexColumn>
    </Dialog>
  );
}

export default SendLinkCodeDialog;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledCallOut = styled(CallOut)`
  padding: 12px 16px;
  justify-content: center;
`;
